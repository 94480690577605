import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  TextField,
  CircularProgress,
  Card,
  Typography,
  CardMedia,
  CardContent,
  Modal,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { GoogleMap, Marker, useLoadScript, Autocomplete } from '@react-google-maps/api';
import { getSearchMap } from 'api';
import { REACT_APP_GOOGLE_MAPS_API_KEY } from 'config/keys';
import { API_BASE_URL } from 'config/api';
import noAvatar from 'assets/images/no-avatar.png';
import FloatingBookingSelector from 'components/organisms/floatingBookingSelector/view';
import { useProfile } from 'context';
import { useNavigate } from 'react-router-dom';
import CompactCard from './compactCard';

const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {
  lat: 51.52268795372802,
  lng: -0.08518920377573923,
};

const BookingFind = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [coworkingSpaces, setCoworkingSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [postcode, setPostcode] = useState('');
  const [coordinates, setCoordinates] = useState(center);
  const [distance, setDistance] = useState(1); // default distance in miles
  const [loading, setLoading] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const { spaces, start, end } = useProfile(); // Get the start and end dates from profile context
  const navigate = useNavigate();

  const mapRef = useRef(null); // Reference to the Google Map instance

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'], // Load Places library
  });

  // Handle place selection from autocomplete
  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setPostcode(place.formatted_address);
      }
    }
  };

  // Function to calculate the appropriate zoom level based on the distance
  const getZoomLevel = (radius) => {
    const equatorLength = 40075004; // Earth's circumference in meters
    const widthInPixels = window.innerWidth; // Width of the map container in pixels
    const metersPerPixel = equatorLength / 512; // At zoom level 0, 1 pixel = 156543 meters
    let zoomLevel = 0;

    while ((metersPerPixel * widthInPixels) / Math.pow(2, zoomLevel) > radius) {
      zoomLevel++;
    }

    return zoomLevel - 1; // Return a slightly larger zoom level to include the radius
  };

  // Fetch coworking spaces
  useEffect(() => {
    const fetchCoworkingSpaces = async () => {
      try {
        setLoading(true);
        const params = {
          start: start.format('YYYY-MM-DD'),
          end: end ? end.format('YYYY-MM-DD') : start.format('YYYY-MM-DD'),
          spaces,
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          distance: distance * 1609.34, // Convert miles to meters
        };

        const results = await getSearchMap(params);
        setCoworkingSpaces(results);

        // Adjust zoom based on distance
        const radiusInMeters = distance * 1609.34;
        const zoom = getZoomLevel(radiusInMeters);

        if (mapRef.current) {
          mapRef.current.setZoom(zoom);
          mapRef.current.setCenter(coordinates);
        }
      } catch (error) {
        console.error('Error fetching coworking spaces:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCoworkingSpaces();
  }, [searchQuery, spaces, distance, start, end, coordinates]);

  useEffect(() => {
    if (selectedSpace) {
      navigate(`/venue/${selectedSpace.slug}`);
    }
  }, [selectedSpace]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <CircularProgress />;

  return (
    <Grid container spacing={2}>
      <Modal open={loading}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </Modal>

      {/* Left Side: Filters and Results */}
      <Grid item xs={4} sx={{ marginTop: '40px' }}>
        {/* Filter Inputs */}
        <Grid container spacing={2} alignItems="center">
          {/* Google Places Autocomplete */}
          <Grid item xs={8}>
            <Autocomplete
              onLoad={(autocomplete) => setAutocomplete(autocomplete)}
              onPlaceChanged={handlePlaceSelect}
            >
              <TextField
                label="Postcode"
                variant="outlined"
                fullWidth
                value={postcode}
                onChange={e => setPostcode(e.target.value)}
                margin="normal"
                placeholder="Enter a postcode"
              />
            </Autocomplete>
          </Grid>

          {/* Distance Selector */}
          <Grid item xs={4}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Distance (miles)</InputLabel>
              <Select
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
                label="Distance (miles)"
              >
                <MenuItem value={1}>1 mile</MenuItem>
                <MenuItem value={2}>2 miles</MenuItem>
                <MenuItem value={3}>3 miles</MenuItem>
                <MenuItem value={5}>5 miles</MenuItem>
                <MenuItem value={10}>10 miles</MenuItem>
                <MenuItem value={50}>50 miles</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {coworkingSpaces.map((space) => (
            <CompactCard space={space} setSelectedSpace={setSelectedSpace} />
          ))}
        </Grid>
      </Grid>

      {/* Right Side: Map */}
      <Grid item xs={8}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={12} // Default zoom level
          center={coordinates}
          onLoad={map => mapRef.current = map} // Set mapRef on load
        >
          {coworkingSpaces.map((space) => (
            <Marker
              key={space.id}
              position={{ lng: space?.venue_location[0], lat: space?.venue_location[1] }}
              onClick={() => setSelectedSpace(space)}
            />
          ))}
        </GoogleMap>
      </Grid>
      <FloatingBookingSelector />
    </Grid>
  );
};

export default BookingFind;
