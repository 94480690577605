import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled('div')`
min-height: 100vh;
`

export const SecondRowContainer = styled(Box)({
    maxWidth: '80vw', // Default width for desktop

    // Center the container on desktop
    margin: '0 auto',
  
    // Media query for screens with a maximum width of 600px (adjust as needed)
    '@media (max-width: 900px)': {
      maxWidth: '100%', // Set width to 100% for smaller screens
    },
  });

  export const ModalContent = styled('div')`
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: 50%;
    @media (max-width: 768px) {
        width: 100%; // Full screen on mobile devices
        margin: 10px 0;  
    }
`
