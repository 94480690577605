import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { padding, styled } from '@mui/system';
import { Grid } from '@mui/material'
import { Card, CardMedia, Typography, Box, Chip, CardContent, CardActions, Avatar, Button } from '@mui/material';
import { API_BASE_URL } from '../../../../config/api'
import DoneIcon from '@mui/icons-material/Done';
import DeviceUnknown from '@mui/icons-material/DeviceUnknown';
import { InnerChip, AvatarContainer } from './styled';
import noAvatar from '../../../../assets/images/no-avatar.png'

const EventListItem = ({ event, selectEvent }) => {

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  const eventDateStr = () => { 
    const date = new Date(event?.start)
    return date.toLocaleDateString()
  }

  return (
    <div onClick={() => selectEvent(event)} style={{ cursor: 'pointer', position: 'relative' }}>
      <Card style={{ position: 'relative' }}>
        <AvatarContainer>
          <CardMedia
            component="img"
            src={event?.image ? `${API_BASE_URL}${event?.image}` : noAvatar}
            alt="Event Image"
            onError={handleImageRenderError}
            sx={{ borderRadius: '10px' }}
          />
        </AvatarContainer>
        <CardContent>
          
            <Typography sx={{ marginTop: '4px', paddingLeft: '6px', fontSize: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {event?.name}
            </Typography>
            <Typography sx={{ marginTop: '4px', paddingLeft: '6px', fontSize: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {eventDateStr()}
            </Typography>
          

        </CardContent>

      </Card>
    </div>
  );
};

EventListItem.propTypes = {
  event: PropTypes.object,
  selectEvent: PropTypes.func,
}

export default EventListItem
