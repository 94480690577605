import Text from 'components/atoms/text/view'
import styled from 'styled-components'
import { Button } from '@mui/material'

export const ProfileContainer = styled.div`
  padding: 25px;
  margin: 0 auto 15px;
`

export const ProfileTitle = styled.h1`
  font-size: 22px;
  font-weight: bold;
  font-family: 'Lato';
`

export const ProfileSubtitle = styled.h4`
  font-size: 20px;
  font-family: 'Lato';
  margin-bottom: 10px;
`

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 15px;
`
export const ProfileHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const ProfileDataContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-left: 20px;
`

export const ProfileInput = styled(Text)`
  color: #6e6e6e;
  padding: 8px 0;
  font-weight: bold;
`
export const ProfileInputDer = styled(Text)`
  color: #6e6e6e;
  padding: 8px 0;
  font-size: 13px !important;
`

export const ProfileInputSmall = styled(Text)`
  color: #6e6e6e;
  padding: 8px 0;
  font-size: 12px !important;
  text-align: center;
`

export const ProfileInputBold = styled(Text)`
  color: black;
  font-weight: bold;
  padding: 8px 0;
`
export const ProfileInputBoldHeader = styled(Text)`
  color: black;
  font-weight: bold;
  padding: 8px 0;
  font-size: 15px !important;
`

export const ProfileInputBoldExtra = styled(ProfileInputBold)`
  font-size: 30px !important;
`

export const ProfileImgContent = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
`
export const ProfileInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ProfileFirstContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #d9d9d9;
`
export const ProfileTeamContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-center;
  margin-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
`
export const ProfileMembershipContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-center;
  margin-bottom: 75px;
  margin-top: 25px;
  border-bottom: 1px solid #e7e7e7;
`
export const ImageTeamMate = styled.img`
  max-width: 70px;
  margin-bottom: 10px;
`
export const ProfileAppContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-center;
  margin-bottom: 15px;
  border-bottom: 1px solid black;
`

export const TeamMateCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  font-family: 'lato';
  margin: 10px;
  padding: 10px;
  width: 80px;
  &:hover {
    -webkit-box-shadow: 5px 5px 11px 1px rgba(0, 0, 0, 0.38);
    box-shadow: 5px 5px 11px 1px rgba(0, 0, 0, 0.38);
  }
`

export const TeamMatesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 3;
  margin-bottom: 15px;
`

export const UpdateButton = styled.button`
  background-color: grey;
  width: 100px;
  font-family: 'lato';
  font-weight: 700;
  font-size: 15px;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  &:hover {
    background-color: orange;
  }
`
export const EndContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
`

export const StatusOn = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #03cb00;
  display: inline-block;
`

export const StatusOff = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: red;
  display: inline-block;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const BorderBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 5px 25px 5px 25px;
  position: relative;
`
export const BorderBoxColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 25px;
  margin: 10px;
  position: relative;
`

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ResetButton = styled(Button)`
  position: absolute !important;
  top: 10px;
  right: 35px;
`

export const ResendInvitationButton = styled(Button)`
  position: absolute !important;
  top: 10px;
  right: 175px;
`
export const ResourceImage = styled.image`
  color: red;
  margin: 25px;
  border: 1px solid red;
`
