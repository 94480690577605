import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import TextInputView from './view'

const PassInput = ({ control, rules, name, label, ...props }) => (
  <Controller
    render={({ value, onChange, field }) => (
      <TextInputView label={label} onChange={onChange} value={value} variant='outlined' {...props} {...field} />
    )}
    defaultValue={props.value}
    name={name}
    control={control}
    rules={rules}
  />
)

PassInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  name: PropTypes.string.isRequired,
}

PassInput.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  rules: {},
}

export default PassInput
