import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonWrapper } from './styled'

const ModalCloseButtonView = ({ onClick }) => {

  return (
    <ButtonWrapper>
      <IconButton
        aria-label="Close"
        style={{
          padding: '8px 8px 0px 0px',
        }}
        onClick={onClick}
      >
        <CloseIcon />
      </IconButton>
    </ButtonWrapper>
  )
}

ModalCloseButtonView.propTypes = {
  onClick: PropTypes.func,
}

ModalCloseButtonView.defaultProps = {
  onClick: () => null,
}

export default ModalCloseButtonView
