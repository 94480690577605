import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import bgLogin from 'assets/images/bgLogin.png'
import Text from 'components/atoms/text'

//  background-image: url(${bgLogin});
export const Container = styled.div`
  display: flex;
  align-items: center;
  
  min-height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${bgLogin});
  flex: 1;
  height: 100vh;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
export const Wrapper = styled(Paper)`
  padding: 10px;
  max-width: 400px;
  width: 100%;
  margin-left: 10vh;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20vh;
  }
`
export const Title = styled(Text)`
  && {
    font-size: 42px;
    line-height: 80px;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 12px;
    font-family: 'Roboto', sans-serif;
  }
`
export const Subtitle = styled(Text)`
  font-style: normal;
  font-family: 'Roboto', sans-serif;
`

export const Header = styled.div`
  && {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
  }
`
