import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import CampaignList from '../campaignList/view'


const Campaigns = props => {
    const { slug } = props
    return (
        <div>
            <CampaignList slug={slug}/>
        </div>

    )
}

Campaigns.propTypes = {}

export default Campaigns
