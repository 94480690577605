import React, { useEffect, useState } from 'react'
import { Container, OutlineLabel, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Grid, TextField, Select, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'


const GroupBookingForm = ({formData, setFormData, communities}) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()

  const handleChange = event => {
    setFormData({ ...formData, group: event.target.value })
  }

  return (
    <Container>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={12}>
          <TextField label="Title" value={formData?.first_name} onChange={(e) => { setFormData({ ...formData, title: e.target.value }) }} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField label="Description" value={formData?.last_name} onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField label="Spaces" value={formData?.dob} onChange={(e) => { setFormData({ ...formData, spaces: e.target.value }) }} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField label="Price" value={formData?.dob} onChange={(e) => { setFormData({ ...formData, price: e.target.value }) }} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField label="Fee" value={formData?.dob} onChange={(e) => { setFormData({ ...formData, fee: e.target.value }) }} style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} sm={12}>
        
          <Select value={formData.group} variant='outlined' onChange={event => handleChange(event)}>
            {communities.map(item => (
              <MenuItem key={item.group?.slug} value={item?.group?.slug}>
                {item?.group?.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

    </Container>
  )
}

GroupBookingForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  communities: PropTypes.array.isRequired
}

export default GroupBookingForm
