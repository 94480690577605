import React from 'react';
import { IconButton, Snackbar } from '@mui/material';
import { Share } from '@mui/icons-material';
import { set } from 'date-fns';

const ShareButton = ({ slugEvent, setOpenSnack }) => {


  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const handleShare = () => {
    // Generate the URL for sharing based on the SLUG_EVENT variable
    const shareUrl = `${window.location.origin}/events/${slugEvent}`;

    // Open a share dialog using the Web Share API if available
    if (navigator.share) {
      navigator.share({
        title: 'Share Event',
        url: shareUrl,
      }).then(() => {
        console.log('');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else {
      // Fallback for browsers that do not support Web Share API
      // You can customize this part to provide alternative sharing options
      // For example, opening a share dialog for WhatsApp
      navigator.clipboard.writeText(shareUrl)
      .then(() => {
        setOpenSnack('Link copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy link to clipboard:', error);
      });
    }
  };

  return (
    <IconButton aria-label="share" onClick={handleShare}>
      <Share />
    </IconButton>
  );
};

export default ShareButton;
