import React, { useState, useEffect } from 'react';
import { Chip, Grid, Typography, Box, Button, Autocomplete, TextField } from '@mui/material';
import { updateCommunity, getTagsWithCategory } from 'api';

const TagsSection = ({ community }) => {
  const [tags, setTags] = useState([]);
  const [offerTags, setOfferTags] = useState(community?.event_tags || []);
  const [selectedTag, setSelectedTag] = useState(null);

  // Function to update the tags in the profile
  const updateTags = () => {
    const postTags = {
      tags: offerTags,
      slug: community?.slug
    };
    console.log('Post Tags:', postTags);

    updateCommunity(postTags)
      .then((r) => {
        console.log('Profile Updated:', r);
      })
      .catch((error) => {
        console.error('Error Updating Profile:', error);
      });
  };

  // Fetch tags from the API
  const getTagsInfo = () => {
    getTagsWithCategory()
      .then(r => {
        setTags(r.tags);
      })
      .catch(error => {
        console.log('The error is -> ', error);
      });
  };

  useEffect(() => {
    getTagsInfo();
  }, []);

  // Add a tag to the "Can Help With" list
  const handleAddOfferTag = () => {
    if (selectedTag && !offerTags.some((t) => t.name === selectedTag.name)) {
      setOfferTags([...offerTags, selectedTag]);
      setSelectedTag(null); // Clear selected tag after adding
    }
  };

  // Remove a tag from the "Can Help With" list
  const handleRemoveOfferTag = (tagToRemove) => {
    setOfferTags(offerTags.filter((tag) => tag.name !== tagToRemove.name));
  };

  useEffect(() => {
    if (offerTags.length > 0) {
      updateTags();
    }
  }, [offerTags]);

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Grid container spacing={3}>
        {/* Available Tags Selection */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Select Tags
          </Typography>

          {/* Autocomplete for Tag Selection */}
          <Autocomplete
            options={tags}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSelectedTag(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Tag" variant="outlined" />
            )}
          />

          {/* Add Button */}
          <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
            <Button variant="contained" onClick={handleAddOfferTag} disabled={!selectedTag}>
              Include Tag
            </Button>
          </Box>
        </Grid>

        {/* Selected "Can Help With" Tags Column */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Community tags
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {offerTags.map((tag) => (
              <Chip
                key={tag.name}
                label={tag.name}
                color="secondary"
                onDelete={() => handleRemoveOfferTag(tag)} // Add onDelete handler
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TagsSection;
