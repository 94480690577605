import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { getVenuePrice } from 'api'; // Ensure to import your API function
import { Button, Typography, Box, Grid, Tooltip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; // Import chevron icon
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'; // Import chevron icon
import PeopleIcon from '@mui/icons-material/People'; // Import people icon
import BoltIcon from '@mui/icons-material/Bolt'; // Import thunderbolt icon
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import calendar icon

const WeekSliderView = ({ slug, onDateSelect }) => {
  const [dates, setDates] = useState([]);
  const [prices, setPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(dayjs().startOf('week'));
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [selectedDate, setSelectedDate] = useState(null); // Add selectedDate state
  const [weekDays, setWeekDays] = useState([]);

  const endDate = startDate.add(7, 'day');

  const generateWeekDays = (start) => {
    const days = [];
    let currentDate = start;
    while (currentDate.isBefore(endDate)) {
      days.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    return days;
  };



  useEffect(() => {
    const days = generateWeekDays(startDate);
    setWeekDays(days);
    setDates(days.map(date => date.format('YYYY-MM-DD')));
    setDateRange({
      start: startDate.format('DD MMM'),
      end: endDate.format('DD MMM')
    });

    getVenuePrice(slug, {
      start: startDate.format('YYYY-MM-DD'),
      end: endDate.format('YYYY-MM-DD')
    }).then(r => {
      setPrices(r.prices);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
    });
  }, [slug, startDate]);

  const handlePrevWeek = () => {
    setStartDate(prevStartDate => prevStartDate.add(-7, 'day'));
  };

  const handleNextWeek = () => {
    setStartDate(prevStartDate => prevStartDate.add(7, 'day'));
  };

  const handleSelectedDate = (priceObject) => {
    if (priceObject) {
      setSelectedDate(priceObject.start); // Set the selected date
      onDateSelect(priceObject); // Pass the price object instead of just the date
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8px' }}>
        <Button onClick={() => handlePrevWeek()} variant="text" sx={{ marginRight: '8px' }}>
          <ChevronLeftIcon />
        </Button>
        <Typography variant="body1">{`${dateRange.start} - ${dateRange.end}`}</Typography>
        <Button onClick={() => handleNextWeek()} variant="text" sx={{ marginLeft: '8px' }}>
          <ChevronRightIcon />
        </Button>
      </Box>

      <Box sx={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '16px' }}>
        <Box sx={{ display: 'inline-flex' }}>
          {weekDays.map(date => {
            const priceObject = prices.find(p => dayjs(p.start).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'));
            const price = priceObject ? (priceObject.price * 1).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) : '-';
            const available = priceObject ? priceObject.available : '-';
            const isInstantBooking = priceObject?.instant_booking;
            const hasAvailability = available !== '-';
            const isSelected = selectedDate === date.format('YYYY-MM-DD');
            const hasActivity = priceObject?.activities > 0;

            return (
              <Box
                key={date.format('YYYY-MM-DD')}
                onClick={() => handleSelectedDate(priceObject)}
                sx={{
                  display: 'inline-block',
                  minWidth: hasAvailability ? '120px' : '100px',
                  border: isSelected ? '2px solid #1976d2' : '1px solid #ddd', // Thicker border if selected
                  borderRadius: '4px',
                  margin: '4px',
                  cursor: hasAvailability ? 'pointer' : 'not-allowed',
                  boxShadow: isSelected ? '0 0 8px rgba(0, 0, 0, 0.3)' : 'none', // Add shadow to the selected card
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      backgroundColor: hasAvailability ? 'secondary.main' : '#f5f5f5',
                      color: hasAvailability ? '#fff' : '#000',
                      padding: '4px',
                      textAlign: 'center',
                      margin: '0' // Remove top margin here
                    }}
                  >
                    {date.format('ddd DD')}
                  </Typography>
                </Box>
                <Box sx={{ padding: '8px' }}>
                  <Typography sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>
                    {price}
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginY: '8px' }}>
                    {hasAvailability && (
                      <PeopleIcon sx={{ fontSize: '18px', color: 'secondary.main', marginRight: '4px' }} />
                    )}
                    <Typography>{available}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                    <Grid container>
                      <Grid xs={6}>
                        {isInstantBooking && (
                          <Tooltip title="Instant confirmation booking">
                            <BoltIcon sx={{ fontSize: '20px', color: 'warning.main' }} />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                      >
                        {hasAvailability && (
                          <Tooltip title="Community activities or events on this day">
                            <CalendarTodayIcon sx={{ fontSize: '20px' }} />
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default WeekSliderView;
