import styled, { keyframes, css } from 'styled-components'
import { Grid, Typography } from '@mui/material';

export const Container = styled.div``

export const TopMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #FFF;
`

export const Hero = styled.div`
    padding-top: 3vh;
    justify-content: center;
    align-items: center;

    ${({ backgroundImage }) =>
        backgroundImage
            ? `background-image: url(${backgroundImage});
               background-size: cover;
               background-position: center;`
            : `background-color: #eee`}


    background-color: #F3F3FF;
    padding-left: 10vw;
    min-height: 20vh;
    padding-bottom: 3vh;

    @media (max-width: 768px) {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        min-height: 10vh;
    }
`
export const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
export const HeroBox = styled.div`
    width: 100%;
    justify-content: center;
    align-items: center;
    
    background-color: #fff;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`
export const HeroBoxMain = styled.div`
    width: 100%;    
    justify-content: center;
    align-items: center;
    height: 16vh;
    flex-direction: row;
    display: flex;
    position: relative;    
`

export const HeroAvatarContainer = styled.div`
    width: 150px;
    margin: 5px;
`
export const HeroMainData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
export const HeroMainText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px;
    height: 100%;
`
export const HeroMainGrid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 5px;
    height: 10px;
`
export const HeroBoxDetails = styled.div`
    width: 100%;    
    justify-content: center;
    align-items: center;
    background-color: #fff;

`

export const IconInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 10px;    
`
export const InfoTextSmall = styled.div`
    align-items: start;
    font-size: 14px;
`

export const InfoTextMedium = styled.div`
    font-size: 16px;
`
export const ForgotButton = styled.a`
    position: fixed;
    bottom: 80px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    background-color: #FFF;
    color: #000;
    border: 1px solid #000;
    
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
    padding: 10px;
`

export const ModalContent = styled.div`
    background-color: #fff;
    max-height: 80vh;
    overflow-y: auto;  // Enable vertical scrolling when content 
`

export const InfoGrid = styled(Grid)`
    display: flex;
    flex-direction: row;
    align-items: center;
    content-align: center;
    padding-left: 10px;
`;

export const AccordionContainer = styled.div`
    margin: 25px 15px;
`;    

export const AccordionSection = styled.div`
    margin: 10px 0px 20px;
`;

export const AccordionTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    text-align: center;
`;

export const AccordionSectionTitle = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0;
`;
export const AccordionSectionContent = styled.div`
    font-size: 14px;
    font-weight: 400;    
    margin: 5px 0;
`;

export const AccordionSectionShare = styled.div`
    font-size: 14px;
    margin: 5px auto;
    padding: 0 5px;
    text-align: center;
`;

export const AccordionSectionQR = styled.div`
    font-size: 14px;
    margin: 15px auto;
    padding: 0 5px;
    text-align: center;
`;

// Define the sliding animation
const slideIn = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;