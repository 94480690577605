import React from 'react'
import PropTypes from 'prop-types'
import { TextStyled } from './styled'

const Text = ({ children, variant, className, type }) => (
  <TextStyled variant={variant} className={className} type={type}>
    {children}
  </TextStyled>
)

Text.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
}

Text.defaultProps = {
  children: null,
  variant: null,
  className: '',
  type: null,
}

export default Text
