import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, FormContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import InternalHeader from '../../general/internalHeader/view'
import { getEvent} from 'api'

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import coworking from 'assets/images/coworking.jpg'
import noAvatar from '../../../../assets/images/no-avatar.png'
import EventForm from '../eventForm/view';

const EventUpdate = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [theEvent, setEvent] = useState([])


  const navigate = useNavigate()

  
  function getEventInfo() {
    getEvent(slug).then(r => {
      setEvent(r.event)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function navigateEvent(event) {
    navigate(`/events/${event?.slug}`);
    //window.location.href = '/login'
  }

  function headerActionMethod(firstLoad) {
  }

  useEffect(() => {
    getEventInfo(true)

  }, [])





  const avatar = noAvatar
  return (
    <Container>
      <InternalHeader title={'New event'} subtitle={''} avatar={avatar} background={coworking} actionTitle='Save event' actionMethod={headerActionMethod} />
      <FormContainer>
        <EventForm event={theEvent}/>
      </FormContainer>
    </Container>
  )
}

EventUpdate.propTypes = {}

export default EventUpdate
