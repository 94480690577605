import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { CircularProgress, Grid } from '@mui/material'
import VenueListItem from '../venueListItem/view'
import { useNavigate } from 'react-router'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'

const VenueList = ({ slug, venues, setSelectedVenue }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()

  const navigate = useNavigate()

  function navigateVenue(venue) {    
    navigate(`/community/${slug}/venue/${venue?.slug}`);      
    //window.location.href = '/login'
  }

  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        { venues?.length === 0 && (
          <NoResultsPlaceholder firstSentence={t('No partners found')} lastSentence={t('Try something different or wait a few minutes')} />
        )}
        {venues?.map((item, index) => (
          <Grid item xs={2} sm={2} md={2} lg={1} key={index}>
            <VenueListItem venue={item?.venue} setSelectedVenue={venue => navigateVenue(venue)} />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

VenueList.propTypes = {
  slug: PropTypes.string,
  venues: PropTypes.array,
}

export default VenueList
