import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  HeroContainer,
  LowerRowContainer,
  StyledAvatar,
  StyledTabs,
  CommunityName,
  FirstRowContainer,
  CommunityStatus,
  JoinButton,
  SecondRowContainer,
  InfoContainer,
  AvatarContainer,
  ContentContainer,
  CommunityBasic
} from './styled'

import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { getEventTicket, getPublicEventTicket } from 'api'
import { useNavigate } from 'react-router'

import coworking from 'assets/images/coworking.jpg'
import logo from 'assets/images/logo_epic.png'

const BookingHeader = ( { title, subtitle, avatar, background } ) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [theParticipants, setParticipants] = useState([])
  const [myTicket, setMyTicket] = useState(null)
  const [theEvent, setEvent] = useState([])
  const [userModal, setUserModal] = useState(false)
  const [joinModal, setJoinModal] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [ticketInfo, setTicketInfo] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))


  if (background == null) {
    background = coworking
  }
  return (
    <Container>
      <HeroContainer elevation={0} background={background}>
        {/* <StyledAvatar alt="Avatar" src={logo} /> */}
        {/* Content for the Hero section */}
      </HeroContainer>

      <InfoContainer>
        <AvatarContainer></AvatarContainer>
        <ContentContainer>

          <LowerRowContainer>
            <FirstRowContainer>
              <CommunityBasic>
                <CommunityName>{title}</CommunityName>
                <CommunityStatus>{subtitle}</CommunityStatus>
              </CommunityBasic>

              {/* <JoinButton variant="contained" color="primary">Join</JoinButton> */}
            </FirstRowContainer>


          </LowerRowContainer>
        </ContentContainer>
      </InfoContainer>
    </Container>

  )
}

BookingHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  avatar: PropTypes.string,
  background: PropTypes.string
}

export default BookingHeader
