import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import BookingHeader from '../bookingHeader/view'
import EventList from '../../community/eventList/view'
import { getBooking } from 'api'
import { Grid, Button, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { REACT_APP_STRIPE_KEY } from 'config/api';

import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';

const BookingSingle = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [booking, setBooking] = useState(null)
  const [workSpace, setWorkSpace] = useState([])
  const [amenities, setAmenities] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [communities, setCommunities] = useState([])
  const [gallery, setGallery] = useState(null)
  const [sessionId, setSessionId] = useState(null)

  const [prices, setPrices] = useState([])

  const [value, setValue] = useState("0");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [highlightedDays, setHighlightedDays] = useState([1, 5, 7, 10, 20, 27])
  const [isLoading, setIsLoading] = useState(false);

  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMonthChange = (date) => {
    //if (requestAbortController.current) {
    // make sure that you are aborting useless requests
    // because it is possible to switch between months pretty quickly
    //requestAbortController.current.abort();
    //}

    setIsLoading(true);
    setHighlightedDays([]);
    //fetchHighlightedDays(date);
  };

  function getBookingInfo(firstLoad) {
    getBooking(slug).then(r => {
      setBooking(r)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  useEffect(() => {
    getBookingInfo(true)
  }, [])


  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <BookingHeader title={booking?.venue?.name} subtitle={booking?.venue?.single_address} avatar={booking?.venue?.image} background={booking?.venue?.background} />
      <Grid container>
        <Grid item xs={12} md={9}>
          <TabContext value={value}>
            <SecondRowContainer>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Overview" value="0" />
                <Tab label="Workspace" value="1" />
                <Tab label="Amenities" value="2" />
                <Tab label="Events" value="3" />
                <Tab label="Community" value="4" />
                <Tab label="Gallery" value="5" />
              </Tabs>
              <div>
                {/* Tab content sections */}
                <TabPanel value="0" index={0}>
                  {booking?.name}
                  <br />
                  {booking?.description}

                </TabPanel>
                <TabPanel value="1" index={1}>
                  {/* Content for workspace section */}
                  Workspace section
                </TabPanel>
                <TabPanel value="2" index={2}>
                  {/* Content for amenities section */}

                </TabPanel>
                <TabPanel value="3" index={3}>
                  {/* Content for Events section */}

                </TabPanel>
                <TabPanel value="4" index={4}>
                  {/* Content for Bookings section */}
                  communites section content
                </TabPanel>
                <TabPanel value="5" index={5}>
                  {/* Content for Partners section */}
                  Gallery
                </TabPanel>
              </div>
            </SecondRowContainer>
          </TabContext>
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={selectedDate}
              loading={isLoading}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
            />
          </LocalizationProvider>
        </Grid>

      </Grid>

    </Container>
  )
}

BookingSingle.propTypes = {}

export default BookingSingle
