import React, { useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button, Grid } from '@mui/material'
import MemberListItem from '../memberListItem/view'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'

const CampaignHeader = ({ openModal }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()


  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{marginBottom: '20px'}}>
      <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={() => openModal(true)}>
          {t('Create Campaign')}
        </Button>
      </Grid>
    </Grid>
  )
}

CampaignHeader.propTypes = {
    openModal: PropTypes.func

}

export default CampaignHeader
