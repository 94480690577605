import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button, Grid, Typography, CardMedia, Box, Chip, Modal, IconButton } from '@mui/material'
import { API_BASE_URL } from '../../../../config/api'
import noAvatar from '../../../../assets/images/no-avatar.png'
import { ArrowBack } from '@mui/icons-material';
import { useProfile } from 'context';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Import the external link icon
import CloseIcon from '@mui/icons-material/Close'; // Import the close icon

const MemberDetail = ({ member, goBack, role, validateAccount }) => {
  const [showButton, setShowButton] = React.useState(role === 'admin' && member.status === 'PP')
  const { profile } = useProfile();
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [openImage, setOpenImage] = React.useState(false); // State to manage the image modal display

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  console.log('Member:', member)

  function isValidURL(url) {
    const pattern = /^((https?|ftp):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
    return pattern.test(url);
  }

  function openWebsite(url) {
    if (url) {
      if (isValidURL(url)) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = 'https://' + url;
        }
      }
      window.open(url, '_blank');
    }
  }

  const handleAccountValidation = (result) => {
    validateAccount(result)
    setShowButton(false)
    console.log('Validating account for:', member?.user?.first_name);
  }

  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {showButton && (
            <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleAccountValidation(true)}
                sx={{ marginRight: '10px' }}
              >
                Validate
              </Button>
              <Button
                variant="contained"
                color="warning"
                onClick={() => handleAccountValidation(false)}
              >
                Reject
              </Button>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardMedia
            component="img"
            src={member?.user?.avatar ? `${API_BASE_URL}${member?.user?.avatar}` : noAvatar}
            alt="Member Image"
            onError={handleImageRenderError}
            onClick={() => setOpenImage(true)} // Open the image in modal on click
            sx={{
              width: {
                xs: '60%', // 60% width on xs screens
                sm: '100%', // 100% on sm screens or larger
              },
              margin: {
                xs: '0 auto', // Center the image on xs screens
                sm: 0, // No margin needed on sm screens or larger
              },
              display: 'block', // Ensure it's treated as a block element for centering
              borderRadius: '10px', // Keep the border-radius the same
              cursor: 'pointer', // Show pointer cursor for clickable image
            }}
          />
        </Grid>
        <Grid item sm={9} xs={12}>
          <Typography variant="h5" gutterBottom component="div">
            {member?.user?.first_name} {member?.user?.last_name}
          </Typography>
          <br />
          <Typography variant="h6" gutterBottom component="div">
            {member?.user?.alias}
          </Typography>
          <Grid container spacing={1} alignItems="center" justifyContent="flex-start" sx={{ marginTop: '10px' }}>
            {member?.user?.link_1 && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="button_color"
                  fullWidth
                  onClick={() => openWebsite(member?.user?.link_1)}
                  endIcon={<OpenInNewIcon />} // Add the external link icon
                >
                  Contact Me
                </Button>
              </Grid>
            )}
            {member?.user?.link_2 && (
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="button_color"
                  fullWidth
                  onClick={() => openWebsite(member?.user?.link_2)}
                  endIcon={<OpenInNewIcon />} // Add the external link icon
                >
                  What I do
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        {member?.user?.tags_offer && member?.user?.tags_offer.length > 0 && (
          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <Typography variant="body">
              {member?.user?.first_name} {t('is interested in:')}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginTop: '10px' }}>
              {member?.user?.tags_offer?.map((tag) => (
                <Chip
                  key={tag.name}
                  label={tag.name}
                  color="secondary"
                />
              ))}
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
          <Typography>
            {member?.user?.description}
          </Typography>
        </Grid>
      </Grid>

      {/* Modal for enlarged image */}
      <Modal
        open={openImage}
        onClose={() => setOpenImage(false)} // Close modal on clicking background
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{
          position: 'relative',
          width: {
            xs: '80%', // Set width to 80% on xs screens
            sm: '60%', // Set width to 60% on sm screens or larger
          },
          height: {
            xs: '80%', // Set height to 80% on xs screens
            sm: '60%', // Set height to 60% on sm screens or larger
          },
        }}>
          <IconButton
            onClick={() => setOpenImage(false)} // Close modal on click
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: 'white',
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
          <CardMedia
            component="img"
            src={member?.user?.avatar ? `${API_BASE_URL}${member?.user?.avatar}` : noAvatar}
            alt="Member Image"
            onError={handleImageRenderError}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain', // Maintain aspect ratio
              borderRadius: '10px',
            }}
          />
        </Box>
      </Modal>
    </Container>
  )
}

MemberDetail.propTypes = {
  member: PropTypes.object,
}

export default MemberDetail
