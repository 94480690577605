import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import wLogo from 'assets/icons/WLogo.svg'
import { getClientInfo } from 'api'
import psl from 'psl'
import { Logo } from './styled'

const NavBarView = () => {
  const [infoLogo, setInfoLogo] = useState()

  function getLoginInfo() {
    const parsed = psl.parse(window.location.hostname)
  }

  useEffect(() => {
    getLoginInfo()
  }, [])


  return (
    <AppBar position='static'>
      <Toolbar>
        <Logo src={infoLogo?.logo !== undefined ? infoLogo.logo : wLogo} />
      </Toolbar>
    </AppBar>
  )
}

export default NavBarView
