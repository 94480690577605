import styled from 'styled-components'
import { Badge } from '@mui/material';

export const Container = styled.div`
    margin: 20px;  
    @media (max-width: 768px) {
        width: 100%; // Full screen on mobile devices
        margin: 10px 0;  

    }
`
export const HeroBoxMain = styled.div`
    width: 100%;    
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
`

export const HeroAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 150px;
  margin: 5px;
`;

export const AvatarWrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

export const HeroPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25%;
  font-size: 20px;
  font-weight: 700;
`;

export const HeroMainData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
export const HeroMainText = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px;
    height: 100%;
`
export const HeroMainGrid = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 5px;
    height: 10px;
`
export const HeroBoxDetails = styled.div`
    width: 100%;    
    justify-content: center;
    align-items: center;
    background-color: #fff;
`
export const InfoTextSmall = styled.div`
    align-items: start;
    font-size: 14px;
`

export const InfoTextMedium = styled.div`
    font-size: 16px;
`

// Custom styled Badge to adjust its appearance
export const CustomBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#1976d2', // Customize this color as needed
        color: '#fff', // White color for the dot
    },
}));

export const ModalContent = styled('div')`
  background-color: #fff;
  max-height: 70vh;
  overflow-y: auto;  // Enable vertical scrolling when content;
  width: 30%; // Full screen on mobile devices
  margin: 0 auto; /* Center horizontally */
  justify-content: center; /* Center horizontally */
  margin-top: 10vh;
  padding: 20px 20px 30px;
  margin-bottom: 15vh;
  
  @media (max-width: 768px) {
      width: 90%; // Full screen on mobile devices
      height: 80vh;
      margin: 10vh auto; /* Center horizontally */
  }
`
