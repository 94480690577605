import React from 'react'
import { useNavigate } from 'react-router-dom'
import View from './view'

const Home = ({ ...props }) => {
  //const navigate = useNavigate()
  //const handleClickOnLink = to => navigate(to)
  return <View {...props} />
}

export default Home
