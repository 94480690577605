import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled('div')`
min-height: 100vh;
`

export const ModalContent = styled('div')`
  background-color: #fff;
  max-height: 70vh;
  overflow-y: auto;  // Enable vertical scrolling when content;
  width: 30%; // Full screen on mobile devices
  margin: 0 auto; /* Center horizontally */
  justify-content: center; /* Center horizontally */
  margin-top: 10vh;
  padding: 20px 20px 30px;
  margin-bottom: 15vh;
  
  @media (max-width: 768px) {
      width: 90%; // Full screen on mobile devices
      height: 80vh;
      margin: 10vh auto; /* Center horizontally */
  }
`


export const SecondRowContainer = styled(Box)({
    maxWidth: '80vw', // Default width for desktop

    // Center the container on desktop
    margin: '0 auto',
  
    // Media query for screens with a maximum width of 600px (adjust as needed)
    '@media (max-width: 900px)': {
      maxWidth: '100%', // Set width to 100% for smaller screens
    },
  });