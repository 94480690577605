import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import ProfileHeader from '../profileHeader/view'
import { getProfile, updateProfile, getTagsWithCategory, updateTenant } from 'api'
import { TextField, FormControlLabel, Switch, FormGroup, Typography, Button, FormControl, Box, Grid, Backdrop, CircularProgress } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useProfile } from 'context';
import noAvatar from '../../../../assets/images/no-avatar.png'
import { API_BASE_URL } from 'config/api';
import coworking from 'assets/images/coworking.jpg'
import TagsSection from './tagSection';
import { post } from 'api/utils';
import { useNavigate } from 'react-router-dom'

const Profile = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const { profile, setProfile } = useProfile();
  const navigate = useNavigate()
  console.log('The profile in update is -> ', profile)
  const [myProfile, setMyProfile] = useState({
    first_name: profile?.first_name,
    last_name: profile?.last_name,
    email: profile?.email,
    dob: profile?.dob,
    alias: profile?.alias,
    avatar: profile?.avatar,
    background: profile?.background,
    link_1: profile?.link_1,
    link_2: profile?.link_2,
    link_3: profile?.link_3,
    description: profile?.description,
    tags_need: profile?.tags_need,
    tags_offer: profile?.tags_offer,
    post_code: profile?.post_code,
    single_address: profile?.single_address,
    phone: profile?.phone,
  })

  const [tenantInfo, setTenantInfo] = useState({
    name: '',
    description: '',
    contact_email: '',
    slug: '',
  });

  const [value, setValue] = useState("0");
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateTheProfile = () => {
    setLoading(true);
    updateProfile(myProfile).then(r => {
      updateTenantInfo()
      //getProfileInfo()      
    })
      .catch(error => {
        setLoading(false);
      })
  }

  const updateTenantInfo = () => {
    const updateData = {
      slug: tenantInfo.slug, // Assuming you are using the slug to identify the tenant
      name: tenantInfo.name,
      description: tenantInfo.description,
      contact_email: tenantInfo.contact_email,
    };

    // Call the appropriate API endpoint to update tenant info
    updateTenant(updateData).then(r => {
      getProfileInfo()
    })
      .catch(error => {
        setLoading(false);
      })
  };

  function getProfileInfo(firstLoad) {
    getProfile(slug).then(r => {
      console.log('The response is -> ', r);
      setProfile(r)
      console.log(r?.tenants)
      if (r?.tenants?.length > 0) {
        setTenantInfo({
          name: r?.tenants[0]?.name,
          description: r?.tenants[0]?.description,
          contact_email: r?.tenants[0]?.contact_email,
          slug: r?.tenants[0]?.slug,
        })
      }
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      }
      )
  }

  function getTagsInfo(firstLoad) {
    getTagsWithCategory(slug).then(r => {
      console.log('The tags are -> ', r);
      setTags(r.tags)
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
      }
      )
  }

  useEffect(() => {
    getProfileInfo(true)
    getTagsInfo(true)

  }, [])

  const handleClickForgot = () => {
    navigate('/reset');
  };



  const avatar = myProfile?.avatar ? `${API_BASE_URL}${myProfile?.avatar}` : noAvatar
  const background = myProfile?.background ? `${API_BASE_URL}${myProfile?.background}` : coworking

  return (
    <Container>
      {/* Global toolbar */}
      {/* Profile header */}
      <ProfileHeader title={myProfile?.name} subtitle={myProfile?.single_address} avatar={avatar} background={background} showAction={false} onUpdate={getProfileInfo} setLoading={setLoading} />
      <TabContext value={value}>
        <SecondRowContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Personal Info" value="0" />
            <Tab label="Professional info" value="1" />
            {/* <Tab label="Privacy" value="2" /> */}
            <Tab label="Interest" value="3" />
            <Tab label="Account" value="4" />
          </Tabs>
          <div>
            {/* Tab content sections */}
            <TabPanel value="0" index={0}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Name" value={myProfile?.first_name} onChange={(e) => { setMyProfile({ ...myProfile, first_name: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Surname" value={myProfile?.last_name} onChange={(e) => { setMyProfile({ ...myProfile, last_name: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="emai" disabled value={myProfile?.email} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Job Title" value={myProfile?.alias} onChange={(e) => { setMyProfile({ ...myProfile, alias: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Post Code" value={myProfile?.post_code} onChange={(e) => { setMyProfile({ ...myProfile, post_code: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Telephone" value={myProfile?.phone} onChange={(e) => { setMyProfile({ ...myProfile, phone: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField label="Address" value={myProfile?.single_address} onChange={(e) => { setMyProfile({ ...myProfile, single_address: e.target.value }) }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Bio"
                      value={myProfile?.description}
                      onChange={(e) => { setMyProfile({ ...myProfile, description: e.target.value }) }}
                      multiline
                      rows={4} />
                  </FormControl>
                </Grid>
              </Grid>

            </TabPanel>

            <TabPanel value="1" index={1}>
              {/* Content for Links Section */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Professional Links</Typography> {/* Section Title */}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Contact Link"
                      value={myProfile?.link_1}
                      onChange={(e) => setMyProfile({ ...myProfile, link_1: e.target.value })}
                    />
                    <small>Add here your preferred contact link (LinkedIn, Calendy, Email address...)</small>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Project Link"
                      value={myProfile?.link_2}
                      onChange={(e) => setMyProfile({ ...myProfile, link_2: e.target.value })}
                    />
                    <small>Add here a link with your main project or anything that can help others understand you better (LinkedIn, Instagram, Personal website...)</small>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Other Link"
                      value={myProfile?.link_3}
                      onChange={(e) => setMyProfile({ ...myProfile, link_3: e.target.value })}
                    />
                    <small>Add here any other relevant link you'd like to share</small>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Content for Company Info Section */}
              <Grid container spacing={2} style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                  <Typography variant="h6">Company info</Typography> {/* Section Title */}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Company Name"
                      value={tenantInfo?.name}
                      onChange={(e) => setTenantInfo({ ...tenantInfo, name: e.target.value })}
                    />
                    <small>Add your company name</small>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Company Description"
                      value={tenantInfo?.description}
                      onChange={(e) => setTenantInfo({ ...tenantInfo, description: e.target.value })}
                    />
                    <small>Add a brief description of your company</small>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label="Contact Email"
                      value={tenantInfo?.contact_email}
                      onChange={(e) => setTenantInfo({ ...tenantInfo, contact_email: e.target.value })}
                    />
                    <small>Add your company's contact email</small>
                  </FormControl>
                </Grid>

                {/* Add more fields for Company Info as needed */}
              </Grid>
            </TabPanel>

            <TabPanel value="2" index={2}>
              {/* Content for Rules section */}
              <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5" gutterBottom component="div">
                      Personal Info
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" gutterBottom component="div">
                      If the event is public, what data do you want to show? This is what people with the event or community link will see, no mater if they are registered or not
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} label="Share name" />
                      <FormControlLabel control={<Switch />} defaultChecked label="Share surname" />
                      <FormControlLabel control={<Switch />} label="Share email" />
                      <FormControlLabel control={<Switch />} label="Share phone" />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2" gutterBottom component="div">
                      If the event is private, what data do you want to show? This is what people from the same community can see.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} disabled label="Share name" />
                      <FormControlLabel control={<Switch />} defaultChecked label="Share surname" />
                      <FormControlLabel control={<Switch />} label="Share email" />
                      <FormControlLabel control={<Switch />} label="Share phone" />
                    </FormGroup>
                  </Grid>
                  <Typography variant="h6" gutterBottom component="div">
                    Professional Info
                  </Typography>
                  <Grid item xs={12} sm={12}>
                    <FormGroup>
                      <FormControlLabel control={<Switch defaultChecked />} label="Allow Partners" />
                      <FormControlLabel control={<Switch />} defaultChecked label="Allow Activities" />
                      <FormControlLabel control={<Switch />} label="Allow Fees" />
                      <FormControlLabel control={<Switch />} label="Enable gallery" />
                      <FormControlLabel control={<Switch />} label="Allow user generated content" />
                    </FormGroup>
                  </Grid>

                </Grid>
              </div>


            </TabPanel>
            <TabPanel value="3" index={3}>
              <TagsSection profile={myProfile} tags={tags} />
            </TabPanel>
            <TabPanel value="4" index={4}>
              {/* Password Reset Section */}
              <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  Reset Password
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                  If you forgot your password, you can reset it by clicking the button below.
                </Typography>
                <Button variant="contained" color="secondary" onClick={handleClickForgot}>
                  Reset Password
                </Button>
              </Box>

              {/* Account Deletion Section */}
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  Delete Account
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                  To permanently delete your account, please send us an email at{' '}
                  <a href="mailto:hello@the-epic.space">hello@the-epic.space</a>.
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Make sure to include the following details in your email:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Your full name
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      The email address associated with your account
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      A short reason for deleting the account (optional)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      A confirmation that you understand account deletion is permanent
                    </Typography>
                  </li>
                </ul>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Once we receive your email, we will process your request within a few business days.
                </Typography>
              </Box>
            </TabPanel>
            <Grid item xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="secondary" aria-label="add" onClick={() => updateTheProfile()}>
                Save
              </Button>
            </Grid>
          </div>
        </SecondRowContainer>
      </TabContext>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}

Profile.propTypes = {}

export default Profile
