import styled from 'styled-components'
import Paper from '@mui/material/Paper'
import bgLogin from 'assets/images/bgLogin.png'
import Text from 'components/atoms/text'
import Box from '@mui/material/Box'

//  background-image: url(${bgLogin});
export const Container = styled.div`
  display: flex;
  align-items: center;
  
  min-height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${bgLogin});
  flex: 1;
  height: 100vh;
  @media (max-width: 768px) {
    justify-content: center;
  }
`
export const Wrapper = styled(Paper)`
  padding: 10px;
  max-width: 400px;
  width: 100%;
  margin-left: 10vh;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 20vh;
  }
`
export const Title = styled(Text)`
  && {
    font-size: 64px;
    line-height: 80px;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 12px;
    font-family: 'Roboto', sans-serif;
  }
`
export const Subtitle = styled(Text)`
  font-style: normal;
  font-family: 'Roboto', sans-serif;
`

export const Header = styled.div`
  && {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
  }
`
export const ModalContent = styled('div')`
  background-color: #fff;
  max-height: 70vh;
  overflow-y: auto;  // Enable vertical scrolling when content;
  width: 30%; // Full screen on mobile devices
  margin: 0 auto; /* Center horizontally */
  justify-content: center; /* Center horizontally */
  margin-top: 10vh;
  padding: 20px 20px 30px;
  margin-bottom: 15vh;
  
  @media (max-width: 768px) {
      width: 90%; // Full screen on mobile devices
      height: 80vh;
      margin: 10vh auto; /* Center horizontally */
  }
`