import React from 'react'
import PropTypes from 'prop-types'
import Breadcrumb from 'components/molecules/breadcrumb'
import MainNavBar from 'components/organisms/mainNavBar/view'
import PublicNavBar from 'components/organisms/publicNavBar/view'
import { Container, Content, Header } from './styled'
import { Typography, Box, Link } from '@mui/material'

const LayoutView = ({
  children,
  theme,
  breadcrumb,
  profile,
}) => (
  <Container>
    <Content>
      {profile ? <MainNavBar /> : <PublicNavBar />}
      {React.cloneElement(children, { theme, profile })}
      <Header>
        <Box
          component="footer"
          sx={{
            backgroundColor: '#f5f5f5',
            padding: '20px',
            marginTop: '4em', // Push the footer to the bottom
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Email: <Link href="mailto:hello@the-epic.space">hello@the-epic.space</Link>
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Link href="/privacy-policy" color="text.primary" sx={{ mr: 2 }}>
              Privacy Policy
            </Link>
            <Typography variant="body2" color="text.secondary">
              © {new Date().getFullYear()} Epic Spaces Ltd. All rights reserved.
            </Typography>
          </Box>
        </Box>
      </Header>
    </Content>
  </Container>
)

LayoutView.defaultProps = {
  children: <></>,
  breadcrumb: true,
  profile: null,
}

LayoutView.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object.isRequired,
  breadcrumb: PropTypes.bool,
  profile: PropTypes.object,
}

export default LayoutView
