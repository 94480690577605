import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import EventList from '../eventList/view'


const Activity = props => {
    const { community, upcomingEvents, pastEvents, setCommunityJoin } = props
    return (
        <div>
            <EventList community={community} upcomingEvents={upcomingEvents} pastEvents={pastEvents} setCommunityJoin={setCommunityJoin}/>
        </div>

    )
}

Activity.propTypes = {}

export default Activity
