import styled from 'styled-components'
import { Typography, Paper, Icon } from '@mui/material';

export const Container = styled.div``


export const HeroImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`
export const CardAvatarContainer = styled.div`
    max-width: 85px;
    margin: 0px;
`
export const CardBox = styled(Paper)`
    display: 'flex',
    flexDirection: 'row',
    min-width: 210px;
    margin: 0 8px;
    padding: 4px;
    text-align: center;
    flex-shrink: 0;    
    align-items: center;    
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    flex-direction: row;
    width: 320px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 80%;
    }
`

export const CardContainer = styled.div`

    overflow-x: auto;
    display: flex;
    width: 100%;
    padding: 10px 0px;
    margin: 15px 0;
`

export const ForgotButton = styled.a`
    position: fixed;
    bottom: 80px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    background-color: #FFF;
    color: #000;
    border: 1px solid #000;
    
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
    padding: 10px;
`

export const ModalContent = styled.div`
    background-color: #fff;
    max-height: 80vh;
    overflow-y: auto;  // Enable vertical scrolling when content 
`

export const CardBoxHeader = styled.div`
    line-height: 1em;
    border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.main};
    margin-bottom: 4px;
    display: flex;
    flex-direction: row;
`

export const CardBoxTitle = styled.div`
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 4px;
    padding: 2px 8px 0px;
    text-align: left;
    flex: 1;
`
export const CardBoxIcon = styled.div`
    width: 20px;
`
// If you want to make the ChevronRight icon smaller
export const ChevronRight = styled(Icon)`
    font-size: 12px; // Adjust the size as needed
`

export const CardBoxMain = styled.div`
    width: 100%;    
    justify-content: flex-start;
    align-items: flex-start;
    height: 90px;
    flex-direction: row;
    display: flex;
`

export const TypoTitle = styled.div`
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    padding: 2px 8px 0px;
`
export const TypoText = styled.div`
    text-align: left;
    font-size: 13px;
    margin-bottom: 8px;
    padding: 0px 8px;
`

export const EllipsisText = styled.div`
    overflow: hidden;
    max-height: 4em;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
`

export const LinkText = styled.span`
  color:${({ theme }) => theme.palette.secondary.main};
  padding: 0px;
`