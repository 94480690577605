import React, { useEffect, useState } from 'react'
import { getOffices } from 'api'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import { styled } from '@mui/system';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import Badge from '@mui/material/Badge'
//import { useTranslation } from 'react-i18next'
import formatWords from 'components/atoms/formatWords/formatWords'
import logo from 'assets/icons/WLogo.svg'
import { useLocation } from 'react-router-dom'
import routes from 'config/routes'
import { logout } from 'api/utils'
import { Container, Content, HeaderContainer, ItemText, Logo, MenuItem, MenuLink, StyledAccountCircleIcon } from './styled'



const IconWrapper = styled('div')({
  color: (theme) => theme.palette.grey[500],
});

const Header = ({ theme }) => (
  <HeaderContainer>
    {theme?.logo && <Logo src={theme.logo} />}
    {!theme?.logo && <Logo src={logo} />}
  </HeaderContainer>
)

Header.propTypes = {
  theme: PropTypes.object,
}

Header.defaultProps = {
  theme: '',
}

const Item = ({ active, to, classes, label, Icon }) => (
  <MenuLink to={to} active={active}>
    <MenuItem button>
      {Icon && (
        <ListItemIcon>
          <Icon className={classes.icon} style={{ color: active ? '#fff' : '#9e9e9e' }} />
        </ListItemIcon>
      )}
      <ItemText primary={label} active={active} />
    </MenuItem>
  </MenuLink>
)

Item.propTypes = {
  active: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  to: PropTypes.string,
  classes: PropTypes.object,
  label: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
}

Item.defaultProps = {
  active: false,
  to: '',
  classes: '',
  label: '',
  Icon: null,
}

const Notifications = ({ className }) => (
  <Badge badgeContent={4} color='error' className={className}>
    <NotificationsNoneOutlinedIcon />
  </Badge>
)

Notifications.propTypes = {
  className: PropTypes.string,
}

Notifications.defaultProps = {
  className: '',
}

export default function PermanentDrawerLeft({ className, options }) {
  const { pathname } = useLocation()
  //const { t } = useTranslation()
  const [data, setData] = useState()

  useEffect(() => {
    //if (data === undefined) getData()
  }, [data])

  const AccountAvatar = () => (
    <StyledAccountCircleIcon />
  )

  const filterOptions = []
  if (data !== undefined) data?.web_sections?.map(ws => filterOptions.push(formatWords(ws.code)))
  const filterData = []
  options.map(o => {
    filterOptions.map(f => {
      if (f === o.label) filterData.push(o)
      return ''
    })
    return ''
  })

  return (
    <Container variant='permanent' anchor='left' className={className}>
      <Header theme={data?.theme} />
      <Content>
        <List>
          {filterData.map(({ id, label, to, Icon }) => (
            <Item key={id} to={to} active={pathname.includes(to)} label={label} Icon={Icon} />
          ))}
        </List>
        <List>
          {/*
          <MenuItem button>
            <ListItemIcon>
              <Notifications className={classes.icon} />
            </ListItemIcon>
            <ItemText primary={t('drawer.notifications')} />
          </MenuItem>
           */}
          <Item Icon={AccountAvatar} to={routes.profile} label='Account'/>
          <MenuItem onClick={() => logout()}>
            <ItemText primary='LOGOUT' />
          </MenuItem>
        </List>
      </Content>
    </Container>
  )
}

PermanentDrawerLeft.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
}

PermanentDrawerLeft.defaultProps = {
  className: '',
  options: [],
}
