import { styled } from '@mui/system';
import { Chip } from '@mui/material';

export const Container = styled('div')``;

export const Card = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)';
`;

export const Media = styled('div')`
  // width: 150;
  // height: 150;
`;

export const WhiteBox = styled('div')`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 6px;
  margin-right: 6px;
`;

export const Content = styled('div')`
  padding-left: 16px;
`;
  

export const AvatarContainer = styled('div')`
  padding: 5px;
  position: relative; // Add this line
`;

export const InnerChip = styled(Chip)`
  position: absolute;
  bottom: 10px;
  right: 5px; // Adjust the left position as needed
  background-color: #fff;
`;
  
