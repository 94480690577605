import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import CommunityHeader from '../communityHeader/view'
import MemberList from '../memberList/view'
import MemberDetail from '../memberDetail/view'
import VenueList from '../venueList/view'
import EventList from '../eventList/view'
import CommunityForm from '../communityForm/view';
import ModalLogin from '../../general/modalLogin/view'
import ModalSignup from 'components/pages/general/modalSignup/view';
import { getCommunityPublic, getPublicEventTicket, joinCommunity } from 'api'
import { Box, Fab, Modal, Button, FormGroup, List, ListItem, ListItemText, ListItemAvatar, Avatar, Grid } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import SideMenu from '../sideMenu/view';
import { Gallery } from "react-grid-gallery";

import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import noAvatar from '../../../../assets/images/no-avatar.png'
import { API_BASE_URL } from 'config/api';
import coworking from 'assets/images/coworking.jpg'
import AboutUs from './aboutUs';
import Members from './members';
import Activity from './activity';
import Partners from './partners';
import Manage from './manage';
import Campaigns from './campaign';

const CommunitySinglePublic = props => {

  const { profile } = props
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const params = useParams()
  const { slug } = params
  const navigate = useNavigate();
  const [community, setCommunity] = useState(null)
  const [member, setMember] = useState(null)
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [venues, setVenues] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState(0);
  const [name, setName] = useState("AI Caramaba")
  const [description, setDescription] = useState("Ai caramba is a community of people who love AI")
  const [communitySlug, setCommunitySlug] = useState("ai-caramba")
  const [showAction, setShowAction] = useState(false)
  const [roleExtended, setRoleExtended] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [communityJoin, setCommunityJoin] = useState(false)
  const [loginMode, setLoginMode] = useState(true)
  const [explainToJoin, setExplainToJoin] = useState(false)
  const [modalOpen, setModalOpen] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState('');
  const [modalContent, setModalContent] = useState({
    title: '',
    message: '',
    button: '',
    action: ''
  });

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const hadleHeaderAction = () => {
    const data = {
      community: community?.slug,
    }
    joinCommunity(data)
  }

  function getCommunityInfo(firstLoad) {
    getCommunityPublic(slug).then(r => {
      setCommunity(r.group)
      setFounders(r.founders)
      setMembers(r.members)
      setVenues(r.venues)
      setPastEvents(r.past_events)
      setUpcomingEvents(r.upcoming_events)
      setGallery(r.gallery)
    })
      .catch(error => {
        navigate(routes.errorCommunity)
      })
  }
  function setSelectedParticipant(participant) {
    setMember(participant)
  }

  useEffect(() => {
    getCommunityInfo(true)

  }, [])

  const askToJoin = () => {
    setCommunityJoin(true)
    setExplainToJoin(true)
  }

  const avatar = community?.image ? `${API_BASE_URL}${community?.image}` : noAvatar
  const background = community?.background ? `${API_BASE_URL}${community?.background}` : coworking

  const handleSignupSuccess = () => {
    setModalContent({
      title: 'Signup Successful!',
      message: 'We have sent you an email to verify your account. Please check your inbox and click the link to verify your account.',
      button: 'OK',
      action: 'navigate'
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoginMode(true)
  };

  return (
    <Container>
      <CommunityHeader title={community?.name} subtitle={community?.single_address} avatar={avatar} background={background} actionTitle='Join Community' actionMethod={hadleHeaderAction} showAction={showAction} />
      <Grid container spacing={2}>
        {/* Left Menu */}
        <Grid item xs={12} md={2}>
          <SideMenu open={drawerOpen} onClose={handleDrawerToggle} changeTab={handleChange} avatar={avatar} title={community?.name} subtitle={community?.single_address} role={'public'} />
        </Grid>
        <Grid item xs={12} md={10}>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            {value === 0 && <AboutUs community={community} />}
            {value === 1 && <Members members={members} member={member} setMember={setMember} setSelectedParticipant={setSelectedParticipant} tags={[]} />}
            {value === 2 && <Activity community={community} upcomingEvents={upcomingEvents} pastEvents={pastEvents} setCommunityJoin={askToJoin} />}
          </Box>
        </Grid>
      </Grid>
      <Fab
        color="secondary"
        variant="extended"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 32, right: 16 }}
        onClick={() => askToJoin(true)}
      >
        <Typography>Join Community </Typography>
      </Fab>
      <Modal
        open={communityJoin}
        onClose={() => setCommunityJoin(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            {explainToJoin &&
              <Typography
                variant="secondary"
                sx={{
                  fontSize: '1.3rem',
                  fontWeight: 700,
                  lineHeight: '1.5',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  overflowWrap: 'break-word', // Ensure long words or links break appropriately
                }}
              >
                You need to login or sign up to join this community
              </Typography>}
            {loginMode && <ModalLogin showAction={(mode) => setLoginMode(mode)} setLogin={setCommunityJoin} postAction={{ action: 'joinCommunity', slug: community?.slug }} />}
            {!loginMode && <ModalSignup showAction={(mode) => setLoginMode(mode)} onSuccess={handleSignupSuccess} setLogin={setCommunityJoin} postAction={{ action: 'joinCommunity', slug: community?.slug }} />}
          </Box>
        </ModalContent>
      </Modal>
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <Typography variant="h6">{modalContent.title}</Typography>
          <Typography sx={{ mt: 2 }}>{modalContent.message}</Typography>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Button onClick={handleCloseModal} variant="contained" color="secondary">
              {modalContent.button}
            </Button>
          </Grid>
        </ModalContent>
      </Modal>
    </Container>
  )
}

CommunitySinglePublic.propTypes = {}

export default CommunitySinglePublic
