import styled from 'styled-components'

export const Container = styled.div`
    background-color: #fff;
    margin: 5px;
    padding: 5px;
`

export const ForgotButton = styled.a`
    position: fixed;
    bottom: 40px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5%;
    width: 160px;
    height: 80px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
`

export const ModalContent = styled.div`
  background-color: #fff;
  max-height: 70vh;
  overflow-y: auto;  // Enable vertical scrolling when content;
  width: 30%; // Full screen on mobile devices
  margin: 0 auto; /* Center horizontally */
  justify-content: center; /* Center horizontally */
  margin-top: 10vh;
  padding: 20px 20px 30px;
  margin-bottom: 15vh;
  
  @media (max-width: 768px) {
      width: 90%; // Full screen on mobile devices
      height: 80vh;
      margin: 10vh auto; /* Center horizontally */
  }
`