import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { padding, styled } from '@mui/system';
import { Grid } from '@mui/material'
import { Card, CardMedia, Typography, Box, Chip, CardContent, CardActions, Avatar, Button } from '@mui/material';
import { API_BASE_URL } from '../../../../config/api'
import DoneIcon from '@mui/icons-material/Done';
import DeviceUnknown from '@mui/icons-material/DeviceUnknown';
import { InnerChip, AvatarContainer } from './styled';
import noAvatar from '../../../../assets/images/no-avatar.png'

const MemberListItem = ({ member, setSelectedParticipant, role }) => {

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  return (
    <div onClick={() => setSelectedParticipant(member)} style={{ cursor: 'pointer', position: 'relative' }}>
      <Card style={{ position: 'relative' }}>
        <AvatarContainer>
          <CardMedia
            component="img"
            src={member?.user?.avatar ? `${API_BASE_URL}${member?.user?.avatar}` : noAvatar}
            alt="Participant Image"
            onError={handleImageRenderError}
            sx={{ borderRadius: '10px' }}
          />
          {member.status === "PP" && role === 'admin' &&(
            <Box
              sx={{
                position: 'absolute',
                top: 8,  // Adjust to position at the top-right corner
                right: 8,
                width: 10,  // Size of the red ball
                height: 10,
                backgroundColor: 'red',
                borderRadius: '50%',
                border: '2px solid white' // Optional border for better visibility
              }}
            />
          )}
        </AvatarContainer>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={9}>
            <Typography sx={{ marginTop: '4px', paddingLeft: '6px', fontSize: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {member?.user.first_name || '-'}
            </Typography>
            <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '14px', marginBottom: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {member?.user?.alias || '-'}
            </Typography>
          </Grid>
          <Grid item xs={3}>

          </Grid>
        </Grid>

      </Card>
    </div>
  );
};

MemberListItem.propTypes = {
  member: PropTypes.object,
  setSelectedParticipant: PropTypes.func,
  role: PropTypes.string
}

export default MemberListItem
