import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { padding, styled } from '@mui/system';
import { Grid } from '@mui/material'
import { Card, CardMedia, Typography, Box, Chip, CardContent, CardActions, Avatar, Button } from '@mui/material';
import { API_BASE_URL } from '../../../config/api'
import DoneIcon from '@mui/icons-material/Done';
import DeviceUnknown from '@mui/icons-material/DeviceUnknown';
import { InnerChip, AvatarContainer } from './styled';
import noAvatar from '../../../assets/images/no-avatar.png'

const ParticipantListItem = ({ participant, setSelectedParticipant, anonymous }) => {

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  const avatar  = anonymous ? noAvatar : participant?.user?.avatar ? `${API_BASE_URL}${participant?.user?.avatar}` : noAvatar
  return (
    <div onClick={() => {if (!anonymous) {setSelectedParticipant(participant)} }} style={{ cursor: 'pointer', position: 'relative' }}>
      <Card style={{ position: 'relative' }}>
        <AvatarContainer>
          {/* {participant?.status === 'AC' && <InnerChip label={participant?.actions ? participant?.actions?.length : 0} size="small" variant="outlined" color="success" icon={participant?.status === 'AC' ? <DoneIcon /> : <DeviceUnknown />} />} */}
          {participant?.status === 'AC' && <InnerChip size="small" variant="outlined" color="success" icon={participant?.status === 'AC' ? <DoneIcon /> : <DeviceUnknown />} />}
          <CardMedia
            component="img"
            src={avatar}
            alt="Participant Image"
            onError={handleImageRenderError}
            sx={{ borderRadius: '10px' }}
          />
        </AvatarContainer>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={9}>
            <Typography sx={{ marginTop: '4px', paddingLeft: '6px', fontSize: '14px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {participant?.user.first_name}
            </Typography>
            <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '12px', marginBottom: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {participant?.user?.alias || '-'}
            </Typography>
          </Grid>
          <Grid item xs={3}>

          </Grid>
        </Grid>

      </Card>
    </div>
  );
};

ParticipantListItem.propTypes = {
  participant: PropTypes.object,
  setSelectedParticipant: PropTypes.func,
  anonymous: PropTypes.bool
}

export default ParticipantListItem
