// src/hooks/useAuth.js
import { configureToken, getUserToken, signUp, getProfile, resetPassword } from 'api';
import { useProfile } from '../context';
import { useNavigate } from 'react-router-dom'

export const useAuth = () => {
  const { setProfile, resetContext } = useProfile();
  const navigate = useNavigate()

  const doLogin = async (data) => {
    const response = await getUserToken(data);
    const token = response.access;
    localStorage.setItem('token', response.access);
    localStorage.setItem('refreshToken', response.refresh);
    configureToken(token);
  
    // Fetch profile after successful login
    const profile = await getProfile();
    setProfile(profile);
    return response;
  };

  const doSignup = async (data) => {
    let response = await signUp(data);
    return response;
  };

  const doReset = async (data) => {
    const response = await resetPassword(data);
    return response;
  };

  const doLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    resetContext(); // Perform any additional context reset using useLogout
    navigate(`/`);
  };

  return { doLogin, doSignup, doReset, doLogout };
};
