import {Navigate, useLocation} from 'react-router-dom'

export function RequireAuth({ children }) {
  const isAuthenticated = localStorage.getItem('token') != null
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default RequireAuth;