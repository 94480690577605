import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Container, SecondRowContainer, ModalContent, StyledFab
} from './styled'
import routes from 'config/routes'
import CommunityHeader from '../communityHeader/view'
import MemberList from '../memberList/view'
import MemberDetail from '../memberDetail/view'
import VenueList from '../venueList/view'
import EventList from '../eventList/view'
import CommunityForm from '../communityForm/view'
import { getCommunity, getCommunityPublic, validateCommunityMembership, verifyActiveAcount } from 'api'
import {
  Box, Fab, Modal, Button, List, ListItem, ListItemText, Backdrop, CircularProgress, Grid
} from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import SideMenu from '../sideMenu/view'
import { Gallery } from 'react-grid-gallery'
import Typography from '@mui/material/Typography'
import RefreshIcon from '@mui/icons-material/Refresh'
import noAvatar from '../../../../assets/images/no-avatar.png'
import { API_BASE_URL } from 'config/api'
import coworking from 'assets/images/coworking.jpg'
import AboutUs from './aboutUs'
import Members from './members'
import Activity from './activity'
import Partners from './partners'
import Manage from './manage'
import Campaigns from './campaign'
import CommunityJoin from '../comunityJoin'
import MessageBoard from './messages'
import ModalLogin from '../../general/modalLogin/view'
import ModalSignup from 'components/pages/general/modalSignup/view';
import { get } from 'api/utils'


const CommunitySingle = props => {
  const { profile } = props
  const token = localStorage.getItem('token');
  const isAuthenticated = token && profile?.user_name || false; // Checking both token presence and a valid profile

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  // get the doJoin value from the query params
  const location = useLocation();
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [community, setCommunity] = useState(null)
  const [member, setMember] = useState(null)
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [venues, setVenues] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [loading, setLoading] = useState(true);
  const initialTab = searchParams.get('tab') || '0';
  const [value, setValue] = useState(initialTab);
  const [name, setName] = useState("AI Caramaba")
  const [description, setDescription] = useState("Ai caramba is a community of people who love AI")
  const [communitySlug, setCommunitySlug] = useState("ai-caramba")
  const [showAction, setShowAction] = useState(false)
  const [roleExtended, setRoleExtended] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(true)
  const [askCommunityJoin, setAskCommunityJoin] = useState(false)
  const [communityJoin, setCommunityJoin] = useState(false)
  const [alreadyJoined, setAlreadyJoined] = useState(false)
  const [alreadyMember, setAlreadyMember] = useState(false)
  const [explainToJoin, setExplainToJoin] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showPending, setShowPending] = useState(true)
  const [loginMode, setLoginMode] = useState(true)
  const [defaultEmail, setDefaultEmail] = useState(null)
  const [modalContent, setModalContent] = useState({
    title: '',
    message: '',
    button: '',
    action: ''
  });

  const handleChange = useCallback((newValue) => {
    setValue(newValue);
    console.log(newValue)
    setSearchParams({ tab: newValue }); // Update the query parameter
  }, [setSearchParams]);


  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen(prev => !prev)
  }, [])

  useEffect(() => {
    // If the tab changes via back/forward button, update the value state
    const tabFromUrl = searchParams.get('tab');
    if (tabFromUrl !== value) {
      setValue(tabFromUrl || '0');
    }
  }, [location, searchParams, value]);
  

  const handleJoin = useCallback((result) => {
    if (result) {
      getCommunityInfo(false)
      setAskCommunityJoin(false)
      if (!isAuthenticated) {
        setCommunityJoin(true)
      }
    } else {
      getCommunityInfo(false)
      setAskCommunityJoin(false)
      setCommunityJoin(false)
    }


  }, [])


  const handleLogin = useCallback((result) => {
    getCommunityInfo(false)
    if (result) {      
      setCommunityJoin(false)
    }


  }, [])

  

  const getCommunityInfo = useCallback((firstLoad) => {

    let fetchCommunity;

    if (profile && isAuthenticated) {
      fetchCommunity = getCommunity(slug);
    } else {
      fetchCommunity = getCommunityPublic(slug);
    }

    fetchCommunity
      .then(response => {

        if (response) {
          setCommunity(response.group);
          setFounders(response.founders);
          setMembers(response.members);
          setVenues(response.venues);
          setPastEvents(response.past_events);
          setUpcomingEvents(response.upcoming_events);
          setGallery(response.gallery);
          if (response.my_membership) {
            if (response.my_membership?.status) {
              setAlreadyMember(response.my_membership);
              setAlreadyJoined(true);
              setRoleExtended(['AD', 'FO', 'MA'].includes(response.my_membership?.role));
            } else {
              setAlreadyMember(null);
              const doJoin = new URLSearchParams(location.search).get('doJoin');
              if (doJoin) {
                setCommunityJoin(true);
              }
              setRoleExtended(false);
            }
          }

          /*  if (profile && response.members) {
             console.log(response.members)
             const imMember = response.members.find(m => m.user?.user_name === profile?.user_name);
             if (imMember) {
               setAlreadyMember(imMember);
               setAlreadyJoined(true);
               setRoleExtended(['AD', 'FO', 'MA'].includes(imMember.role));
             } else {
               setAlreadyMember(null);
               const doJoin = new URLSearchParams(location.search).get('doJoin');
               if (doJoin) {
                 setCommunityJoin(true);
               }
               setRoleExtended(false);
             }
           } */
             setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        console.error('Error fetching community:', error);
        navigate(routes.errorCommunity);
      });
  }, [slug, profile?.user_name, isAuthenticated, navigate]);


  const askToJoin = () => {
    if (isAuthenticated) {
      setCommunityJoin(true)
    } else {
      setAskCommunityJoin(true)
    }
  }

  const validateAccount = (result) => {
    console.log(member)
    validateCommunityMembership({ group: community?.slug, user_name: member?.user?.user_name, result: result ? 'accept' : 'reject' }).then((r) => {
      console.log(r)
      getCommunityInfo(false)
    }).catch(error => {
      console.error('Error validating account:', error)
    }
    )
    
  }

  useEffect(() => {
    getCommunityInfo(true)
  }, [getCommunityInfo])

  const handleSignupSuccess = (email) => {
    console.log('Receive email is ', email)
    setDefaultEmail(email)
    setModalContent({
      title: 'Signup Successful!',
      message: 'We have sent you an email to verify your account. Please check your inbox and click the link to verify your account.',
      button: 'Yes, I have verified my email address',
      action: 'navigate'
    });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    console.log('Handle close modal ')
    
    setModalOpen(false);
    if (modalContent.action === 'assure') {
      setLoginMode(true)
    }
     else {
      
      const params = {email: defaultEmail}
      console.log(params)
      verifyActiveAcount(params).then((r) => {
        console.log(r)
        setModalContent({
          title: 'Mmm, are you sure?',
          message: 'Hey, can you double check that you have recieved an email with the subject "Validate your account" from Epic Spaces Ltd? Maybe it went straight to spam. You won\'t be able to login until we verify your email address.Sorry! :(',
          button: 'Yes, now I have done it!',
          action: 'assure'
        });
        setModalOpen(true);
      }).catch((error) =>{
        console.log('There is an error and I\'ll handle it')
        console.log(error)
        setLoginMode(true)
      })  
     }
  
  };


  const avatar = community?.image ? `${API_BASE_URL}${community?.image}` : noAvatar
  const background = community?.background ? `${API_BASE_URL}${community?.background}` : coworking

  const images = [
    { src: "https://c5.staticflickr.com/9/8768/28941110956_b05ab588c1_b.jpg", width: 240, height: 320, caption: "8H (gratisography.com)", thumbnailCaption: "8H" },
    { src: "https://c3.staticflickr.com/9/8583/28354353794_9f2d08d8c0_b.jpg", width: 320, height: 190, caption: "286H (gratisography.com)", thumbnailCaption: "286H" },
    { src: "https://c7.staticflickr.com/9/8569/28941134686_d57273d933_b.jpg", width: 320, height: 148, caption: "315H (gratisography.com)", thumbnailCaption: "315H" },
    { src: "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_b.jpg", width: 320, height: 213, caption: "201H (gratisography.com)", thumbnailCaption: "201H" },
    { src: "https://c2.staticflickr.com/9/8239/28897202241_1497bec71a_b.jpg", width: 248, height: 320, caption: "Big Ben (Tom Eversley - isorepublic.com)", thumbnailCaption: "Big Ben" },
    { src: "https://c7.staticflickr.com/9/8785/28687743710_3580fcb5f0_b.jpg", width: 320, height: 113, caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)", thumbnailCaption: <span style={{ color: "darkred" }}>Red Zone - <i>Paris</i></span> },
    { src: "https://c6.staticflickr.com/9/8520/28357073053_cafcb3da6f_b.jpg", width: 313, height: 320, caption: "Wood Glass (Tom Eversley - isorepublic.com)", thumbnailCaption: "Wood Glass" },
    { src: "https://c8.staticflickr.com/9/8104/28973555735_ae7c208970_b.jpg", width: 320, height: 213, caption: "Flower Interior Macro (Tom Eversley - isorepublic.com)", thumbnailCaption: "Flower Interior Macro" },
  ]

  return (
    <Container>
      <CommunityHeader title={community?.name} subtitle={community?.single_address} avatar={avatar} background={background} actionTitle='Join Community' showAction={showAction} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <SideMenu open={drawerOpen} onClose={handleDrawerToggle} changeTab={handleChange} avatar={avatar} title={community?.name} subtitle={community?.single_address} role={roleExtended ? 'admin' : 'participant'} isAuthenticated={isAuthenticated} value={value}/>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            {value === '0' && !loading && <AboutUs community={community} />}
            {value === '1' && !loading && <Members community={community} members={members} member={member} setMember={setMember} setSelectedParticipant={setMember} tags={[]} role={roleExtended ? 'admin' : 'participant'} validateAccount={(result) => validateAccount(result)}/>}
            {value === '2' && !loading && <Activity community={community} upcomingEvents={upcomingEvents} pastEvents={pastEvents} setCommunityJoin={askToJoin} venues={venues} />}
            {value === '3' && !loading && <MessageBoard slug={slug} />}
            {value === '4' && !loading && <Partners venues={venues} slug={slug} />}
            {value === '5' && !loading && <Campaigns slug={slug} />}
            {value === '6' && !loading && <Manage community={community} />}
            <TabContext value={value}>
              <SecondRowContainer>
                <div>
                  <TabPanel value="7" index={7}>
                    <Gallery images={images} />
                  </TabPanel>
                </div>
              </SecondRowContainer>
            </TabContext>
          </Box>
        </Grid>
      </Grid>

      {!alreadyJoined && <Fab color="primary" variant="extended" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => setAskCommunityJoin(true)}>
        <Typography>Join Community</Typography>
      </Fab>}
      <Modal open={askCommunityJoin} onClose={() => setAskCommunityJoin(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <ModalContent>
          <CommunityJoin setJoin={(result) => handleJoin(result)} postAction={{ action: 'joinCommunity', slug: community?.slug }} community={community} isAuthenticated={isAuthenticated} />
        </ModalContent>
      </Modal>

      <Modal
        open={communityJoin}
        onClose={() => setCommunityJoin(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            {explainToJoin &&
              <Typography
                variant="secondary"
                sx={{
                  fontSize: '1.3rem',
                  fontWeight: 700,
                  lineHeight: '1.5',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  overflowWrap: 'break-word', // Ensure long words or links break appropriately
                }}
              >
                You need to login or sign up to join this community
              </Typography>}
            {loginMode && <ModalLogin showAction={(mode) => setLoginMode(mode)} setLogin={(result) => handleLogin(result)} postAction={{ action: 'joinCommunity', slug: community?.slug }} />}
            {!loginMode && <ModalSignup showAction={(mode) => setLoginMode(mode)} onSuccess={handleSignupSuccess} setLogin={setCommunityJoin} postAction={{ action: 'joinCommunity', slug: community?.slug }} />}
          </Box>
        </ModalContent>
      </Modal>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <Typography variant="h6">{modalContent.title}</Typography>
          <Typography sx={{ mt: 2 }}>{modalContent.message}</Typography>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Button onClick={handleCloseModal} variant="contained" color="secondary">
              {modalContent.button}
            </Button>
          </Grid>
        </ModalContent>
      </Modal>

      <Modal open={alreadyMember?.status === 'PP' && showPending} onClose={() => setShowPending(false)}>
        <ModalContent>
          <Typography variant="h6">Almost there!</Typography>
          <Typography sx={{ mt: 2 }}>
            Your membership is still pending validation. In the meantime, you can browse the community with limited functionality. Enjoy!
          </Typography>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Button onClick={() => setShowPending(false)} variant="contained" color="secondary">
              OK
            </Button>
          </Grid>
        </ModalContent>
      </Modal>

      <Backdrop
        sx={{ color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}

CommunitySingle.propTypes = {}

export default CommunitySingle
