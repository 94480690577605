import React, { createContext, useState, useContext } from 'react';

const Context = createContext({
  profile: null,
  setProfile: () => null,
  spaces: 0,
  setSpaces: () => null,
  bookingType: '',
  setBookingType: () => null,
  start: null,
  setStart: () => null,
  end: null,
  setEnd: () => null,
  resetContext: () => null,
});

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [spaces, setSpaces] = useState(1);
  const [bookingType, setBookingType] = useState('HD');
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const resetContext = () => {
    setProfile(null);
    setSpaces(1);
    setBookingType('HD');
    setStart(null);
    setEnd(null);
    // Reset other states if you have any
  };

  return (
    <Context.Provider value={{ profile, setProfile, spaces, setSpaces, bookingType, setBookingType, start, setStart, end, setEnd, resetContext }}>
      {children}
    </Context.Provider>
  );
};

export const useProfile = () => {
  return useContext(Context);
};

export default Context;
