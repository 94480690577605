import styled from 'styled-components'
import Drawer from '../../../components/organisms/drawer'

export const Container = styled.div`
  min-height: 100vh;
`

export const Content = styled.div`
  flex-grow: 1;
  padding: 0;
  min-height: 100vh;
`


export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  height: 50px;
`
