import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, FormContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventHeader from '../eventHeader/view'
import InternalHeader from '../../general/internalHeader/view'
import EventListItem from '../eventListItem/view'
import { getEvent, getEvents } from 'api'
import { Grid, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import coworking from 'assets/images/coworking.jpg'
import noAvatar from '../../../../assets/images/no-avatar.png'
import EventForm from '../eventForm/view';

const EventAdmin = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [events, setEvents] = useState([])
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");

  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getEventInfo(firstLoad) {
    getEvents(slug).then(r => {
      setEvents(r.events)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function navigateEvent(event) {
    navigate(`/events/${event?.slug}`);
    //window.location.href = '/login'
  }

  function headerActionMethod(firstLoad) {
  }

  useEffect(() => {
    getEventInfo(true)

  }, [])





  const avatar = noAvatar
  return (
    <Container>
      <InternalHeader title={'New event'} subtitle={''} avatar={avatar} background={coworking} actionTitle='Save event' actionMethod={headerActionMethod} />
      <FormContainer>
        <EventForm />
      </FormContainer>
    </Container>
  )
}

EventAdmin.propTypes = {}

export default EventAdmin
