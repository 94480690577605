import { styled } from '@mui/system';

import { Paper, Typography, Avatar, Tabs, Button } from '@mui/material';

export const Container = styled('div')()

/* export const HeroContainer = styled(Paper)(({ background }) => ({
    height: '70vh',
    background: `url("${background}") center/cover`, 
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    '@media (max-width: 600px)': {
        marginBottom: '60px'
      }, 
  }));

 */  

export const HeroContainer = styled(Paper)(({ theme }) => ({
    minHeight: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.title.main,
    textAlign: 'center',
    padding: theme.spacing(2),
    position: 'relative',
  }));

export const WhiteBackground = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }));

  export const HeroBoxTitle = styled('div')({
    paddingBottom: '10vh',
    paddingLeft: '3vw',
    textAlign: 'left',
  });

export const HeroTitle = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '4.5rem',
    color: theme.palette.title.main,

  }));

  export const HeroSubtitle = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '2rem',
    color: theme.palette.title.main,
  }));


export const HeroImage = styled('img')({
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px', // optional: add some border radius to the image
  });

export const HeroContent = styled('div')({
    // Add styles for community list items
    padding: '20px',
    maxWidth: '1024px',
    margin: 'auto', // Center horizontally and vertically
    position: 'absolute',
    top: '70%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF'
  });


  

  export const SearchContainer = styled('div')({
    marginBottom: '20px',
  });
  
  export const ThreeBoxesContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
  });


  


