import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import HeaderToolbar from '../../organisms/headerToolbar'
import { CircularProgress, Grid } from '@mui/material'
import ParticipantListItem from '../participantListItem/view'

const ParticipantList = ({ participants, setSelectedParticipant, anonymous }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()

  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 8, md: 12 }}>
        {participants.map((item, index) => (
          <Grid item xs={2} sm={2} md={2} lg={1} key={index}>
            <ParticipantListItem participant={item} setSelectedParticipant={setSelectedParticipant} anonymous={anonymous}/>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

ParticipantList.propTypes = {
  participants: PropTypes.array,
  setSelectedParticipant: PropTypes.func,
  anonymous: PropTypes.bool
}

export default ParticipantList
