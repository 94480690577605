import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  HeroContainer,
  SearchContainer,
  ThreeBoxesContainer,
  HeroContent,
  HeroBoxTitle,
  HeroTitle,
  HeroSubtitle,
  HeroImage,
  WhiteBackground,
} from './styled'

import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { getEventTicket, getPublicEventTicket } from 'api'
import { useNavigate } from 'react-router'
import { TextField, Button, Box, Grid } from '@mui/material'

import coworking from 'assets/images/coworking.jpg'
import home_hero from 'assets/images/home_hero.jpg'

const HomeHeader = ({ title, subtitle, avatar, background }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [theParticipants, setParticipants] = useState([])
  const [myTicket, setMyTicket] = useState(null)
  const [theEvent, setEvent] = useState([])
  const [userModal, setUserModal] = useState(false)
  const [joinModal, setJoinModal] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [ticketInfo, setTicketInfo] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))


  if (background == null) {
    background = home_hero
  }
  return (
    <HeroContainer elevation={0}>
      <Container>
        <Grid container spacing={2} alignItems="end">
          {/* Left side with title */}
          <Grid item xs={12} sm={6}>
            <HeroBoxTitle>
              <HeroTitle variant="h2">
                Discover, Connect, Thrive
              </HeroTitle>
              <HeroSubtitle variant="h4">
                Your Epic Community Hub
              </HeroSubtitle>
            </HeroBoxTitle>
          </Grid>

          {/* Right side with image and white background */}
          <Grid item xs={12} sm={6}>
            <WhiteBackground>
              <HeroImage src={background} alt="Your Alt Text" />
            </WhiteBackground>
          </Grid>
        </Grid>
      </Container>
    </HeroContainer>

  )
}

HomeHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  avatar: PropTypes.string,
  background: PropTypes.string
}

export default HomeHeader
