import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import CommunityForm from '../communityForm/view'

const Manage = props => {
    const { community } = props
    return (
        <div>
            <CommunityForm community={community} />
        </div>

    )
}

Manage.propTypes = {}

export default Manage
