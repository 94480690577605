import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  padding: 8px;
`;

export const ResponsiveFormControl = styled.div`
  width: 100%; /* Full width on mobile by default */

  @media (min-width: 600px) {
    width: 30%; /* 30% width on tablets and above */
  }
`;

export const ResponsiveTextField = styled.div`
  width: 100%; /* Full width on mobile by default */

  @media (min-width: 600px) {
    width: 20%; /* 20% width on tablets and above */
  }
`;

