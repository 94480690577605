import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled('div')`
min-height: 100vh;
`

export const SecondRowContainer = styled(Box)({
    maxWidth: '80vw', // Default width for desktop

    // Center the container on desktop
    margin: '0 auto',
  
    // Media query for screens with a maximum width of 600px (adjust as needed)
    '@media (max-width: 900px)': {
      maxWidth: '100%', // Set width to 100% for smaller screens
    },
  });


export const ImageContainer = styled('div')`
display: flex;
flexDirection: column;
alignItems: center;
justifyContent: center;
width: 50%;
margin: 0 auto;
`

export const StyledEditorContainer = styled('div')`
  height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.87);
`;