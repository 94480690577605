import { styled } from '@mui/system';
import { Fab } from '@mui/material'


export const Container = styled('div')``


export const ForgotButton = styled('a')`
    position: fixed;
    bottom: 60px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */    
    font-size: 18px;
    cursor: pointer;
    padding: 10px;
`

export const ModalContent = styled('div')`
    background-color: #fff;
    max-height: 80vh;
    overflow-y: auto;  // Enable vertical scrolling when content;
    width: 30%; // Full screen on mobile devices
    margin: 0 auto; /* Center horizontally */
    justify-content: center; /* Center horizontally */
    margin-top: 10vh;
    
    @media (max-width: 768px) {
        width: 90%; // Full screen on mobile devices
        height: 90vh;
        margin: 10vh auto; /* Center horizontally */
    }
`

export const StyledFab = styled(Fab)`
  background-color: #0F617D;
  color: #FFF;
`;

