import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container, HeroBoxMain, AvatarWrapper, HeroAvatarContainer, HeroMainData, HeroMainText, InfoTextMedium, InfoTextSmall, CustomBadge, HeroPriceContainer, ModalContent } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Select, MenuItem, Grid, Typography, Link, Chip, IconButton, Modal, Button, CircularProgress, Snackbar, Alert } from '@mui/material'
import EventListItem from '../eventListItem/view'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import Badge from '@mui/material/Badge';
import { getCommunityActivity, joinBooking, updateCommunity } from 'api'
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';
import { useNavigate } from 'react-router'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'
import { format, isValid, set } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import { loadStripe } from '@stripe/stripe-js';
import { useProfile } from 'context'
import { fi } from 'date-fns/locale'
import { useSnackbar } from 'notistack'
import { palette } from '@mui/system'
import ActivityListItem from '../activityListItem/view'
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale to start the week on Monday
dayjs.locale('en-gb'); // Set the locale for dayjs


const EventList = ({ community, setCommunityJoin, updateList = false, setUpdateList }) => {


  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [events, setEvents] = useState([])
  const [selectedOption, setSelectedOption] = useState('upcoming')
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [highlightedDays, setHighlightedDays] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [activity, setActivity] = useState([]);
  const [pastActivity, setPastActivity] = useState([]);
  const [quickCheckout, setQuickCheckout] = useState(false)
  const [sessionId, setSessionId] = useState(null)
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [filters, setFilters] = useState({ date: null, type: 'upcoming' });
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [sentences, setSentences] = useState({
    firstSentence: 'No upcoming events',
    lastSentence: 'Check back later for more events'
  })
  const [bookingResults, setBookingResults] = useState(0)

  const { profile } = useProfile();

  function setOption(option) {
    if (option === 'upcoming') {
      setEvents(activity)
      setSentences({ firstSentence: 'No upcoming events', lastSentence: 'Check back later for more events' })
    } else {
      setEvents(pastActivity)
      setSentences({ firstSentence: 'No past events', lastSentence: 'Check upcoming events or modify your filters' })
    }
  }

  function navigateItem(type, communitySlug, slug) {

    if (profile === null) {
      setCommunityJoin(true)
    } else {
      if (type === 'group_booking') {
        navigate(`/community/${communitySlug}/booking/${slug}`);
      }

      if (type === 'event') {
        navigate(`/events/${slug}`);
      }
      if (type === 'venue') {
        navigate(`/venue/${slug}`);
      }
    }

  }


  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setBookingResults(0)
  };

  const clearFilters = () => {
    setFilters({ date: null, type: 'upcoming' });
    setSelectedDate(null);
    setSelectedOption('upcoming');
  };

  function updateCommunityData() {
    setIsLoading(true);
    getCommunityActivity(community?.slug, filters).then(r => {
      setActivity(r.upcoming_activities)
      setPastActivity(r.past_activities)

      if (selectedOption === 'upcoming') {
        setEvents(r.upcoming_activities)
      } else {
        setEvents(r.past_activities)
      }
      const highlighted = r.total_dates.map(dateObj => dateObj.date);
      setHighlightedDays(highlighted);
      setIsLoading(false);
      setUpdateList(false)
    })
  }

  useEffect(() => {
    updateCommunityData()

  }, [filters])

  useEffect(() => {
    if (updateList) updateCommunityData()

  }, [updateList])

  useEffect(() => {
    setOption(selectedOption)
  }, [selectedOption])


  const handleMonthChange = (date) => {
    //if (requestAbortController.current) {
    // make sure that you are aborting useless requests
    // because it is possible to switch between months pretty quickly
    //requestAbortController.current.abort();
    //}

    setIsLoading(true);
    //setHighlightedDays([1,22,4,5,15]);
    setIsLoading(false);
    //fetchHighlightedDays(date);
  };

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    const dateStr = newValue?.toISOString().split('T')[0]; // Convert date to 'YYYY-MM-DD' format

    setFilters({ ...filters, date: dateStr });
    setIsLoading(true);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setFilters({ ...filters, type: e.target.value });
  };

  // Function to format the date and time
  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    return `${formattedDate}`;
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    return `${formattedTime}`;
  }

  function ServerDay(props) {
    const { day, outsideCurrentMonth, highlightedDays, ...other } = props;
    const formattedDay = dayjs(day).format('YYYY-MM-DD');
    const isSelected = formattedDay !== '' && highlightedDays.includes(formattedDay);

    // Render PickersDay directly if not selected, otherwise wrap in Badge
    return isSelected ? (
      <CustomBadge
        key={day.toString()}
        overlap="circular"
        badgeContent=""
        color="primary"
        variant='dot'
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </CustomBadge>
    ) : (
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    );
  }

  const doBooking = () => {
    const postData = {
      "bookings": [
        {
          code: selectedBooking?.code,
          number_spaces: 1,
          price: selectedBooking?.price,
        }
      ]
    }
    joinBooking(postData).then(r => {
      setSessionId(r.sessionId)
      setQuickCheckout(false)
    }).catch(error => {
      enqueueSnackbar(t('booking.message.error'), {
        variant: 'error',
      })
      setQuickCheckout(false)
    })
  };


  const selectBooking = (booking) => {

    if (profile === null) {
      setCommunityJoin(true)
    } else {
      setSelectedBooking(booking)
      setQuickCheckout(true)
    }

  }

  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

  useEffect(() => {
    if (sessionId != null) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (error) {
          console.error(error.message);
          // Handle the error, e.g., display a message to the user
        }
      }
      redirectToCheckout()
    }

  }, [sessionId])

  return (
    <Container>
      <Grid container>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'start' }}>
            <Select
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <MenuItem value="upcoming">Upcoming Activity</MenuItem>
              <MenuItem value="past">Past Activity</MenuItem>
            </Select>
            <Button onClick={clearFilters} variant="contained" color="secondary" style={{ marginLeft: '10px' }}>
              Clear Filters
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} md={9} sx={{ paddingTop: 5 }}>
          {isLoading &&
            <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
              <CircularProgress size={50} />
            </Grid>}
          {!isLoading && events.length === 0 && (
            <NoResultsPlaceholder firstSentence={sentences?.firstSentence} lastSentence={sentences?.lastSentence} />
          )}

          {!isLoading && events?.map((item, index) => (            
            <ActivityListItem event={item} selectedOption={selectedOption} navigateItem={navigateItem} selectBooking={selectBooking}/>            
          ))}
        </Grid>
        <Grid item xs={12} md={3}>
          <LocalizationProvider 
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"  // Ensure the locale is set here as well
          >
            <DateCalendar
              value={selectedDate}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  highlightedDays,
                },
              }}
              onChange={handleDateChange}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Modal open={quickCheckout} onClose={() => setQuickCheckout(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <ModalContent>
          <Typography variant="h6" component="h2" sx={{ marginTop: '30px' }}>
            Checkout
          </Typography>
          <Typography variant="body2" sx={{ marginTop: '10px' }}>
            You are about to book a work space at {selectedBooking?.venue} on {formatDate(selectedBooking?.activiyStart)} for £{selectedBooking?.total_price}
          </Typography>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <Button size='small' variant='contained' color='secondary' onClick={() => doBooking()} sx={{ alignItems: 'center' }}>
              Confirm booking
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </Container>
  )
}

EventList.propTypes = {
  community: PropTypes.object,
  setCommunityJoin: PropTypes.func
}

export default EventList
