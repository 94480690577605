import React from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { Button, Typography } from '@mui/material';

const LocationMap = ({ latitude, longitude, name, googleMapsUrl }) => {
  const containerStyle = {
    width: '100%',
    height: '200px',
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  const options = {
    disableDefaultUI: true,
    zoomControl: false, // Enable zoom control, but with a smaller size
    gestureHandling: 'none', // Prevents users from moving the map
  };

  const googleMapsLink = googleMapsUrl
    ? googleMapsUrl
    : `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  return (
    <>
      <LoadScript googleMapsApiKey="AIzaSyDTijFIM_ZWjk3TOm3Ia2a4PLZhUZLTfKo">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          options={options}
        >
          <Marker position={center}>
            <InfoWindow position={center}>
              <div style={{ fontSize: '14px', lineHeight: '1.5' }}>
                <strong>{name}</strong>
              </div>
            </InfoWindow>
          </Marker>
        </GoogleMap>
      </LoadScript>
      <Button
        variant="outlined"
        href={googleMapsLink}
        target="_blank"
        style={{ marginTop: '10px' }}
      >
        Open in Google Maps
      </Button>
    </>
  );
};

export default LocationMap;
