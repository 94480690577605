import React from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom'

import View from './view'

const ResetForm = ({ ...props }) => {
  const onSuccess = props.onSuccess
  const setShowReset = props.setShowReset
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { doReset } = useAuth();

  const mutateReset = useMutation(data => doReset(data), {
    onSuccess: result => {
      if (onSuccess) {
        onSuccess(result)
      }
      //navigate('/communities')
    },
    onError: () => {
      enqueueSnackbar(t('reset.error'), {
        variant: 'error',
      })
    },
  })

  const onSubmit = data => {
    mutateReset.mutate(data)
  }

  return <View {...props} onSubmit={onSubmit} onSuccess={onSuccess} setShowReset={setShowReset} />
}

export default ResetForm
