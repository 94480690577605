import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styled'

const FullScreen = ({ children }) => <Container>{children}</Container>

FullScreen.defaultProps = {
  children: <></>,
}

FullScreen.propTypes = {
  children: PropTypes.node,
}

export default FullScreen
