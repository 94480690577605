import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Modal, Typography, Button } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDashboard, searchCommunities } from 'api';
import InternalHeader from '../../general/internalHeader/view';
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view';
import FloatingBookingSelector from 'components/organisms/floatingBookingSelector/view';
import CommunitySlider from 'components/organisms/communitySlider/view';
import BookingSlider from 'components/organisms/bookingSlider/view';
import VenueSlider from 'components/organisms/venueSlider/view';
import noAvatar from 'assets/images/no-avatar.png';
import coworking from 'assets/images/coworking.jpg';
import { API_BASE_URL } from 'config/api';
import { Container, ModalContent } from './styled';
import { useProfile } from 'context';

const DashboardHome = () => {
  const { t } = useTranslation();
  const { profile } = useProfile();
  console.log(profile)
  const { slug } = useParams();
  const [communities, setCommunities] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(profile?.onboarding < 50);
  const [dashboardInfo, setDashboardInfo] = useState({
    bookings: [],
    events: [],
    communities: [],
  });
  const [venues, setVenues] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getDashboardInfo(true);
  }, []);

  const getDashboardInfo = (firstLoad) => {
    getDashboard()
      .then(r => {
        console.log('The response is -> ', r);
        setDashboardInfo({
          bookings: r.upcoming_info?.bookings || [],
          events: r.upcoming_info?.events || [],
          communities: r.communities || [],
        });
        setVenues(r.venues || []);
      })
      .catch(error => console.log('The error is -> ', error));
  };

  const headerActionMethod = (firstLoad) => {
    const params = { search: 'python' };
    searchCommunities(params)
      .then(r => {
        setCommunities(r.groups);
      })
      .catch(error => console.log('The error is -> ', error));
  };

  const navigateCommunity = (slug) => {
    navigate(`/community/${slug}`);
  };

  const navigateBooking = () => {
    navigate(`/bookings`);
  };

  const navigateVenue = (slug) => {
    navigate(`/venue/${slug}`);
  };

  const navigateProfile = () => {
    navigate(`/profile`);
  };

  const avatar = profile?.avatar ? `${API_BASE_URL}${profile?.avatar}` : noAvatar;
  const background = profile?.background ? `${API_BASE_URL}${profile?.background}` : coworking;

  return (
    <Container>
      <InternalHeader
        title={`Hello ${profile?.first_name}`}
        subtitle={'Welcome to your dashboard'}
        avatar={avatar}
        background={background}
        actionTitle=""
        actionMethod={headerActionMethod}
      />

      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: 5 }}>
          <BookingSlider
            navigateBooking={(item) => navigateBooking(item)}
            bookings={dashboardInfo.bookings}
          />
        </Grid>
      </Grid>
      <Grid>
        <CommunitySlider navigateCommunity={(item) => navigateCommunity(item)} existingCommunities={dashboardInfo.communities} showMore={true}/>
      </Grid>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: 5 }}>
          <VenueSlider navigateVenue={(item) => navigateVenue(item)} venues={venues} showMore={true}/>
        </Grid>
      </Grid>



      {profile?.imAdmin && <FloatingBookingSelector />}
      <Modal open={showOnboarding} onClose={() => setShowOnboarding(false)}>
        <ModalContent>
          <Typography variant="h5" align="center" gutterBottom>
            Complete Your Profile
          </Typography>
          <Typography variant="body1" align="center" sx={{ mt: 2 }}>
            Hey, it’s great having you here! Can you take a few minutes to add more details to your profile?
            This will help communities get to know you better and make the most of your experience.
          </Typography>
          <Grid container justifyContent="center" spacing={2} sx={{ mt: 3 }}>
            <Grid item>
              <Button onClick={() => navigateProfile()} variant="contained" color="secondary">
                Complete Profile
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => setShowOnboarding(false)} variant="outlined" color="warning">
                Maybe Later
              </Button>
            </Grid>
          </Grid>
        </ModalContent>
      </Modal>

    </Container>
  );
};

export default DashboardHome;
