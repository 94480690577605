import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import MemberList from '../memberList/view'
import MemberDetail from '../memberDetail/view'
import { TextField, Autocomplete, Box } from '@mui/material';

const Members = props => {
    const { member, members, setMember, setSelectedParticipant, tags } = props
    const [searchText, setSearchText] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
  
    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);
        //onFilterChange(event.target.value, selectedTags);
      };
    
      const handleTagsChange = (event, value) => {
        setSelectedTags(value);
        //onFilterChange(searchText, value);
      };

    return (
        <div>            
            {member === null && <MemberList members={members} setSelectedParticipant={setSelectedParticipant} />}
            {member !== null && <MemberDetail member={member} goBack={() => setMember(null)} />}
        </div>

    )
}

Members.propTypes = {}

export default Members
