import {
  configureToken,
  getUserToken,
  setEvents,
  signUp,
  getProfile,
} from 'api'
import { useProfile } from '../context';

export const DO_LOGIN = async data => {
  const { setProfile } = useProfile();

  const response = await getUserToken(data)
  const token = await response.access
  localStorage.setItem('token', response.access)
  localStorage.setItem('refreshToken', response.refresh)
  configureToken(token)
  
  // Fetch profile after successful login
  const profile = await getProfile();
  setProfile(profile);
  return response
}

export const DO_SIGNUP = async data => {
  const { setProfile } = useProfile();
  const response = await signUp(data)
  const token = await response.access
  localStorage.setItem('token', response.access)
  localStorage.setItem('refreshToken', response.refresh)
  configureToken(token)
  const profile = await getProfile();
  setProfile(profile);
  return response
}

export const SET_EVENT = async data => {
  const response = await setEvents(data)
  return response
}

