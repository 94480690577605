import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardMedia, Typography, Grid, Box } from '@mui/material'
import { API_BASE_URL } from '../../../../config/api'
import DoneIcon from '@mui/icons-material/Done'
import DeviceUnknown from '@mui/icons-material/DeviceUnknown'
import PeopleIcon from '@mui/icons-material/People'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useTranslation } from 'react-i18next'
import noAvatar from '../../../../assets/images/no-avatar.png'

const CommunityListItem = ({ group, selectCommunity }) => {
  const { t } = useTranslation()

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  const getStatusLabel = (status) => {
    switch (status) {
      case 'AC': return 'Active'
      case 'IN': return 'Inactive'
      case 'PE': return 'Pending'
      default: return 'Unknown'
    }
  }

  const getVisibilityLabel = (visibility) => {
    switch (visibility) {
      case 'PU': return 'Public'
      case 'PR': return 'Private'
      default: return 'Unknown'
    }
  }

  const getValorationStars = (valoration) => {
    const rating = parseFloat(valoration)
    const stars = []
    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        stars.push(<StarIcon key={i} sx={{ color: 'gold' }} />)
      } else {
        stars.push(<StarBorderIcon key={i} sx={{ color: rating === 0 ? 'gray' : 'gold' }} />)
      }
    }
    return stars
  }

  const getPrivacyIcons = (dataProtection) => {
    switch (dataProtection) {
      case 'NO': return (
        <>
          <LockIcon sx={{ color: 'gray' }} />
          <LockIcon sx={{ color: 'gray' }} />
          <LockIcon sx={{ color: 'gray' }} />
        </>
      )
      case 'CM': return (
        <>
          <LockIcon sx={{ color: 'gray' }} />
          <LockIcon sx={{ color: 'gray' }} />
          <LockOpenIcon sx={{ color: 'blue' }} />
        </>
      )
      case 'PA': return (
        <>
          <LockIcon sx={{ color: 'gray' }} />
          <LockOpenIcon sx={{ color: 'green' }} />
          <LockOpenIcon sx={{ color: 'green' }} />
        </>
      )
      default: return (
        <>
          <LockIcon sx={{ color: 'red' }} />
          <LockOpenIcon sx={{ color: 'red' }} />
        </>
      )
    }
  }

  return (
    <div
      onClick={() => selectCommunity(group)}
      style={{ cursor: 'pointer', position: 'relative', width: '100%' }}
    >
      <Card style={{ padding: '5px' }}>
        <CardMedia
          component="img"
          src={group?.group?.image ? `${API_BASE_URL}${group?.group?.image}` : noAvatar}
          alt="Community Image"
          onError={handleImageRenderError}
          style={{ objectFit: 'cover', width: '100%' }}
        />
        <Box
          style={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <Typography
            style={{
              marginTop: '4px',
              fontSize: '16px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: 'bold'
            }}
          >
            {group?.group?.name}
          </Typography>
          <Typography
            style={{
              marginTop: '6px',
              fontSize: '12px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              color: 'gray',
            }}
          >
            {group?.group?.description?.slice(0, 100)}{group?.group?.description?.length > 100 ? '...' : ''}
          </Typography>
          <Grid container spacing={1} alignItems="center" style={{ marginTop: '10px' }}>
            <Grid item xs={12} sm={6}>              
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                <div>{getValorationStars(group?.group?.valoration)}</div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                style={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <PeopleIcon style={{ marginRight: '4px' }} />
                {group?.group?.participants}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  )
}

CommunityListItem.propTypes = {
  group: PropTypes.object,
  selectCommunity: PropTypes.func
}

export default CommunityListItem
