import React from 'react'
import NavBar from 'components/molecules/navBar'
import PropTypes from 'prop-types'
import { Container } from './styled'

const Layout = ({ children }) => (
  <Container>
    <NavBar />
    {children}
  </Container>
)

Layout.defaultProps = {
  children: <></>,
}

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
