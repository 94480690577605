import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventHeader from '../eventHeader/view'
import InternalHeader from '../../general/internalHeader/view'
import EventListItem from '../eventListItem/view'
import { getEvent, getEvents } from 'api'
import { Grid, Box, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import coworking from 'assets/images/coworking.jpg'
import noAvatar from '../../../../assets/images/no-avatar.png'

const EventHome = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [events, setEvents] = useState([])
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");

  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getEventInfo(firstLoad) {
    getEvents(slug).then(r => {
      setEvents(r.past)
      setUpcomingEvents(r.upcoming)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function navigateEvent(event) {
    navigate(`/event-update/${event?.slug}`);
    //window.location.href = '/login'
  }

  function headerActionMethod() {
    navigate(`/event-manage`);
  }

  useEffect(() => {
    getEventInfo(true)

  }, [])





  const avatar = noAvatar
  return (
    <Container>
      {/* Global toolbar */}
      {/* Event header */}
      <InternalHeader title={'Your Events'} subtitle={''} avatar={avatar} background={coworking} actionTitle='Create Event' actionMethod={headerActionMethod} />
      <TabContext value={value}>
        <SecondRowContainer>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Upcoming" value="0" />
            <Tab label="Past" value="1" />

          </Tabs>
          <div>
            {/* Tab content sections */}
            <TabPanel value="0" index={0}>
              {upcomingEvents.length > 0 ?
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {upcomingEvents?.map((item, index) => (
                    <Grid item xs={3} sm={3} md={3} lg={2} key={index}>
                      <EventListItem event={item} selectEvent={(item) => navigateEvent(item)} />
                    </Grid>
                  ))}
                </Grid>
                :
                <Grid container spacing={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ flex: 1 }}
                  >
                    <Typography variant="h6" component="div" sx={{ justifyContent: 'center' }}>
                      No upcoming event
                    </Typography>
                  </Box>
                </Grid>
              }
            </TabPanel>
            <TabPanel value="1" index={1}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {events?.map((item, index) => (
                  <Grid item xs={3} sm={3} md={3} lg={2} key={index}>
                    <EventListItem event={item} selectEvent={(item) => navigateEvent(item)} />
                  </Grid>
                ))}
              </Grid>

            </TabPanel>
          </div>
        </SecondRowContainer>
      </TabContext>


      {/* List of events */}
    </Container>
  )
}

EventHome.propTypes = {}

export default EventHome
