import React, { useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import {
  Container,
  CardContainer,
  CardBox,
  CardBoxMain,
  CardAvatarContainer,
  TypoTitle,
  TypoText,
  LinkText,
  CardBoxHeader,
  CardBoxTitle,
  CardBoxIcon,
  EllipsisText
} from './styled'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, Button } from '@mui/material'
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import { API_BASE_URL } from '../../../../config/api'
import { ChevronRight } from '@mui/icons-material'
import { he } from 'date-fns/locale'


const EventUpsell = ({ theEvent, setFeaturedParticipant }) => {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { t } = useTranslation()

  const navigate = useNavigate();
  const tabs = []

  const navigateLink = (link, type) => {
    if (type === 'ME') {
      setFeaturedParticipant(link)
    } else if (link) {
      window.open(link, '_blank'); // Opens the link in a new tab or window
    }
  };



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20%', // Adjust this value to control the visibility of the next card
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode for larger screens
        },
      },
    ],
  };

  return (
    <Container>
      <CardContainer>
        {theEvent?.featured?.map((item, index) => (
          <CardBox key={index} onClick={() => navigateLink(item?.link, item?.type)}>
            <CardBoxHeader>
              <CardBoxTitle>{t(`event.bookingType.${item?.type}`)}</CardBoxTitle>
              <CardBoxIcon>
                <ChevronRight sx={{ fontSize: '16px' }} />
              </CardBoxIcon>
            </CardBoxHeader>
            <CardBoxMain>
              {item.image && (
                <CardAvatarContainer>
                  <img
                    src={`${API_BASE_URL}${item?.image}`}
                    alt="Avatar"
                    style={{ width: '85px', height: '85px', objectFit: 'cover' }}
                  />
                </CardAvatarContainer>
              )}
              <div style={{ flex: 1 }}>
                <TypoTitle>{item?.title}</TypoTitle>
                <EllipsisText>
                  <TypoText>{item?.description}
                  </TypoText>
                </EllipsisText>
              </div>
            </CardBoxMain>
          </CardBox>
        ))}
      </CardContainer>
    </Container >
  )
}

EventUpsell.propTypes = {
  theEvent: PropTypes.object,
  setFeaturedParticipant: PropTypes.func,
}

EventUpsell.defaultProps = {
  theEvent: {},
  setFeaturedParticipant: () => { },
}

export default EventUpsell
