import styled from 'styled-components'
import { CardMedia, Box } from '@mui/material';

export const Container = styled.div``

export const TopMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #FFF;
`

export const Hero = styled.div`

    justify-content: center;
    align-items: center;
    background-color: #d32a24;
    
    padding-left: 10vw;
    height: 60vh;
    overflow: hidden;

    @media (max-width: 768px) {
        padding: 0 10;
        min-height: 10vh;
    }
`

export const HeroBox = styled.div`
    padding-top: 3vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    
    flex-direction: column;

`
export const HeroBoxTitle = styled.div`
    padding-top: 3vh;
    font-size: 96px;
    font-weight: 600;
    line-height: 80px;
    color: #FFF;
    @media (max-width: 768px) {
        font-size: 72px;
        font-weight: 600;
        line-height: 60px;
    }
`
export const HeroBoxSubtitle = styled.div`
    padding-top: 3vh;
    font-size: 24px;
    line-height: 30px;
    color: #FFF;
    @media (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        padding-bottom: 3vh;
    }
`

export const HeroImage = styled(CardMedia)`
  width: 100%;
  height: 100%;
  border-radius: 100px; // Large value for a rounded effect
  margin-left: 100px;
  height: 60vh;
  @media (max-width: 768px) {
    border-radius: 100px; // Large value for a rounded effect
    height: 20vh;
    };
`;

export const HeroImageContainer = styled(Box)`
    position: relative; 
    flex: 1; 
    height: 100%
    
    
`;

export const LeftSection = styled.div``

export const RightSection = styled.div``
