import React, { useEffect, useState } from 'react'
import ResetForm from 'components/organisms/resetPassword'
import bgReset from 'assets/images/reset-password.jpg'
import psl from 'psl'
import { useTranslation } from 'react-i18next'
import { Container, Header, Subtitle, Title, Wrapper } from './styled'

const Reset = () => {
  const [resetInfo, setResetInfo] = useState()
  const { t } = useTranslation()

  function getResetInfo() {
    let parsed = 'test' //psl.parse(window.location.hostname)
    if (psl.parse(window.location.hostname)) {
      parsed = psl.parse(window.location.hostname).subdomain
    }
    //getClientInfo(parsed.subdomain).then(r => setResetInfo(r))
    
  }

  function onSubmit(data) {
    console.log(data)
  }

  useEffect(() => {
    getResetInfo()
  }, [])

  return (
    //<Container style={{ backgroundImage: `url(${bgReset})` }}>
    <Container style={{ backgroundImage: `url(${resetInfo?.picture !== undefined ? resetInfo.picture : bgReset})` }}>
      <Wrapper elevation={0}>
        <Header>
          <Title variant='h4'>{t('reset.title')}</Title>
          <Subtitle>{t('reset.subtitle')}</Subtitle>
        </Header>
        <ResetForm onSubmit={{onSubmit}}/>
      </Wrapper>
    </Container>
  )
}

export default Reset
