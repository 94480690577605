import { colors } from 'config/colors'
import styled from 'styled-components'

export const CircularContainer = styled.div`
  border-radius: 50%;
  background-color: ${colors.gray};
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const TheLetters = styled.h1`
  font-family: 'lato';
  color: white;
  font-size: 50px;
  font-weight: 700;
`
