import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Autocomplete, Grid, Box } from '@mui/material'
import MemberListItem from '../memberListItem/view'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'
import { getGroupMembers, getTagsWithCategory } from 'api'

const MemberList = ({ community, members = [], setSelectedParticipant, role }) => {
  const [memberList, setMemberList] = useState([])
  const [tags, setTags] = useState([])
  const [searchFilter, setSearchFilter] = useState({
    searchQuery: '',
    tags: [],
    community: community?.slug
  })
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')

  const getTagsInfo = () => {
    getTagsWithCategory()
      .then(r => {
        const selectedTagNames = r.tags?.map(tag => tag.name);
        setTags(selectedTagNames)
      })
      .catch(error => {
        console.log('The error is -> ', error)
      })
  }

  const getMemberList = () => {
    console.log('Search Filter:', searchFilter)
    getGroupMembers(searchFilter)
      .then(r => {
        setMemberList(r.members || [])
      })
      .catch(error => console.log('The error is -> ', error))
  }

  // Fetch members list when searchFilter changes
  useEffect(() => {
    getMemberList()
  }, [searchFilter])

  // Update search filter with community on community change
  useEffect(() => {
    if (community) {
      setSearchFilter(prev => ({
        ...prev,
        community: community.slug
      }))
    }
  }, [community])

  // Fetch tags on first load
  useEffect(() => {
    getTagsInfo()
  }, [])

  // Handle the delayed search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchFilter(prev => ({
        ...prev,
        searchQuery: debouncedSearchQuery,
      }))
    }, 1000) // 1 second delay

    return () => clearTimeout(handler)
  }, [debouncedSearchQuery])

  const handleSearchChange = (e) => {
    setDebouncedSearchQuery(e.target.value)
  }

  const handleTagChange = (event, value) => {
    setSearchFilter(prev => ({
      ...prev,
      tags: value
    }));
  };

  return (
    <Box sx={{ padding: '16px' }}>
      {/* Search Field */}
      <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            label="Search by Name, Surname, or Alias"
            variant="outlined"
            value={debouncedSearchQuery}
            onChange={handleSearchChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            id="tags"
            options={tags}
            getOptionLabel={(option) => `${option}`}
            value={searchFilter.tags}
            onChange={handleTagChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Filter by Tags"
                placeholder="Select tags"
              />
            )}
          />
        </Grid>
      </Grid>

      {/* Member List */}
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {memberList?.length === 0 && (
          <Grid item xs={12}>
            <NoResultsPlaceholder firstSentence="No members found" lastSentence="Try something different or wait a few minutes" />
          </Grid>
        )}
        {memberList?.map((item, index) => (
          <Grid item xs={2} sm={2} md={2} lg={1} key={index}>
            <MemberListItem member={item} setSelectedParticipant={setSelectedParticipant} role={role} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

MemberList.propTypes = {
  members: PropTypes.array,
  setSelectedParticipant: PropTypes.func,
  role: PropTypes.string,
}

export default MemberList
