import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const LoadingBodyView = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '300px' }}>
    <CircularProgress color='secondary'/>
  </div>

)

LoadingBodyView.propTypes = {}

LoadingBodyView.defaultProps = {}

export default LoadingBodyView
