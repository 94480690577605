import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import VenueList from '../venueList/view'


const Partners = props => {
    const { venues, slug } = props
    return (
        <div>
            <VenueList venues={venues} slug={slug} />
        </div>

    )
}

Partners.propTypes = {}

export default Partners
