import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Container, ForgotButton } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventTicketLogin from '../eventTicketLogin/view'
import EventTicketSignup from '../eventTicketSignup/view'
import EventTicketJoin from '../eventTicketJoin/view'

const EventOnboarding = ({event}) => {

  const { t } = useTranslation()
  const [showLogin, setShowLogin] = useState(false)
  const isLogged = localStorage.getItem('token') ? true : false;

  const setLogin = (visible) => {
    setShowLogin(visible)
  };

  return (
    <Container>
      {!isLogged && showLogin && <EventTicketLogin setLogin={setLogin} event={event}/>}
      {!isLogged && !showLogin && <EventTicketSignup setLogin={setLogin}  event={event}/>}
      {isLogged && <EventTicketJoin setLogin={setLogin}  event={event}/>}      
    </Container>
  )
}

EventOnboarding.propTypes = {
  event: PropTypes.object,
}
EventOnboarding.defaultProps = {
  event: {}
}

export default EventOnboarding
