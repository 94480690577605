import styled from 'styled-components'

export const Container = styled.div`
    margin: 20px;  
    @media (max-width: 768px) {
        width: 100%; // Full screen on mobile devices
        margin: 10px 0;  

    }
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    width: 60%; // Full screen on mobile devices
    @media (max-width: 768px) {
        width: 100%; // Full screen on mobile devices
        margin: 10px 0;  
    }
`

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`