import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
import MessageList from '../messageList/view'


const MessageBoard = props => {
    const { slug } = props
    return (
        <div>
            <MessageList slug={slug}/>
        </div>

    )
}

MessageBoard.propTypes = {}

export default MessageBoard
