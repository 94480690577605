import React, {useState} from 'react';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Info, Group, Event, Business, Campaign, Settings } from '@mui/icons-material';
import { StyledAvatar, CommunityBasic, CommunityName, CommunityStatus, AvatarContainer, StyledListItem } from './styled';

const SideMenu = ({ open, onClose, changeTab, avatar, title, subtitle }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    changeTab(index);
  };
  return (
    <Box
      sx={{
        //width: '100%',
        //maxWidth: 240,
        bgcolor: 'background.paper',
        //position: 'fixed',
        //height: '100vh',
        //overflow: 'auto',
      }}
    >
      <AvatarContainer>
        <StyledAvatar alt="Avatar" src={avatar} />
      </AvatarContainer>

      <CommunityBasic>
        <CommunityName>{title}</CommunityName>
        <CommunityStatus>{subtitle}</CommunityStatus>
      </CommunityBasic>
      <Divider />
      <List>
      <StyledListItem selected={selectedIndex === 0} key={'bookings'} onClick={() => handleListItemClick(0)}>
        <ListItemIcon><Info /></ListItemIcon>
        <ListItemText primary='Bookings' />
      </StyledListItem>
      {/* <StyledListItem selected={selectedIndex === 1} key={'plans'} onClick={() => handleListItemClick(1)}>
        <ListItemIcon><Group /></ListItemIcon>
        <ListItemText primary='Plans' />
      </StyledListItem>
      <StyledListItem selected={selectedIndex === 5} key={'manage'} onClick={() => handleListItemClick(5)}>
        <ListItemIcon><Settings /></ListItemIcon>
        <ListItemText primary='Manage' /> 
      </StyledListItem>*/}
      </List>
    </Box>
  );
};

export default SideMenu;
