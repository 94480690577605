import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container, ForgotButton } from './styled'
import { SpacerVerticalSmall, FormItemColumn } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventHeader from '../eventHeader/view'
import HeaderToolbar from '../../../organisms/headerToolbar'
import ParticipantList from '../../participantList/view'
import { joinEvent, updateEventTicket, uploadEventTicketAvatar } from 'api'
import { CircularProgress, Grid, Modal, Button, Stepper, Step, StepLabel } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField, InputLabel, MenuItem, Select, Checkbox, InputAdornment, IconButton, FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router'
import { colors } from 'config/colors'

const EventTicketSignup = ({setLogin, event }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [ticket, setTicket] = useState(null);
  const [formValid, setFormValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  })

  const [formData, setFormData] = useState({
    event: slug,
  })

  const signUp = () => {

      joinEvent(formData).then(r => {
        if (r.status == 'duplicated') {
          setErrors({ ...errors, email: t('event.joinDuplicated') })
        } else {
          
          navigate(`/event-ticket/${r.ticket?.slug}`);
        }
      })
        .catch(error => console.log('The error is -> ', error))
    }



  

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  return (
    <Container>
      <EventHeader event={event} disclaimer={false}/>
      <Box container spacing={2}>
        
        
      </Box>
     

      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="button_color"
            onClick={() => signUp()}
            fullWidth
            disabled={!formValid}
          >Join Event
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={() => setLogin(true)}
            fullWidth
          >I already have an account
          </Button>
        </Grid> */}
      </Grid>

    </Container>
  )
}

EventTicketSignup.propTypes = {
  setLogin: PropTypes.func,
  event: PropTypes.object,
}
EventTicketSignup.defaultProps = {
  setLogin: () => null,
  event: () => {},
}


export default EventTicketSignup
