import React, { useState, useEffect } from 'react'
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { getMessageBoard } from 'api'
import dayjs from 'dayjs'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder'

const MessageList = ({ slug }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [campaigns, setCampaigns] = useState([])

  const fetchMessageBoard = () => {
    getMessageBoard(slug).then(r => {

      const extractedCampaigns = r?.campaign_users.map(userCampaign => userCampaign.campaign) || [];
      setCampaigns(extractedCampaigns);
    })
  }

  useEffect(() => {
    fetchMessageBoard()
  }, [])

  return (
    <Container>
      <Grid container spacing={3} sx={{ paddingTop: 5 }}>
        {campaigns?.length === 0 && (
          <Grid item xs={12}>
            <NoResultsPlaceholder firstSentence={t('No messages found')} lastSentence={t('Stay put!')} />
          </Grid>
        )}
        {campaigns?.map((item, index) => {
          const formattedDate = dayjs(item?.planned_sent).format('DD/MM/YYYY HH:mm')
          const content = item?.body // Assuming the content is stored in 'message'

          return (
            <Grid item xs={12} key={index}>
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Typography variant="h6">{formattedDate}</Typography>
                  <Typography variant="subtitle1" gutterBottom>{item?.subject}</Typography>
                  <Box sx={{ overflow: 'auto' }}>
                    <div dangerouslySetInnerHTML={{ __html: item?.body }} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}

export default MessageList
