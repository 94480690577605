import React, { useEffect, useState } from 'react'
import EventList from '../eventList/view'
import PropTypes from 'prop-types'
import { Typography, Fab, Modal, Button, Grid, Badge, TextField, Select, MenuItem } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { BookingModalContent } from './styled';
import { getBookingPlans, acceptBooking, createBookingPlan, getVenuePrice } from 'api'
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';
import { loadStripe } from '@stripe/stripe-js';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale to start the week on Monday
dayjs.locale('en-gb'); // Set the locale for dayjs

const Activity = props => {
    const { community, upcomingEvents, pastEvents, setCommunityJoin, venues } = props
    const [bookingConfirm, setBookingConfirm] = useState(false)
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        venue: ''
    });
    const [selectedDate, setSelectedDate] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [prices, setPrices] = useState([])
    const [sessionId, setSessionId] = useState(null)
    const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
    const [bookingPrice, setBookingPrice] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0);
    const [updateList, setUpdateList] = useState(false)


    function getVenuePricesInfo(slug) {
        getVenuePrice(slug).then(r => {

            setPrices(r.prices)
        })
            .catch(error => console.log('The error is -> ', error))
    }

    const handleMonthChange = (date) => {
        setIsLoading(true);
        setHighlightedDays([]);
        //fetchHighlightedDays(date);
        setIsLoading(false);
    };

    const doBooking = () => {
        const formattedDate = selectedDate.format('DD/MM/YYYY');

        const data = {
            title: formData.title,
            description: formData.description,
            venue: formData.venue,
            group: community.slug,
            date: formattedDate,
            number_spaces: formData.spaces,
            booking_type: 'HD',
            price: formData.price,
            fee: formData.fee,
        }
        createBookingPlan(data).then(r => {
            setSessionId(r.sessionId)
            setBookingConfirm(false)
            setUpdateList(true)
        })
            .catch(error => console.log('The error is -> ', error))

    };


    function ServerDay(props) {
        const { day, outsideCurrentMonth, ...other } = props;

        // Check if prices is an array
        if (!Array.isArray(prices)) {
            return null; // or handle it in a way that makes sense for your application
        }
        // Check if there is a price for the current day
        const priceInfo = prices?.find((price) => {
            const priceDate = new Date(price.start);
            return priceDate.toISOString().split('T')[0] === props.day.format('YYYY-MM-DD');
        });

        return (
            <Badge
                key={props.day.toString()}
                overlap="circular"
                badgeContent={priceInfo ? `£${priceInfo?.price}` : undefined}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    useEffect(() => {
        if (formData.venue) {
            getVenuePricesInfo(formData.venue)
        }
    }, [formData.venue])

    useEffect(() => {
        if (selectedDate) {
            const formattedDate = selectedDate.format('YYYY-MM-DD');

            // Find the price for the selected date
            const priceEntry = prices.find(price => {
                const startDate = new Date(price.start).toISOString().split('T')[0];
                return startDate === formattedDate;
            });

            // If available spaces are less than selected spaces, reset prices
            if (priceEntry && priceEntry.available < 1) {
                setBookingPrice(0);
            } else {
                const dailyPrice = priceEntry ? parseFloat(priceEntry.price) : 0;
                setBookingPrice(dailyPrice);
            }
        } else {
            setBookingPrice(0);
            setTotalPrice(0);
        }
    }, [selectedDate, prices]);


    return (
        <div>
            <EventList community={community} setCommunityJoin={(data) => setCommunityJoin(data)} updateList={updateList} setUpdateList={setUpdateList} />
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => setBookingConfirm(true)}
            >
                <AddIcon />
            </Fab>
            <Modal
                open={bookingConfirm}
                onClose={() => setBookingConfirm(false)}
                aria-labelledby="booking-modal-title"
                aria-describedby="booking-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <BookingModalContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label="Title"
                                        value={formData?.title || community?.name + ' day'}
                                        onChange={(e) => { setFormData({ ...formData, title: e.target.value }) }}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label="Description"
                                        value={formData?.description || ''}
                                        onChange={(e) => { setFormData({ ...formData, description: e.target.value }) }}
                                        style={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Select
                                        value={formData.venue || ''}
                                        variant='outlined'
                                        onChange={event => setFormData({ ...formData, venue: event.target.value })}
                                        style={{ width: '100%' }}
                                    >
                                        {venues.map(item => (
                                            <MenuItem key={item?.venue?.slug} value={item?.venue?.slug}>
                                                {item?.venue?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button variant='contained' color='button_color' onClick={() => doBooking()} disabled={bookingPrice === 0 || !formData.venue}>
                                        Join {bookingPrice > 0 ? 'for £' + bookingPrice.toFixed(2) : ''}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="en-gb"  // Ensure the locale is set here as well
                            >
                                <DateCalendar
                                    value={selectedDate}
                                    loading={isLoading}
                                    onMonthChange={handleMonthChange}
                                    renderLoading={() => <DayCalendarSkeleton />}
                                    slots={{
                                        day: ServerDay,
                                    }}
                                    slotProps={{
                                        day: {
                                            highlightedDays,
                                        },
                                    }}
                                    onChange={(newValue) => setSelectedDate(newValue)}
                                />
                            </LocalizationProvider>


                        </Grid>
                    </Grid>
                </BookingModalContent>
            </Modal>
        </div>

    )
}

Activity.propTypes = {
    community: PropTypes.object,
    upcomingEvents: PropTypes.array,
    pastEvents: PropTypes.array,
    setCommunityJoin: PropTypes.func
}

export default Activity
