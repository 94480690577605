import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, ImageContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import BookingHeader from '../bookingHeader/view'
import InternalHeader from '../../general/internalHeader/view'
import BookingListItem from '../bookingListItem/view'
import { getBooking, createBooking,  getCommunities, getVenuesGroup, getVenuePrice} from 'api'
import { TextField, FormControlLabel, Switch, FormGroup, Select, Grid, MenuItem, Avatar, Button } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import coworking from 'assets/images/coworking.jpg'
import { API_BASE_URL } from '../../../../config/api'
import noAvatar from '../../../../assets/images/no-avatar.png'
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';

const BookingForm = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [bookings, setBookings] = useState([])

  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [pastBookings, setPastBookings] = useState([])
  const [upcomingBookings, setUpcomingBookings] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");
  const [bookingPrice, setBookingPrice] = useState("")
  const [description, setDescription] = useState("")
  const [community, setCommunity] = useState('');
  const [communities, setCommunities] = useState([]);
  const [venues, setVenues] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null);
  const [prices, setPrices] = useState([])

  const [theBooking, setBooking] = useState({
    slug: '',
    name: '',
    description: '',
    community: '',
    space: '',
    tags: [],
    date: null,
    start: null,
    end: null,
    spaces: 0,
    image: '',
    background: '',
  })
  const [image, setImage] = useState(theBooking?.image ? `${API_BASE_URL}${theBooking?.image}` : noAvatar);
  const [background, setBackground] = useState(theBooking?.background ? `${API_BASE_URL}${theBooking?.background}` : noAvatar);
  const navigate = useNavigate()

  const handleChange = (booking, newValue) => {
    setValue(newValue);
  };

  function addBooking() {
    const formattedDate = theBooking?.date?.format('DD/MM/YYYY');
    const formData = {
      "bookings": [
        {
          venue: theBooking.space,
          start: formattedDate,
          end: formattedDate,
          number_spaces: theBooking.spaces,
          booking_type: 'HD',
          price: prices[0].price,
        }
      ]
    }
    createBooking(formData).then(r => {
      //console.log(r)
      //navigateBooking(r)
    })
  }


  function navigateBooking(booking) {
    navigate(`/bookings/${theBooking?.slug}`);
    //window.location.href = '/login'
  }

  function headerActionMethod(firstLoad) {
    
  }

  const handleCommunity = (booking) => {
    setBooking({ ...theBooking, community: booking.target.value })
  };

  const handleSpace = (booking) => {
    setBooking({ ...theBooking, space: booking.target.value })
  };

  const handleTags = (booking) => {
    setBooking({ ...theBooking, tags: booking.target.value })
  };


  const handleDateChange = (date) => {
    setBooking({ ...theBooking, date: date })
    setSelectedDate(date);
  };

  const handleTimeStartChange = (time) => {
    setBooking({ ...theBooking, start: time })    
  };

  const handleTimeEndChange = (time) => {
    setBooking({ ...theBooking, end: time })
  };


  function getVenuePricesInfo(firstLoad) {
    const parameters = {
      spaces: theBooking.spaces,
      // format theBooking.date  as YYYY-MM-DD
      start: theBooking?.date?.format('YYYY-MM-DD'),
      end: theBooking.date.format('YYYY-MM-DD'),
      booking_type : 'HD'
    }

    getVenuePrice(theBooking.space, parameters).then(r => {
      setPrices(r.prices)
      // get the element title from the first element of the array prices
      r.prices.length > 0 && setBookingPrice(r.prices[0].title ? r.prices[0].title : '')
    })
      .catch(error => console.log('The error is -> ', error))
  }


  const handleFileChange = (booking) => {
    const file = booking.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (booking) => {
        setSelectedFile({
          file: file,
          imageUrl: booking.target.result,
        });
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImage(reader.result);
      };
    }
    setSelectedFile(file);
  };

  const handleBackgroundChange = (booking) => {
    const file = booking.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (booking) => {
        setSelectedBackground({
          file: file,
          imageUrl: booking.target.result,
        });
      };
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setBackground(reader.result);
      };
    }
    setSelectedBackground(file);
  };

  const openFileInput = () => {
    document.getElementById('imageInput').click();
  };


  

  useEffect(() => {

    getCommunities().then(r => {
      setCommunities(r.communities)
    }).catch(error => console.log('The error is -> ', error))



  }, [])

  useEffect(() => {
    if (theBooking.community !== '') {
      getVenuesGroup(theBooking.community).then(r => {
        setVenues(r.venues)
      }).catch(error => console.log('The error is -> ', error))
    }
  }, [theBooking.community])

  useEffect(() => {
    if (theBooking.date !== null && theBooking.space !== '' && theBooking.spaces > 0) {
      getVenuePricesInfo(true)
    }
    // if date is set, calculate available venues nd estimate individual price
  }, [theBooking])


  const avatar = noAvatar
  return (
    <Container>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Spaces"
              type="number"
              value={theBooking.spaces}
              onChange={(e) => setBooking({ ...theBooking, spaces: e.target.value })}
              InputProps={{
                inputProps: {
                  min: 0,
                  step: 1,
                },
              }}
            />

          </Grid>
          <Grid item xs={12} sm={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="Booking date" value={selectedDate} onChange={handleDateChange} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Select
              value={theBooking?.community}
              onChange={handleCommunity}
              displayEmpty
              inputProps={{ 'aria-label': 'Community' }}
            >
              <MenuItem value="" disabled>Select a community</MenuItem>
              {communities?.map((item, index) => (
                <MenuItem value={item.slug}>{item.name}</MenuItem>
              ))
              }
              {/* Add more communities as needed */}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Select
              value={theBooking?.space}
              onChange={handleSpace}
              displayEmpty
              inputProps={{ 'aria-label': 'Community' }}
            >
              <MenuItem value="" disabled>Select a venue</MenuItem>
              {venues?.map((item, index) => (
                <MenuItem value={item.slug}>{item.name}</MenuItem>
              ))}
            </Select>
          </Grid>          
          <Grid item xs={12} sm={12}>
            <TextField label="Comments" value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '60%' }} />
          </Grid>

          
          <Grid item xs={12} sm={12}>
            <Button variant="extended" color="primary" aria-label="add" style={{ width: '60%' }} onClick={() => addBooking()}>
              Book for {bookingPrice}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

BookingForm.propTypes = {}

export default BookingForm
