import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, AccordionContainer, AccordionTitle, AccordionSection, Hero, AccordionSectionTitle, AccordionSectionContent, HeroBox, HeroAvatarContainer, HeroBoxMain, HeroBoxDetails, HeroMainData, HeroMainText, AccordionSectionShare, InfoTextSmall, InfoTextMedium, AccordionSectionQR } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import HeaderToolbar from '../../../organisms/headerToolbar'
import ParticipantList from '../../participantList/view'
import EventModalParticipant from '../eventModalParticipant/view'
import { getEventTicket, getPublicEventTicket } from 'api'
import { useMediaQuery, Snackbar, AccordionSummary, AccordionDetails, Link, Fade, Divider, Collapse, Grid, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { API_BASE_URL } from '../../../../config/api'
import LoadingCard from 'components/molecules/loadingCard/container'
import QRCode from 'react-qr-code';
import ShareButton from './shareButton'

const EventToolbar = ({ theEvent, loggedIn, setLoggedIn }) => {

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { t } = useTranslation()
  const [showDetails, setShowDetails] = useState(false);
  const [openSnack, setOpenSnack] = useState("");

  const toggleDetails = () => {
    setShowDetails(prev => !prev);
  };
  const navigate = useNavigate();
  const tabs = []



  const gridStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    alignItems: 'center',
  };

  const infoStyleLeft = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
  };

  const infoStyleRight = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'right',
  };

  const infoStyleCentral = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    paddingLeft: '10px',
  };


  // Function to format the date and time
  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    return `${formattedDate}`;
  }

  // Function to format the date and time
  function formatDateFull(dateTimeString) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateTimeString);
    const dayOfWeek = daysOfWeek[date.getDay()];
    const formattedDate = `${dayOfWeek}, ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    return formattedDate;
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    return `${formattedTime}`;
  }



  const formattedDate = formatDate(theEvent.start);
  const formattedDateFull = formatDateFull(theEvent.start);
  const formattedStart = formatTime(theEvent.start);
  const formattedEnd = formatTime(theEvent.end);

  const eventImage = theEvent.image ? `${API_BASE_URL}${theEvent.image}` : 'https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg'
  const eventBackground = theEvent.background ? `${API_BASE_URL}${theEvent.background}` : null

  const baseUrl = window.location.origin;
  const shareUrl = `${baseUrl}/events/${theEvent.slug}`;

  return (
    <Container>
      <Hero backgroundImage={eventBackground}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} key={1}>
            {theEvent?.length === 0 ? <LoadingCard /> :
              <HeroBox>
                <HeroBoxMain>
                  <HeroAvatarContainer>
                    <img
                      src={eventImage}
                      alt="Event Image"
                      style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
                    />
                  </HeroAvatarContainer>
                  <HeroMainData>
                    <HeroMainText>
                      <InfoTextMedium>{formattedDate},  {formattedStart} - {formattedEnd}</InfoTextMedium>
                      <Typography variant="h6">
                        {theEvent?.name}
                      </Typography>
                      <Link href={theEvent?.venue?.gmaps_address} target="_blank" rel="noopener">
                        <InfoTextSmall>{theEvent?.venue?.name}</InfoTextSmall>
                        <InfoTextSmall>{theEvent?.venue?.single_address}</InfoTextSmall>
                      </Link>
                    </HeroMainText>
                  </HeroMainData>

                </HeroBoxMain>
                <HeroBoxDetails showDetails={showDetails} aria-label="expand details">


                  <Collapse in={showDetails} orientation="vertical" easing={{ enter: '300' }} unmountOnExit>
                    <AccordionContainer>
                      {theEvent?.subtitle && <AccordionSection>
                        <AccordionTitle>
                          {theEvent?.subtitle}
                        </AccordionTitle>
                      </AccordionSection>}
                      {theEvent?.description && <AccordionSection>
                        <AccordionSectionTitle>
                          About the event
                        </AccordionSectionTitle>
                        <AccordionSectionContent>
                          {theEvent?.description}
                        </AccordionSectionContent>
                      </AccordionSection>}
                      {theEvent?.instructions !== "" && <AccordionSection>
                        <AccordionSectionTitle>
                          General Information
                        </AccordionSectionTitle>
                        <AccordionSectionContent>
                          <div dangerouslySetInnerHTML={{ __html: theEvent?.instructions }} />
                        </AccordionSectionContent>
                      </AccordionSection>}
                      {theEvent?.rules !== "" && <AccordionSection>
                        <AccordionSectionTitle>
                          Basic Rules
                        </AccordionSectionTitle>
                        <AccordionSectionContent>
                          <div dangerouslySetInnerHTML={{ __html: theEvent?.rules }} />
                        </AccordionSectionContent>
                      </AccordionSection>}
                      <AccordionSection>
                        <AccordionSectionTitle>
                          When
                        </AccordionSectionTitle>
                        <AccordionSectionContent>
                          {formattedDateFull},  {formattedStart} - {formattedEnd}
                        </AccordionSectionContent>
                      </AccordionSection>
                      <AccordionSection>
                        <AccordionSectionTitle>
                          Where
                        </AccordionSectionTitle>
                        <AccordionSectionContent>
                          <Link href={theEvent?.venue?.gmaps_address} target="_blank" rel="noopener">
                            <InfoTextSmall>{theEvent?.venue?.single_address}</InfoTextSmall>
                          </Link>
                        </AccordionSectionContent>
                      </AccordionSection>
                      {theEvent?.group?.description !== "" && <AccordionSection>
                        <AccordionSectionTitle>
                          About us
                        </AccordionSectionTitle>
                        <AccordionSectionContent>
                          {theEvent?.group?.description}
                        </AccordionSectionContent>
                      </AccordionSection>}
                      <AccordionSection>
                        <AccordionSectionTitle>
                          Share this event
                        </AccordionSectionTitle>
                        <AccordionSectionQR>
                          <QRCode value={shareUrl} size={192} />
                        </AccordionSectionQR>
                        <AccordionSectionShare>
                          <ShareButton slugEvent={theEvent.slug} setOpenSnack={setOpenSnack} />
                        </AccordionSectionShare>
                      </AccordionSection>
                    </AccordionContainer>
                  </Collapse>
                </HeroBoxDetails>
                <div
                  style={{

                    
                    zIndex: 100, // Ensure the button is on top
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0px 0px 5px',
                    margin: '0 auto',
                  }}
                  onClick={toggleDetails}
                  aria-label="expand details"
                >
                  <div style={{
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    backgroundColor: '#0F617D',
                    color: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px 5px',
                    borderRadius: '5px',
                    fontSize: '0.75rem',
                  }}>
                    {showDetails ? <ExpandLessIcon size='small'/> : <ExpandMoreIcon size='small' />}
                    {showDetails ? 'Less' : 'More'} details
                    {showDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                </div>
              </HeroBox>}
            <Snackbar
              open={openSnack != ""}
              onClose={() => setOpenSnack("")}
              autoHideDuration={3000}
              message={openSnack}
            />
          </Grid>
        </Grid>
      </Hero>
    </Container >
  )
}

EventToolbar.propTypes = {
  theEvent: PropTypes.object,
  loggedIn: PropTypes.bool,
  setLoggedIn: PropTypes.func,
}

EventToolbar.defaultProps = {
  theEvent: {},
  loggedIn: false,
  setLoggedIn: () => { },
}

export default EventToolbar
