import styled from 'styled-components'

export const Container = styled.div`
    min-height: 40vh;
    margin: 20px;  
    @media (max-width: 768px) {
        width: 100%; // Full screen on mobile devices
        margin: 10px 0;  

    }
`
