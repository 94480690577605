import styled from 'styled-components'

export const Container = styled.div``

export const ForgotButton = styled.a`
    position: fixed;
    bottom: 40px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5%;
    width: 160px;
    height: 80px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
`

export const ModalContent = styled.div`
    background-color: #fff;
    height: 80vh;
    overflow-y: auto;  // Enable vertical scrolling when content;
    width: 30%; // Full screen on mobile devices
    margin: 0 auto; /* Center horizontally */
    justify-content: center; /* Center horizontally */
    margin-top: 10vh;
    

    @media (max-width: 768px) {
        width: 90%; // Full screen on mobile devices
        height: 80vh;
        margin: 10vh auto; /* Center horizontally */
    }
`

export const ModalFooter = styled.div`
    margin: 0px 10px 30px 10px;    
`

export const ContainerStyle = styled.div`
    text-align: center;
`

export const ImageStyle = styled.div`
    width: 40%; /* Half of the modal's width */
    height: auto;
    margin: 0 auto; /* Center horizontally */
`