import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import FilterListIcon from '@mui/icons-material/FilterList';
import { Container, ForgotButton, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventToolbar from '../eventToolbar/view'
import ParticipantList from '../../participantList/view'
import EventModalParticipant from '../eventModalParticipant/view'
import EventOnboarding from '../eventOnboarding/view'
import { getEvent, getPublicEvent } from 'api'
import { Fab, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar, } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import LoginIcon from '@mui/icons-material/Login';
import ErrorIcon from '@mui/icons-material/Error';

const EventParticipant = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [theParticipants, setParticipants] = useState([])
  const [myTicket, setMyTicket] = useState([])
  const [theEvent, setEvent] = useState([])
  const [userModal, setUserModal] = useState(false)
  const [joinModal, setJoinModal] = useState(false)
  const [eventFull, setEventFull] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token') !== null)
  const [anonymous, setAnonymous] = useState(true)


  const navigate = useNavigate();

  const tabs = []

  function loadModal(participant) {
    setSelectedParticipant(participant)
    setUserModal(true)
  }

  function closeModal(visible) {
    setSelectedParticipant(null)
    setJoinModal(false)
  }


  function getEventInfo() {
    if (loggedIn) {      
      getEvent(slug).then(r => {
        setEvent(r.event)
        setParticipants(r.event?.active_tickets)
        if (r.event?.my_ticket !== null) {
          navigate(`/event-ticket/${r.event?.my_ticket?.slug}`);
        }
        setMyTicket(r.event?.my_ticket)
        if (r.event?.space_limit <= r.event?.active_tickets?.length) {
          setEventFull(true)
        }
        setAnonymous(false)
      })
        .catch(error => console.log('The error is -> ', error))
    } else {
      getPublicEvent(slug).then(r => {
        setEvent(r.event)
        setParticipants(r.event.active_tickets)
        setMyTicket(r.event.my_ticket)
      })
        .catch(error => console.log('The error is -> ', error))
    }

  }

  useEffect(() => {
    getEventInfo()
  }, [])

  return (
    <Container>
      <EventToolbar theEvent={theEvent} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      <ParticipantList participants={theParticipants} setSelectedParticipant={loadModal} anonymous={anonymous}/>
      <EventModalParticipant selectedParticipant={selectedParticipant}  myTicket={myTicket} visibleModal={userModal} onClose={() => setUserModal(false)} />
    
      <Modal
        open={joinModal}
        onClose={() => setJoinModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>
        <EventOnboarding event={theEvent} />
        </ModalContent>
      </Modal>
      {!eventFull && <ForgotButton onClick={() => setJoinModal(true)}>
        <StyledFab variant="extended">
          <LoginIcon sx={{ mr: 1 }} />
          {t('event.join')}
          </StyledFab>        
        </ForgotButton>}
        {eventFull && <ForgotButton>
        <StyledFab variant="extended">
          <ErrorIcon sx={{ mr: 1 }} />
          {t('event.full')}
          </StyledFab>        
        </ForgotButton>}
    </Container>
  )
}

EventParticipant.propTypes = {}

export default EventParticipant
