import styled from 'styled-components'


export const PlaceholderContainer = styled.div`
  display:flex;
  border: 1px solid #eaecef;
  height: 100%;
  padding: 1%;
  background-color: white;
  box-shadow: 2px 5px 5px 1px lightgrey;
`
export const ImageContainer = styled.div`
  width: 100px;
  height: 100%;
  background-color: #eaecef;
  margin-right: 1%;
`
export const Image = styled.div`
  border: 1px solid white;
  width: 100%;
  height: 100%;
  background-color: #eaecef;
`

export const ContentContainer = styled.div`
  border: 1px solid white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  justify-content: space-between;  
`

export const Stripe = styled.div`
  background-color: #eaecef;
  height: 20px;
  margin-bottom: 5px;
  animation: blinker 1.5s linear infinite;
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`

export const SmallStripe = styled(Stripe)`
  width: 50%;
`
export const MediumStripe = styled(Stripe)`
  width: 70%;
`
export const LongStripe = styled(Stripe)`
  width: 100%;
`
