import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, SecondRowContainer, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import CommunityHeader from '../communityHeader/view'
import InternalHeader from '../../general/internalHeader/view'
import CommunityListItem from '../communityListItem/view'
import MemberList from '../memberList/view'
import EventList from '../eventList/view'
import { getCommunity, getCommunitiesForMember, searchCommunities } from 'api'
import { Grid, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'

import CommunitySlider from 'components/organisms/communitySlider/view';

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import noAvatar from '../../../../assets/images/no-avatar.png'
import { API_BASE_URL } from 'config/api';
import coworking from 'assets/images/coworking.jpg'
import { useProfile } from 'context';


const CommunityHome = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { profile } = useProfile()
  const { slug } = params
  const [communities, setCommunities] = useState([])
  const [founders, setFounders] = useState([])
  const [members, setMembers] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [gallery, setGallery] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [value, setValue] = useState("0");

  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function getCommunityInfo(firstLoad) {
    getCommunitiesForMember(slug).then(r => {
      setCommunities(r.communities)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function headerActionMethod(firstLoad) {
    const params = { search: 'python' }
    searchCommunities(params).then(r => {
      setCommunities(r.groups)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function navigateCommunity(slug) {
    navigate(`/community/${slug}`);
    //window.location.href = '/login'
  }



  useEffect(() => {
    getCommunityInfo(true)

  }, [])



  const avatar = profile?.avatar ? `${API_BASE_URL}${profile?.avatar}` : noAvatar
  const background = profile?.background ? `${API_BASE_URL}${profile?.background}` : coworking

  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <InternalHeader title={'Your communities'} subtitle={''} avatar={avatar} background={background} actionTitle='Create community' actionMethod={headerActionMethod} />

      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: 5 }}>
          {communities?.length === 0 && (
            <NoResultsPlaceholder firstSentence={t('No communities found')} lastSentence={t('Browse our communities and join them!  ')} />
          )}
        </Grid>
        {communities?.map((item, index) => (
          <Grid item xs={6} sm={4} md={3} key={index}>
            <CommunityListItem group={item} selectCommunity={(item) => navigateCommunity(item.group?.slug)} />
          </Grid>
        ))}
      </Grid>
      <Grid>
        <CommunitySlider navigateCommunity={(item) => navigateCommunity(item)} existingCommunities={[]} showMore={false}/>
      </Grid>
      {/* List of communities */}
    </Container>
  )
}

CommunityHome.propTypes = {}

export default CommunityHome
