import styled from 'styled-components'
import { colors } from 'config/colors'

export const Container = styled.form`
  padding: 20px;
  display: grid;
  grid-template-columns: auto;
  row-gap: 16px;
`

export const ForgotButton = styled.a`
  justify-self: end;
  color: ${colors.gray};
  font-size: 14px;
  margin-bottom: 23px;
`
