import React, { useState, useEffect } from 'react';
import { Chip, Grid, Typography, Box, Button, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete'; // Import Autocomplete component
import { updateProfile } from 'api';

const TagsSection = ({ profile, tags = [] }) => {
  const { tags_offer, tags_need } = profile;

  // State to store selected tags for offering help
  const [offerTags, setOfferTags] = useState(tags_offer || []);
  const [selectedTag, setSelectedTag] = useState(null); // Store selected tag

  // Function to update the tags in the profile
  const updateTags = () => {
    const postTags = {
      offers: offerTags,
      needs: [], // Keep this empty or implement if needed
    };
    console.log('Post Tags:', postTags);

    updateProfile(postTags)
      .then((r) => {
        console.log('Profile Updated:', r);
      })
      .catch((error) => {
        console.error('Error Updating Profile:', error);
      });
  };

  // Add a tag to the "Can Help With" list
  const handleAddOfferTag = () => {
    if (selectedTag && !offerTags.some((t) => t.name === selectedTag.name)) {
      setOfferTags([...offerTags, selectedTag]);
      setSelectedTag(null); // Clear selected tag after adding
    }
  };

  // Remove a tag from the "Can Help With" list
  const handleRemoveOfferTag = (tagToRemove) => {
    setOfferTags(offerTags.filter((tag) => tag.name !== tagToRemove.name));
  };

  useEffect(() => {
    updateTags();
  }, [offerTags]);

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Grid container spacing={3}>
        {/* Available Tags Selection */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Select Tags
          </Typography>

          {/* Autocomplete for Tags */}
          <Autocomplete
            options={tags} // Provide the available tags here
            getOptionLabel={(option) => option.name} // Display the tag name
            value={selectedTag}
            onChange={(event, newValue) => {
              setSelectedTag(newValue); // Set the selected tag
            }}
            renderInput={(params) => (
              <TextField {...params} label="Choose a tag" variant="outlined" />
            )}
            sx={{ marginBottom: 2 }}
          />

          {/* Add Button */}
          <Button variant="contained" onClick={handleAddOfferTag} disabled={!selectedTag}>
            I'm interested
          </Button>
        </Grid>

        {/* Selected "Can Help With" Tags Column */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Can Help With
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {offerTags.map((tag) => (
              <Chip
                key={tag.name}
                label={tag.name}
                color="secondary"
                onDelete={() => handleRemoveOfferTag(tag)} // Add onDelete handler
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TagsSection;
