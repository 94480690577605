import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { padding, styled } from '@mui/system';
import { Grid } from '@mui/material'
import { Card, CardMedia, Typography, Box, Chip, CardContent, CardActions, IconButton, Button } from '@mui/material';
import { Container, HeroBoxMain, AvatarWrapper, HeroAvatarContainer, HeroMainData, HeroMainText, InfoTextMedium, InfoTextSmall, CustomBadge, HeroPriceContainer, ModalContent } from './styled'
import { API_BASE_URL } from '../../../../config/api'
import DoneIcon from '@mui/icons-material/Done';
import DeviceUnknown from '@mui/icons-material/DeviceUnknown';
import { InnerChip, AvatarContainer } from './styled';
import noAvatar from '../../../../assets/images/no-avatar.png'
import { useNavigate } from 'react-router'
import InfoIcon from '@mui/icons-material/Info';

const ActivityListItem = ({ event, selectedOption, navigateItem, selectBooking }) => {

  const navigate = useNavigate();

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  // Function to format the date and time
  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    return `${formattedDate}`;
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    return `${formattedTime}`;
  }

  const formattedDate = formatDate(event.activiyStart);
  const formattedStart = formatTime(event.activiyStart);
  const formattedEnd = formatTime(event.activityEnd);

  const eventImage = event.image ? `${API_BASE_URL}${event.image}` : 'https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg'
  const eventBackground = event.background ? `${API_BASE_URL}${event.background}` : null

  const typeName = event?.type === 'group_booking' ? 'Workspace Booking' : 'Event'
  const typeColor = event?.type === 'group_booking' ? 'success' : 'info'
  let spaceText = event?.available_spaces > 0 ? `Available` : 'Fully Booked'
  if (selectedOption === 'past') {
    spaceText = 'Not available'
  } else if (event?.available_spaces === -1) {
    if (event?.bookings?.length > 0) {
      spaceText = event?.bookings?.length + ' x booked'
    }

  } else if (event?.available_spaces === 0) {
    spaceText = 'Fully Booked'
  }
  const formattedPrice = event?.total_price && event?.total_price > 0 ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(event?.total_price) : '-'

  return (

    <HeroBoxMain>
      <Grid container>
        {/* Responsive Grid for Chip - Visible only on extra small screens */}
        <Grid
          event
          xs={12}
          sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, justifyContent: 'flex-end', paddingRight: '5px' }}
        >
          <Chip label={typeName} color={typeColor} size='small' sx={{ marginRight: '5px', marginTop: '5px', marginBottom: '5px' }} />
        </Grid>

        {/* Main Content Area */}
        <Grid event xs={12} md={8} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
          <HeroAvatarContainer>
            <AvatarWrapper>
              <img
                src={eventImage}
                alt="Event Image"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </AvatarWrapper>
            <HeroPriceContainer>
              {formattedPrice}
            </HeroPriceContainer>
          </HeroAvatarContainer>
          <HeroMainData>
            <HeroMainText>
              <Grid container>
                <Grid event xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                  <InfoTextMedium>{formattedDate}, {formattedStart} - {formattedEnd}</InfoTextMedium>
                </Grid>
              </Grid>
              <Grid event xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <Grid event xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography variant="h6" onClick={() => navigateItem(event.type, event?.community?.slug, event.slug)} sx={{ cursor: 'pointer' }}>
                    {event?.title}
                  </Typography>
                </Grid>
                <Grid event xs={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography variant="body2">
                    {event?.description}
                  </Typography>
                </Grid>
              </Grid>

              <Grid event xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '10px' }}>
                <Grid event xs={12} onClick={() => navigateItem('venue', event?.community?.slug, event?.venue_slug)} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                  <InfoTextSmall>{event?.venue}</InfoTextSmall>
                  <InfoTextSmall>{event?.venue_address}</InfoTextSmall>
                </Grid>
              </Grid>
            </HeroMainText>
          </HeroMainData>
        </Grid>

        {/* Right Column - for larger screens */}
        <Grid event xs={12} md={4} sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column' }, alignItems: { xs: 'center', sm: 'flex-end' } }}>
          {/* Chip Section - Visible only on small and larger screens */}
          <Grid
            event
            xs={12}
            sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, justifyContent: 'flex-end', paddingRight: '5px', flexWrap: 'wrap' }}
          >
            <Chip label={typeName} color={typeColor} size='small' sx={{ marginRight: '5px', marginTop: '5px' }} />
          </Grid>

          {/* Action Buttons - Align left */}
          <Grid
            event
            xs={6}
            sm={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start', // Align left
              paddingRight: '5px',
              flexWrap: 'wrap'
            }}
          >
            {event?.my_booking === false && selectedOption === 'upcoming' && event?.available_spaces !== 0 && (
              <Button sx={{ margin: '5px' }} variant='contained' color='secondary' onClick={() => selectBooking(event)}>
                Book now
              </Button>
            )}
            {event?.my_booking && <Chip label='Attending' color='primary' size='medium' sx={{ margin: '5px' }} />}
          </Grid>

          {/* Info Section - Align right */}
          <Grid
            event
            xs={6}
            sm={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end', // Align right
              paddingRight: '5px',
              flexWrap: 'wrap'
            }}
          >
            <b>{event?.bookings?.length > 0 ? spaceText : ''}</b>
            <IconButton onClick={() => navigateItem(event.type, event?.community?.slug, event.slug)} sx={{ margin: '0px' }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>

      </Grid>

    </HeroBoxMain>
  );
};

ActivityListItem.propTypes = {
  event: PropTypes.object,
  selectedOption: PropTypes.string,
  navigateItem: PropTypes.func,
  selectBooking: PropTypes.func
}

ActivityListItem.defaultProps = {
  event: {},
  selectedOption: null,
  navigateItem: () => {},
  selectBooking: () => {}
}

export default ActivityListItem
