// src/components/Providers.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AppRoutes from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';
import { getTheme } from '../config/theme';
import '../App.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getProfile } from '../api';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useProfile } from '../context';

const Providers = () => {
  const [theme, setTheme] = useState(getTheme(null));
  const [configLoaded, setConfigLoaded] = useState(false);
  const { profile, setProfile } = useProfile();

  const fetchProfile = async () => {
    try {
      const localData = await getProfile();
      setProfile(localData);
    } catch (error) {
      console.error('Failed to fetch profile', error);
    }
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem('token');
    if (loggedIn) {
      fetchProfile().then(() => setConfigLoaded(true));
    } else {
      setConfigLoaded(true);
    }
  }, [setProfile]);

  if (!configLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ParallaxProvider>
        <ThemeProviderStyled theme={theme}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider maxSnack={3}>
                <Router>
                  <AppRoutes profile={profile} />
                </Router>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </ThemeProviderStyled>
      </ParallaxProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default Providers;
