import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container, ForgotButton } from './styled'
import { SpacerVerticalSmall, FormItemColumn } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventHeader from '../eventHeader/view'
import HeaderToolbar from '../../../organisms/headerToolbar'
import ParticipantList from '../../participantList/view'
import { joinEvent, updateEventTicket, uploadEventTicketAvatar } from 'api'
import { CircularProgress, Grid, Modal, Button, Stepper, Step, StepLabel } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField, InputLabel, MenuItem, Select, Checkbox, InputAdornment, IconButton, FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router'
import { colors } from 'config/colors'

const EventTicketSignup = ({ setLogin, event }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [ticket, setTicket] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    name: '',
    email: '',
  })

  const [formData, setFormData] = useState({
    event: slug,
    name: '',
    email: '',
    password: '',
    privacyPolicyChecked: false,
  })

  const signUp = () => {

    joinEvent(formData).then(r => {
      if (r.status == 'duplicated') {
        setErrors({ ...errors, email: t('event.joinDuplicated') })
      } else {

        navigate(`/event-ticket/${r.ticket?.slug}`);
      }
    })
      .catch(error => console.log('The error is -> ', error))
  }



  const handleInputChange = (event) => {
    setErrors({ ...errors, email: '' })
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (formData.privacyPolicyChecked && formData.name !== '' && formData.email !== '') {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }, [formData])


  return (
    <Container>
      <EventHeader event={event} />
      <Box container spacing={2}>
        <Grid item xs={12}>
          <FormItemColumn>
            <InputLabel id="email">Your email</InputLabel>
            <TextField
              size='small'
              variant="outlined"
              required
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && (<span style={{ color: colors.orange, marginTop: '10px', fontSize: '13px', textAlign: 'center' }}>{errors.email}</span>)}
          </FormItemColumn>
        </Grid>
        <Grid item xs={12}>
          <FormItemColumn>
            <InputLabel id="name">Your name</InputLabel>
            <TextField
              size='small'
              variant="outlined"
              required
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormItemColumn>
        </Grid>
        <Divider variant="middle" />
        <SpacerVerticalSmall />
        <Grid item xs={12}>
          If you already have an account, you can login <a href="#" onClick={() => setLogin(true)}>here</a> or create an account <a href="#" onClick={() => setLogin(false)}>here</a>
        </Grid>
        <Grid item xs={12}>
          <FormItemColumn>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.privacyPolicyChecked}
                  onChange={(e) => handleCheckboxChange(e, 'privacyPolicyChecked')}
                  name="privacyPolicyChecked"
                  color="primary"
                />
              }
              label={
                <span>
                  I accept the{' '}
                  <a href="https://the-epic.space/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                </span>
              }
            />
          </FormItemColumn>
        </Grid>
      </Box>
      {/*
       <Grid >
        <Grid item xs={12}>
          <FormItemColumn>
            <Typography variant="body2" color="textSecondary">
              You can create an account by adding a password (Optional)
            </Typography>
            <TextField
              size='small'
              variant="outlined"
              fullWidth
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={showPassword ? 'Hide password' : 'Show password'}
                      onClick={handleTogglePasswordVisibility}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormItemColumn>
        </Grid>
      </Grid> */}

      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="button_color"
            onClick={() => signUp()}
            fullWidth
            disabled={!formValid}
          >Join Event
          </Button>
        </Grid>
        {/* <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={() => setLogin(true)}
            fullWidth
          >I already have an account
          </Button>
        </Grid> */}
      </Grid>

    </Container>
  )
}

EventTicketSignup.propTypes = {
  setLogin: PropTypes.func,
  event: PropTypes.object,
}
EventTicketSignup.defaultProps = {
  setLogin: () => null,
  event: () => { },
}


export default EventTicketSignup
