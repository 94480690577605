import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100vh', 
        textAlign: 'center', 
        padding: '20px' 
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '6rem', fontWeight: 'bold', marginBottom: '20px' }}>
        Unknown Community
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        Oops! The community you are looking for doesn't exist.
      </Typography>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={handleGoHome}
        sx={{ marginTop: '20px' }}
      >
        Go to Homepage
      </Button>
    </Box>
  );
};

export default Error404;
