import React from 'react'
import map from 'lodash/map'
import PropTypes from 'prop-types'

import Tabs from '@mui/material/Tabs'
import {
  PlaceholderContainer,
  ImageContainer,
  Image,
  ContentContainer,
  Stripe,
  SmallStripe,
  MediumStripe,
  LongStripe
} from './styled'

const LoadingCardView = () => (
  <PlaceholderContainer>
    <ImageContainer>
      <Image />
    </ImageContainer>
    <ContentContainer>
      <Stripe />
      <SmallStripe />
      <MediumStripe />
      <LongStripe />
    </ContentContainer>
  </PlaceholderContainer>

)

LoadingCardView.propTypes = {}

LoadingCardView.defaultProps = {}

export default LoadingCardView
