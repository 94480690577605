import React from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Text from 'components/atoms/text'
import PropTypes from 'prop-types'
import { map } from 'lodash'
import {
  Container,
  ContainerTitle,
  ContentDialog,
  DialogActions,
  DialogContainer,
  DialogSubtitle,
  HeaderActions,
  Title,
  TitleContainer,
} from './styled'

const DialogTitle = ({ children, onClose, ...other }) => (
  <ContainerTitle disableTypography {...other}>
    <Title>{children}</Title>
  </ContainerTitle>
)

DialogTitle.defaultProps = {
  onClose: () => {},
  children: <></>,
}

DialogTitle.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
}

const ModalDialogView = ({ onClose, title, subtitle, open, actions, className, children }) => (
  <DialogContainer onClose={onClose} open={open} className={className}>
    <HeaderActions>
      {onClose ? (
        <IconButton aria-label='close' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </HeaderActions>
    <Container>
      <TitleContainer>
        <DialogTitle onClose={onClose}>
          <Text variant='h2'>{title}</Text>
        </DialogTitle>
        {subtitle && (
          <DialogSubtitle>
            <Text>{subtitle}</Text>
          </DialogSubtitle>
        )}
      </TitleContainer>
      <ContentDialog>{children}</ContentDialog>
      {actions && (
        <DialogActions>
          {map(actions, ({ onClick, label, color, id }) => (
            <Button key={id} variant='contained' color={color} onClick={onClick}>
              {label}
            </Button>
          ))}
        </DialogActions>
      )}
    </Container>
  </DialogContainer>
)

ModalDialogView.defaultProps = {
  onClose: () => {},
  title: '',
  subtitle: '',
  open: false,
  actions: [],
  className: '',
  children: <></>,
}

ModalDialogView.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  actions: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default ModalDialogView
