import { styled } from '@mui/system';
import { Paper, Typography, CardMedia, Box } from '@mui/material';

export const Container = styled('div')()



export const HeroBox =  styled('div')({
    paddingTop: '3vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',    
    flexDirection: 'column',
});




export const HomeContent = styled('div')({
    maxWidth: '1024px',
    margin: 'auto',
});

export const SectionHead = styled(Typography)(({ theme }) => ({
    fontSize: '1.8rem',
    color: theme.palette.title.main,
    width: '80%',
    margin: '10vh auto',
    textAlign: 'center',
    '@media (max-width: 600px)': {
        marginBottom: '60px',
        width: '100%',
      }, 
      
  }));