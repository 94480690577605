import React from 'react'
import { Route, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import Layout from '../components/template/layout'
import NoLoggedLayout from '../components/template/noLoggedLayout'
import FullScreenLayout from '../components/template/fullScreenLayout'
import component from 'components/pages/login'

const SelectedLayout = ({ layoutType, children, ...rest }) => {
  switch (layoutType) {
    case 'noLogin':
      return <Layout {...rest}>{children}</Layout>
      //return <NoLoggedLayout {...rest}>{children}</NoLoggedLayout>
    case 'logged':
      return <Layout {...rest}>{children}</Layout>
    default:
      return <Layout {...rest}>{children}</Layout>
      //return <FullScreenLayout {...rest}>{children}</FullScreenLayout>
  }
}

SelectedLayout.defaultProps = {
  layoutType: '',
  children: <></>,
}

SelectedLayout.propTypes = {
  layoutType: PropTypes.string,
  children: PropTypes.node,
}

const RouteWrapper = ({ children, layoutType,  breadcrumb,  ...rest }) => {
    return (layoutType === 'logged') ? (
      <SelectedLayout
        layoutType={layoutType}
        breadcrumb={breadcrumb}
      >
        {children}
      </SelectedLayout>
    ) : (
      
      {children}
    )
  
    }

RouteWrapper.defaultProps = {
  component: () => {},
  layoutType: '',
  breadcrumb: true,
}

RouteWrapper.propTypes = {
  component: PropTypes.func,
  layoutType: PropTypes.string,
  breadcrumb: PropTypes.bool,
}

export default RouteWrapper
