import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { Container, ForgotButton, ModalContent, StyledFab } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import EventToolbar from '../eventToolbar/view'
import EventUpsell from '../eventUpsell/view';
import ParticipantList from '../../participantList/view'
import EventModalParticipant from '../eventModalParticipant/view'
import EventTicketUpdate from '../eventTicketUpdate/view'
import ModalCloseButton from '../../../atoms/modalCloseButton/view'
import { getEventTicket, getPublicEventTicket } from 'api'
import { Fab, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import LoadingBody from 'components/molecules/loadingBody/container';

const EventTicket = () => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const [theParticipants, setParticipants] = useState([])
  const [myTicket, setMyTicket] = useState(null)
  const [theEvent, setEvent] = useState([])
  const [userModal, setUserModal] = useState(false)
  const [joinModal, setJoinModal] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [ticketInfo, setTicketInfo] = useState(null)
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('token'))
  const [expanded, setExpanded] = useState(false);
  const [featuredParticipant, setFeaturedParticipant] = useState(null)


  function loadModal(participant) {
    setSelectedParticipant(participant)
    setUserModal(true)
  }

  function getTicketInfo(firstLoad) {

    if (loggedIn) {
      getEventTicket(slug).then(r => {
        setEvent(r.event)
        setParticipants(r.event.active_tickets)
        setMyTicket(r.event.my_ticket)
        if (r.event?.my_ticket?.status === 'PU' && firstLoad) {
          setJoinModal(true)
        }
      })
        .catch(error => console.log('The error is -> ', error))
    } else {
      getPublicEventTicket(slug).then(r => {
        setEvent(r.event)
        setParticipants(r.event.active_tickets)
        setMyTicket(r.event.my_ticket)
        if (r.event?.my_ticket?.status === 'PU' && firstLoad) {
          setJoinModal(true)         
        }
      })
        .catch(error => console.log('The error is -> ', error))
    }

  }
  function closeAndReload() {
    getTicketInfo(false)
    setUserModal(false)
    setFeaturedParticipant(null)
  }
  useEffect(() => {
    getTicketInfo(true)
    
  }, [])

  useEffect(() => {
    if (featuredParticipant !== null) {
      theParticipants.map((item, index) => {
        if (item.slug === featuredParticipant) {
          loadModal(item)
        }
      })
    }
  }, [featuredParticipant])  

  return (
    <Container>
      <EventToolbar theEvent={theEvent} loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
      { theEvent?.length === 0 && <LoadingBody/>}
      <EventUpsell theEvent={theEvent} setFeaturedParticipant={setFeaturedParticipant}/>
      <ParticipantList participants={theParticipants} setSelectedParticipant={loadModal} />
      <EventModalParticipant selectedParticipant={selectedParticipant} myTicket={myTicket} visibleModal={userModal} onClose={() => closeAndReload()} />
      <Modal
        open={joinModal}
        onClose={() => setJoinModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>
          <ModalCloseButton onClick={() => setJoinModal(false)} />
          <EventTicketUpdate myTicket={myTicket} visibleModal={(visible) => setJoinModal(visible)} event={theEvent} reload={() => getTicketInfo()} />
        </ModalContent>
      </Modal>
      
      <ForgotButton onClick={() => setJoinModal(true)}>
        <StyledFab variant="extended">
          <RefreshIcon sx={{ mr: 1 }} />
          {myTicket ? t('event.update') : t('event.join')}
          </StyledFab>        
        </ForgotButton>
    </Container>
  )
}

EventTicket.propTypes = {}

export default EventTicket
