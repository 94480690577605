import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import ModalDialog from 'components/molecules/modalDialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PassInput from 'components/atoms/passInput'
import TextInput from 'components/atoms/textInput'
import { Container, ForgotButton } from './styled'
import validate from './validate'
import { useNavigate } from 'react-router-dom'

const SignupFormView = ({ onSubmit, setShowLogin }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  })

  const [modal, setModal] = useState(false)

  const handleClickForgot = () => {
    setModal(true)
  }

  const handleClickLogin = () => {
    navigate('/login')
  }

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <TextInput label={t('signup.nameLabel')} name='first_name' control={control} error={errors?.first_name} />
        <TextInput label={t('signup.lastNameLabel')} name='last_name' control={control} error={errors?.last_name} />
        <TextInput label={t('signup.emailLabel')} name='email' control={control} error={errors?.email} />
        <PassInput label={t('signup.passwordLabel')} name='password' control={control} error={errors?.password} />
        <Button variant='contained' color='secondary' type='submit'>
          {t('signup.signUp')}
        </Button>
        <Button variant='contained' color='primary' onClick={() => handleClickLogin()}>
          {t('signup.join')}
        </Button>
      </Container>
    </>
  )
}

SignupFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default SignupFormView
