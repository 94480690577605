import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import LayoutView from './view'
import Spinner from './spinner'
import { OFFICES } from 'api/constants'
import { useQuery } from 'react-query'

//const ALL_OFFICES = { id: 'all', text: 'All', slug: 'all', floors: [] }
const fetchOffices = async () => {
  //const data = await getOffices()
  //data.offices.push(ALL_OFFICES)
  //return data
  return null
}



const Layout = ({
  children,
  theme,
  breadcrumb,
  setProfile,
  profile,
  ...props
}) => {
  
  useEffect(() => {
    //refetch()
    const loggedIn = localStorage.getItem('token')
    //setProfile(loggedIn)
  }, []) 

  /* 
  const { data, isLoading, refetch } = useQuery(null, fetchOffices, { enabled: false })
  useEffect((data) => {
    if (data) {
      if (!profile) {
        setProfile(data)
      }
    }
  }, [data, profile, setProfile])
 */
  
  //if (isLoading || !profile) return <Spinner />
  
  return (
    <LayoutView
      {...props}
      theme={theme}
      breadcrumb={breadcrumb}
      profile={profile}
    >
      {children}
    </LayoutView>
  )
}

Layout.defaultProps = {
  children: <></>,
  profile: null,
}

Layout.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.object.isRequired,
  breadcrumb: PropTypes.bool.isRequired,
  setProfile: PropTypes.func.isRequired,
  profile: PropTypes.string,
}

export default withTheme(Layout)
