import React from 'react'
import Context from '../../../context'
import Container from './container'

const Layout = ({ ...props }) => (
  <Context.Consumer>
    {({
      profile,
      setProfile,
    }) => (
      <Container
        {...props}
        profile={profile}
        setProfile={setProfile}
      />
    )}
  </Context.Consumer>
)

export default Layout
