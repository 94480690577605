import { Grid, Button } from '@mui/material'
import routes from 'config/routes'
import bgLogin from 'assets/images/bgLogin.png'
import { useNavigate } from 'react-router'
import { Container, Wrapper } from '../login/styled'

function CheckMail() {
  const navigate = useNavigate()

  function nexStep() {
    //navigate(routes.login)
  }

  return (
    <Container style={{ backgroundImage: `url(${bgLogin})` }}>
      <Wrapper elevation={0}>
        <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
          <Grid item>
            <Grid container direction='column' justify='center' alignItems='center'>
              <Grid item style={{ marginBottom: '25px', fontSize: '25px', fontWeight: 900 }}>
                Almost there!
              </Grid>
              <Grid item style={{ fontSize: '18px' }}>
                You should have by now a welcome email with some account validation instructions. Please follow them and
                log in again to continue with your onboarding processs.
              </Grid>
              <Grid item style={{ fontSize: '18px' }}>
                We promise that it won&apos;t take more than a few minutes!
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify='center' style={{ marginTop: '25px' }}>
                <Button variant='contained' color='secondary' onClick={() => nexStep()}>
                  I have validated my account
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  )
}

export default CheckMail
