import React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import map from 'lodash/map'
import PropTypes from 'prop-types'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Item, ItemText } from './styled'

const BreadcrumbView = ({ routes }) => (
  <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
    {routes &&
      routes.length > 1 &&
      map(routes, ({ label, id, path }) => (
        <Item key={id} color='inherit' to={path}>
          <ItemText>{label}</ItemText>
        </Item>
      ))}
  </Breadcrumbs>
)

BreadcrumbView.propTypes = {
  routes: PropTypes.array,
}

BreadcrumbView.defaultProps = {
  routes: [],
}

export default BreadcrumbView
