import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import ModalDialog from 'components/molecules/modalDialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PassInput from 'components/atoms/passInput'
import TextInput from 'components/atoms/textInput'
import { Container, ForgotButton } from './styled'
import validate from './validate'
import { useNavigate } from 'react-router-dom'

const LoginFormView = ({ onSubmit, setShowLogin, defaultEmail }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  })

  const [modal, setModal] = useState(false)

  const handleClickForgot = () => {
    navigate('/reset')
  }

  const handleClickSignup = () => {
    navigate('/signup')
  }

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <TextInput label={t('login.emailLabel')} name='email' control={control} error={errors?.email} value={defaultEmail} />
        <PassInput label={t('login.passwordLabel')} name='password' control={control} error={errors?.password} />
        <ForgotButton onClick={handleClickForgot}>{t('login.forgot')}</ForgotButton>
        <Button variant='contained' color='secondary' type='submit'>
          {t('login.logIn')}
        </Button>
{/*         <Button variant='contained' color='primary' onClick={() => handleClickSignup()}>          
            {t('login.join')}          
        </Button> */}
      </Container>
    </>
  )
}

LoginFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  setShowLogin: PropTypes.func.isRequired
}

export default LoginFormView
