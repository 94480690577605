// src/App.js
import React, { Component } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { initTranslation } from '../assets/i18n';
import { interceptorToken } from '../api/utils';
import '../App.css';
import Providers from './Providers';
import { ProfileProvider } from '../context';

const queryClient = new QueryClient();

class App extends Component {
  constructor(props) {
    super(props);
    interceptorToken();
    initTranslation();
  }

  render() {
    return (
      <ProfileProvider>
        <QueryClientProvider client={queryClient}>
          <Providers />
        </QueryClientProvider>
      </ProfileProvider>
    );
  }
}

export default App;
