import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'

import {
  Container,
  SecondRowContainer,
  ModalContent,
  OverviewRight,
  OverviewLeft,
  OverviewTitle,
  OfferContainer,
  OpeningTimesContainer,
  LocationContainer,
  StyledBadge,
  StyledPickersDay,
  HeroBoxMain,
  HeroAvatarContainer,
  AvatarWrapper,
  HeroPriceContainer,
  HeroMainData,
  HeroMainText,
  InfoTextMedium,
  InfoTextSmall,
  OverviewMainTitle,
  ResponsiveTextField
} from './styled'
import { SpacerVerticalSmall, FormItemColumn } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import VenueHeader from '../../venue/venueHeader/view'
import EventList from '../../community/eventList/view'
import { getVenue, getVenuePrice, getVenueActivity, createBooking, joinBooking } from 'api'
import { TextField, Link, Chip, Grid, Tooltip, CardMedia, Button, FormControlLabel, Checkbox, Box, Modal } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import noAvatar from '../../../../assets/images/no-avatar.png'
import CircularProgress from '@mui/material/CircularProgress';
import LocationMap from 'components/organisms/locationMap/view'
import { useSnackbar } from 'notistack'
import PeopleIcon from '@mui/icons-material/People';
import BoltIcon from '@mui/icons-material/Bolt';

import * as Icons from '@mui/icons-material';

import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale to start the week on Monday


import Badge from '@mui/material/Badge';

import Typography from '@mui/material/Typography';
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';

import FloatingBookingSelector from 'components/organisms/floatingBookingSelector/view'
import InfoIcon from '@mui/icons-material/Info';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import ImageGallery from "react-image-gallery";
import { useProfile } from 'context';

import PhotoAlbum from "react-photo-album";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import 'react-image-gallery/styles/css/image-gallery.css';
import { set } from 'date-fns';

import ActivityListItem from '../../community/activityListItem/view';
import WeekSlider from 'components/organisms/weekSlider/view';


dayjs.locale('en-gb'); // Set the locale for dayjs


const VenueSingle = () => {

  const { profile, spaces, setSpaces, bookingType, setBookingType } = useProfile();

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params
  const navigate = useNavigate();
  const [venue, setVenue] = useState(null)
  const [confirmModal, setConfirmModal] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0);
  const [bookingCode, setBookingCode] = useState(null)
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [center, setCenter] = useState(null)
  const [gallery, setGallery] = useState(null)
  const [sessionId, setSessionId] = useState(null)
  const [images, setImages] = useState([])
  const [amenities, setAmenities] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null)
  const [quickCheckout, setQuickCheckout] = useState(false)
  const [prices, setPrices] = useState([])
  const [activity, setActivity] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  const [value, setValue] = useState("0");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([1, 5, 7, 10, 20, 27])
  const [isLoading, setIsLoading] = useState(false);

  const [openingHoursData, setOpeningHours] = useState([
    { day: 'Monday', time: 'Closed' },
    { day: 'Tuesday', time: 'Closed' },
    { day: 'Wednesday', time: 'Closed' },
    { day: 'Thursday', time: 'Closed' },
    { day: 'Friday', time: 'Closed' },
    { day: 'Saturday', time: 'Closed' },
    { day: 'Sunday', time: 'Closed' },
  ]);


  const [errors, setErrors] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: '',
  })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: false,
  })

  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    return `${formattedTime}`;
  }

  function navigateItem(type, communitySlug, eventSlug) {

    if (type === 'group_booking') {
      navigate(`/community/${communitySlug}/booking/${eventSlug}`);
    }

    if (type === 'event') {
      navigate(`/events/${slug}`);
    }


  }

  const selectBooking = (booking) => {

    setSelectedBooking(booking)
    setQuickCheckout(true)


  }

  const getDayName = (day) => {
    switch (day) {
      case 0:
        return 'Monday'
      case 1:
        return 'Tuesday'
      case 2:
        return 'Wednesday'
      case 3:
        return 'Thursday'
      case 4:
        return 'Friday'
      case 5:
        return 'Saturday'
      case 6:
        return 'Sunday'
      default:
        return ''
    }
  }

  const getIconComponent = (iconName) => {
    return Icons[iconName] || Icons.Help; // Default to HelpIcon if not found
  };

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }



  const handleInputChange = (event) => {
    if (event.target.name === 'name') setErrors({ ...errors, name: '' })
    if (event.target.name === 'email') setErrors({ ...errors, email: '' })
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const doBooking = () => {
    const postData = {
      "bookings": [
        {
          number_spaces: spaces,
          booking_type: bookingType,
          venue: slug,
          start: selectedDate.format('YYYY-MM-DD'),
          price: totalPrice,
          instant_booking: selectedPrice?.instant_booking,
        }
      ]
    }
    createBooking(postData).then(r => {
      setSessionId(r.sessionId)
    })
      .catch(error => console.log('The error is -> ', error))

  };

  const joinActivity = () => {
    const postData = {
      "bookings": [
        {
          code: selectedBooking?.code,
          number_spaces: 1,
          price: selectedBooking?.price,
        }
      ]
    }
    joinBooking(postData).then(r => {
      setSessionId(r.sessionId)
      setQuickCheckout(false)
    }).catch(error => {
      enqueueSnackbar(t('booking.message.error'), {
        variant: 'error',
      })
      setQuickCheckout(false)
    })
  };

  function getVenueInfo(slug) {
    getVenue(slug).then(r => {
      const locationCenter = { lat: r?.location[0], lng: r?.location[1] }
      setCenter(locationCenter)
      setVenue(r)
      if (r.gallery) {
        const galleryImages = r.gallery.map(g => {
          return {
            original: `${API_BASE_URL}${g.image}`,
            thumbnail: `${API_BASE_URL}${g.image}`,
          }
        })
        setImages(galleryImages)
      }
      if (r.open_time) {
        const openingHours = r.open_time.map(o => {
          const formattedOpen = o.time_start?.split(':').slice(0, 2).join(':');
          const formattedClose = o.time_end?.split(':').slice(0, 2).join(':');

          return {
            day: getDayName(parseInt(o.day)),
            time: `${formattedOpen} - ${formattedClose}`
          }
        });
        const updatedOpeningHours = openingHoursData?.map(day => {
          const matchingDay = openingHours?.find(o => o.day === day.day);
          if (matchingDay) {
            return matchingDay;
          } else {
            return day;
          }
        });

        setOpeningHours(updatedOpeningHours);
      }
      if (r.venue_services) {
        setAmenities(r.venue_services);
      }
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function getVenuePricesInfo(firstLoad) {
    getVenuePrice(slug).then(r => {
      setPrices(r.prices)
    })
      .catch(error => console.log('The error is -> ', error))
  }


  function getVenueActivityInfo(firstLoad) {
    getVenueActivity(slug).then(r => {
      setActivity(r.upcoming_activities)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function formatDate(dateTimeString) {
    const date = new Date(dateTimeString);
    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
    return `${formattedDate}`;
  }

  useEffect(() => {
    console.log('Selected price changed ', selectedPrice)
    if (selectedPrice) {
      setSelectedDate(dayjs(selectedPrice?.start));
      // Find the price for the selected date
      // If available spaces are less than selected spaces, reset prices
      if (selectedPrice && selectedPrice?.available < spaces) {
        setTotalPrice(0);
      } else {
        const dailyPrice = selectedPrice ? parseFloat(selectedPrice?.price) : 0;
        setTotalPrice(dailyPrice * spaces);
      }
    } else {
      setTotalPrice(0);
    }
  }, [spaces, bookingType, prices, selectedPrice]);

  useEffect(() => {
    getVenueInfo(slug)
    getVenuePricesInfo(true)
    getVenueActivityInfo(true)
  }, [])

  useEffect(() => {
    if (sessionId != null) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (error) {
          console.error(error.message);
          // Handle the error, e.g., display a message to the user
        }
      }
      redirectToCheckout()
    }

  }, [sessionId])

  const handleSpacesChange = (event) => {
    setSpaces(event.target.value);
  };

  const handleDateSelect = (priceObject) => {
    console.log('Price Object', priceObject)
    // Handle date selection logic
    //const selectedDate = dayjs(date);
    setSelectedPrice(priceObject);
  };

  const avatar = venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar
  const background = venue?.background ? `${API_BASE_URL}${venue?.background}` : noAvatar

  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <VenueHeader title={venue?.name} subtitle={venue?.single_address} avatar={avatar} background={background} />
      <Grid container>
        <Grid item xs={12}>

          <SecondRowContainer>

            <div>
              {/* Tab content sections */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6}>
                  <OverviewLeft>
                    <OverviewMainTitle>{venue?.name}</OverviewMainTitle>
                    <OverviewTitle>About us</OverviewTitle>
                    <div dangerouslySetInnerHTML={{ __html: venue?.long_description }} />
                    <div>
                      <ImageGallery items={images} />
                    </div>

                    <OverviewTitle>Amenities</OverviewTitle>
                    {amenities.length > 0 ? (
                      <Grid container spacing={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {amenities.map((amenity) => {
                          const IconComponent = getIconComponent(amenity.icon);
                          return (
                            <Grid item key={amenity.slug} xs={4} sm={3} md={2} style={{ textAlign: 'center' }}>
                              <Tooltip title={amenity.description} placement="top">
                                <div>
                                  <IconComponent style={{ fontSize: 40, marginBottom: 8 }} color="primary" />
                                </div>
                              </Tooltip>
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : (
                      <Typography>No amenities available</Typography>
                    )}
                    <LocationContainer>
                      <OverviewTitle>Location</OverviewTitle>
                      <Typography color="textSecondary" sx={{ fontSize: '14px', marginBottom: '16px' }}>
                        {venue?.single_address}
                      </Typography>
                      {venue && venue?.location && venue?.location[0] && venue?.location[1] && (
                        <LocationMap
                          latitude={venue?.location[0]}
                          longitude={venue?.location[1]}
                          name={venue?.name}
                          googleMapsUrl={venue?.google_maps_url}
                        />
                      )}
                    </LocationContainer>

                    <OpeningTimesContainer>
                      <OverviewTitle>Opening Times</OverviewTitle>
                      {openingHoursData?.map(({ day, time }) => (
                        <div key={day} style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '0.9rem' }}>{day}</Typography>
                          <Typography sx={{ fontSize: '0.9rem', color: '#666' }}>{time}</Typography>
                        </div>
                      ))}
                    </OpeningTimesContainer>
                  </OverviewLeft>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <OverviewRight>
                    <OverviewTitle sx={{ marginTop: '50px' }}>Book a workspace</OverviewTitle>
                    <Grid item xs={12} md={12}>
                      <WeekSlider
                        slug={slug}
                        onDateSelect={handleDateSelect}
                      />
                    </Grid>


                    <Grid item xs={12} md={12} sx={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                      <Grid item xs={6} md={4} spacing={1} sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <IconButton>
                          <PeopleIcon />
                        </IconButton>

                        <TextField
                          id="spaces"
                          type="number"
                          value={spaces}
                          onChange={handleSpacesChange}
                          inputProps={{ min: 1 }}
                          size="small"
                        />

                      </Grid>
                      <Grid item xs={6} md={8} spacing={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        {selectedPrice?.available >= spaces && <Button variant='contained' color='button_color' onClick={() => doBooking()}>Book {spaces} {bookingType === 'HD' ? 'Hot Desk' : 'Meeting Room'}{spaces > 1 ? 's' : ''} for £{totalPrice}</Button>}
                        {selectedPrice?.available < spaces && <Button variant='contained' disabled={true} color='button_color' >Not available</Button>}

                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} sx={{ marginTop: '10px', alignContent: 'center', justifyContent: 'center', display: 'flex' }}>
                    </Grid>                    
                    <OverviewTitle sx={{ marginTop: '50px' }}>Next activity</OverviewTitle>
                    <Grid item xs={12} md={12}>
                      {!isLoading && activity?.map((item, index) => (
                        <ActivityListItem event={item} selectedOption="upcoming" navigateItem={navigateItem} selectBooking={selectBooking} />
                      ))}
                    </Grid>



                  </OverviewRight>
                </Grid>
              </Grid>




            </div>
          </SecondRowContainer>
        </Grid>

      </Grid>

      <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>

          <Typography id="parent-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
            Confirm your booking
          </Typography>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ position: 'relative' }} onClick={() => setConfirmModal(true)}>
              <CardMedia
                component="img"
                src={venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar}
                alt="Venue Image"
                onError={handleImageRenderError}
                sx={{ borderRadius: '10px', height: '150px' }}
              />

              <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '14px', marginBottom: '10px', marginTop: '20px' }}>
                Please confirm your booking details below and your contact information.
              </Typography>
              <Grid container spacing={2} style={{ padding: '10px' }}>
                <Grid item xs={3}>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Date:</Typography>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Price:</Typography>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Venue:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    {selectedDate}
                  </Typography>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    £{selectedPrice?.price}
                  </Typography>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    {venue?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Box container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    size='small'
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    size='small'
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid xs={12}>
                  <FormItemColumn style={{ marginTop: '0px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size='small'
                          checked={formData.privacyPolicyChecked}
                          onChange={(e) => handleCheckboxChange(e, 'privacyPolicyChecked')}
                          name="privacyPolicyChecked"
                          color="primary"

                        />
                      }
                      error={!!errors.privacyPolicyChecked}
                      helperText={errors.privacyPolicyChecked}
                      label={
                        <span style={{ fontSize: '12px' }}>
                          I accept the{' '}
                          <a href="https://the-epic.space/privacy" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                          </a>
                        </span>
                      }
                    />
                  </FormItemColumn>
                </Grid>
              </Box>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button size='small' disabled={!formData.privacyPolicyChecked} variant='contained' color='secondary' onClick={() => doBooking()} sx={{ alignItems: 'center' }}>
                  Confirm booking
                </Button>
              </div>

            </div>

          </Grid>

        </ModalContent>
      </Modal>
      <Modal open={quickCheckout} onClose={() => setQuickCheckout(false)} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <ModalContent>
          <Typography variant="h6" component="h2" sx={{ marginTop: '30px' }}>
            Checkout
          </Typography>
          <Typography variant="body2" sx={{ marginTop: '10px' }}>
            You are about to book a work space at {selectedBooking?.venue} on {formatDate(selectedBooking?.activiyStart)} for £{selectedBooking?.total_price}
          </Typography>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
            <Button size='small' variant='contained' color='secondary' onClick={() => joinActivity()} sx={{ alignItems: 'center' }}>
              Confirm booking
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </Container>
  )
}

VenueSingle.propTypes = {}

export default VenueSingle
