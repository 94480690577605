import React from 'react'
import PropTypes from 'prop-types'
import DropzoneInputView from './view'

const DropzoneInput = ({ onChange, value, rules, ...props }) => {
  const handleChange = acceptedFiles => {
    onChange(
      acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    )
  }
  return <DropzoneInputView onChange={handleChange} value={value} {...props} />
}

DropzoneInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  rules: PropTypes.object,
}

DropzoneInput.defaultProps = {
  value: [],
  onChange: () => null,
  rules: {},
}

export default DropzoneInput
