import styled from 'styled-components'
import { ListItem } from '@mui/material';

export const Container = styled.div`
    margin: 20px;  
    @media (max-width: 768px) {
        width: 100%; // Full screen on mobile devices
        margin: 10px 0;  

    }
`

export const AvatarContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
});

export const StyledAvatar = styled('img')({
    width: '60%',
    borderRadius: '50%',
    margin: 'auto',

    //top: '0px', // Adjust to position the top half inside the hero section
    //left: '60px',
    '@media (max-width: 600px)': {
        left: '20px',
        width: '180px',
        height: '180px', // Set the height to twice the avatar height
    },
});

export const CommunityBasic = styled('div')({
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
  });

export const CommunityName = styled('div')({
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    //fontWeight: 'bold',
    marginTop: '10px',
});

export const CommunityStatus = styled('div')({
    fontSize: '16px',
    marginBottom: '10px',
});
export const StyledListItem = styled(ListItem)(({ theme, selected }) => ({
    backgroundColor: selected ? theme.palette.action.selected : 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
    // Add the following styles to replace the deprecated method
    '&.Mui-selected': {
      backgroundColor: theme.palette.action.selected,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.action.hover,
    },
}));