import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardMedia, Typography, Box, IconButton, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import noAvatar from 'assets/images/no-avatar.png';
import { API_BASE_URL } from 'config/api';
import { styled } from '@mui/system';

// Styled container for the whole slider
const BookingSliderContainer = styled(Box)(({ theme }) => ({
  padding: '20px',
  backgroundColor: '#f5f5f5',
  marginTop: '40px',
  position: 'relative', // For positioning arrows
  overflow: 'hidden', // Hide overflow for smooth scrolling
}));

// Horizontal scrolling container
const SliderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  transition: 'transform 0.3s ease-in-out', // Smooth scroll transition
}));

// Styled individual card component (compact)
const StyledCard = styled(Card)(({ theme }) => ({
  width: '350px', // Compact width like BookingSlider
  margin: '10px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)', // Slight scale on hover
  },
}));

// Left and right navigation arrows
const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

const BookingSlider = ({ navigateBooking, bookings }) => {
  const sliderRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const handleScrollLeft = () => {
    if (sliderRef.current) {
      const newPosition = Math.max(scrollPosition - 300, 0); // Scroll 300px left
      setScrollPosition(newPosition);
      sliderRef.current.scrollTo({ left: newPosition, behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    if (sliderRef.current) {
      const maxScroll = sliderRef.current.scrollWidth - sliderRef.current.clientWidth;
      const newPosition = Math.min(scrollPosition + 300, maxScroll); // Scroll 300px right
      setScrollPosition(newPosition);
      sliderRef.current.scrollTo({ left: newPosition, behavior: 'smooth' });
    }
  };

  const handleCardClick = (slug) => {
    navigateBooking(slug);
  };

  const navigateAllBookings = () => {
    navigate(`/bookings`);
  };

  const typeMapping = {
    HD: 'Hot Desk',
    PR: 'Private Office',
    MR: 'Meeting Room',
    EV: 'Event',
    OT: 'Other',
  };

  return (
    <BookingSliderContainer>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: '20px' }}>
        <Grid item>
          <Typography variant="h5" component="h2" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
            Featured bookings
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{ cursor: 'pointer', color: 'primary.main', fontSize: '14px' }}
            onClick={navigateAllBookings}
          >
            See all
          </Typography>
        </Grid>
      </Grid>

      {/* Left Arrow */}
      {scrollPosition > 0 && (
        <ArrowButton onClick={handleScrollLeft} sx={{ left: '10px' }}>
          <ArrowBackIosIcon />
        </ArrowButton>
      )}

      {/* Horizontal scrollable content */}
      <Box
        sx={{ overflowX: 'auto', display: 'flex' }}
        ref={sliderRef}
      >
        <SliderWrapper>
          {bookings.map((booking, index) => {
            const image = booking?.venue?.image
              ? `${API_BASE_URL}${booking?.venue?.image}`
              : noAvatar;
            const cardTitle = booking?.venue?.name || booking?.name;
            const bookingPrice = parseFloat(
              booking?.price ? booking.price + booking.vat_amount : 0
            );
            const description = booking?.booking_type
              ? `${typeMapping[booking.booking_type]} - £${bookingPrice.toFixed(2)}`
              : '';
            const cardSubtitle = `${new Date(booking.planned_start_day).toLocaleDateString(
              'en-GB',
              { day: '2-digit', month: '2-digit', year: 'numeric' }
            )} ${booking.planned_start_time.slice(0, -3)} - ${booking.planned_end_time.slice(
              0,
              -3
            )}`;

            return (
              <StyledCard key={index} onClick={() => handleCardClick(booking.slug)}>
                <CardMedia component="img" height="120" image={image} alt={booking.name} />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '10px',
                  }}
                >
                  <Typography gutterBottom variant="h6" component="div">
                    {cardTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: '-webkit-box',
                      overflow: 'hidden',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      textOverflow: 'ellipsis',
                      mt: 1,
                    }}
                  >
                    {description}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {cardSubtitle}
                  </Typography>
                </CardContent>
              </StyledCard>
            );
          })}
        </SliderWrapper>
      </Box>

      {/* Right Arrow */}
      {sliderRef.current && scrollPosition < sliderRef.current.scrollWidth - sliderRef.current.clientWidth && (
        <ArrowButton onClick={handleScrollRight} sx={{ right: '10px' }}>
          <ArrowForwardIosIcon />
        </ArrowButton>
      )}
    </BookingSliderContainer>
  );
};

export default BookingSlider;
