import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import FilterListIcon from '@mui/icons-material/FilterList';
import { Container, ContainerStyle, ImageStyle } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import HeaderToolbar from '../../../organisms/headerToolbar'
import ParticipantList from '../../participantList/view'
import EventOnboarding from '../eventOnboarding/view'
import { getEvent, getPublicEvent } from 'api'
import {  Dialog, DialogTitle, DialogContent, Backdrop, Fade, Divider, Grid, Modal, List, ListItem, ListItemText, ListItemAvatar, Avatar, } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { API_BASE_URL } from '../../../../config/api'

const EventHeader = ({event, disclaimer}) => {

  const { t } = useTranslation()
  const eventImage = event?.image ? `${API_BASE_URL}${event?.image}` : 'https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg'
  const eventName = event?.name ? event?.name : ''

  return (
    <ContainerStyle>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <ImageStyle>
            <img
              src={eventImage}
              alt="Event Image"
              style={{ width: '100%', height: 'auto' }}
            />
          </ImageStyle>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" gutterBottom>
            {eventName}
          </Typography>
          <Typography gutterBottom style={{fontSize: 12}}>
            {disclaimer ? t('event.joinDisclaimer') : t('event.joinConfirm')}
          </Typography>
          
        </Grid>
        <Grid item xs={12}>
        
        </Grid>
      </Grid>
    </ContainerStyle>
  )
}

EventHeader.propTypes = {
  event: PropTypes.object,
  disclaimer: PropTypes.bool
}
EventHeader.defaultProps = {
  event: {},
  disclaimer: true
}

export default EventHeader
