import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Grid,
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import DeskIcon from '@mui/icons-material/Desk';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the British locale
import { useProfile } from 'context';
import { Container, ResponsiveFormControl, ResponsiveTextField } from './styled'; // Import styled components

// Set the locale for dayjs to British English
dayjs.locale('en-gb');

const FloatingBookingSelector = ({ isFloating = true, showDate = true }) => {
  const { spaces, setSpaces, bookingType, setBookingType, start, setStart } = useProfile();

  useEffect(() => {
    if (showDate) {
      const today = dayjs();
      setStart(today);
    }
  }, [setStart, showDate]);

  const handleSpacesChange = (event) => {
    setSpaces(event.target.value);
  };

  const handleBookingTypeChange = (event) => {
    setBookingType(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStart(date);
  };

  return (
    <Box
      sx={{
        position: isFloating ? 'fixed' : 'static',
        bottom: isFloating ? 16 : 'auto',
        right: isFloating ? 16 : 'auto',
        backgroundColor: 'white',
        boxShadow: isFloating ? '0px 0px 10px rgba(0, 0, 0, 0.1)' : 'none',
        borderRadius: '8px',
        padding: '8px',
        zIndex: isFloating ? 1000 : 'auto',
        width: '100%',
        maxWidth: '600px',
        transition: 'width 0.3s ease',
      }}
    >
      <Grid container spacing={1} alignItems="center">
        {showDate && (
          <Grid item xs={12} md={4} container alignItems="center">
            <Container sx={{ display: 'flex', alignItems: 'center' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  value={start}
                  onChange={handleStartDateChange}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" sx={{ mx: 1, width: '120px' }} />
                  )}
                  inputFormat="DD/MM/YYYY" // Set the date format to British
                />
              </LocalizationProvider>
            </Container>
          </Grid>
        )}

        <Grid item xs={12} md={showDate ? 8 : 12} container alignItems="center" spacing={1}>
          <Container sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton>
              <PeopleIcon />
            </IconButton>
            <ResponsiveTextField>
              <TextField
                id="spaces"
                type="number"
                value={spaces}
                onChange={handleSpacesChange}
                inputProps={{ min: 1 }}
                size="small"
              />
            </ResponsiveTextField>

            <IconButton>
              <DeskIcon />
            </IconButton>
            <ResponsiveFormControl>
              <Select
                id="booking-type"
                value={bookingType}
                onChange={handleBookingTypeChange}
                variant="outlined"
                size="small"
              >
                <MenuItem value="HD">Hot Desk</MenuItem>
                <MenuItem value="MR">Meeting Room</MenuItem>
                <MenuItem value="PO">Office</MenuItem>
              </Select>
            </ResponsiveFormControl>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FloatingBookingSelector;
