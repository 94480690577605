import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import IconButton from '@mui/material/IconButton'
import ExitToApp from '@mui/icons-material/ExitToApp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {
  Container,
  SecondRowContainer,
  ModalContent,
  OverviewRight,
  OverviewLeft,
  OverviewTitle,
  OfferContainer,
  OpeningTimesContainer,
  LocationContainer,
} from './styled'
import { SpacerVerticalSmall, FormItemColumn } from '../../helpers/formStyle'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import VenueHeader from '../../venue/venueHeader/view'
import EventList from '../eventList/view'
import { getVenue, getAvailableGroupBooking, joinBooking } from 'api'
import { Avatar, Alert, Grid, Card, CardMedia, Button, FormControlLabel, Checkbox, Snackbar, Box, Modal } from '@mui/material'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import noAvatar from '../../../../assets/images/no-avatar.png'
import CircularProgress from '@mui/material/CircularProgress';
import dayjs, { Dayjs } from 'dayjs';
import Badge from '@mui/material/Badge';
import LocationMap from '../../../organisms/locationMap'

import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'
import RefreshIcon from '@mui/icons-material/Refresh';
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';

import ImageGallery from "react-image-gallery";

import PhotoAlbum from "react-photo-album";

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import 'react-image-gallery/styles/css/image-gallery.css';

import { useSnackbar } from 'notistack'

const BookingSingle = () => {



  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug, bookingSlug } = params
  const [venue, setVenue] = useState(null)
  const [confirmModal, setConfirmModal] = useState(false)
  const [bookingPrice, setBookingPrice] = useState(0)
  const [bookingCode, setBookingCode] = useState(null)
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [center, setCenter] = useState(null)
  const [gallery, setGallery] = useState(null)
  const [sessionId, setSessionId] = useState(null)
  const [venueSlug, setVenueSlug] = useState(null)
  const [images, setImages] = useState([])

  const [prices, setPrices] = useState([])
  const [plan, setPlan] = useState(null)

  const [value, setValue] = useState("0");
  const [selectedDate, setSelectedDate] = useState(null);
  const [highlightedDays, setHighlightedDays] = useState([1, 5, 7, 10, 20, 27])
  const [isLoading, setIsLoading] = useState(false);
  const [referralFee, setReferralFee] = useState(0);
  const [openingHoursData, setOpeningHours] = useState([
    { day: 'Monday', time: 'Closed' },
    { day: 'Tuesday', time: 'Closed' },
    { day: 'Wednesday', time: 'Closed' },
    { day: 'Thursday', time: 'Closed' },
    { day: 'Friday', time: 'Closed' },
    { day: 'Saturday', time: 'Closed' },
    { day: 'Sunday', time: 'Closed' },
  ]);
  const [dataProtection, setDataProtection] = useState({ 'level': 'No data shared.', 'description': 'No data shared.' })
  const { enqueueSnackbar } = useSnackbar()
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: '',
  })

  const [bookingResults, setBookingResults] = useState(0)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    privacyPolicyChecked: false,
  })

  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }))
  }

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  const getDayName = (day) => {
    switch (day) {
      case 0:
        return 'Monday'
      case 1:
        return 'Tuesday'
      case 2:
        return 'Wednesday'
      case 3:
        return 'Thursday'
      case 4:
        return 'Friday'
      case 5:
        return 'Saturday'
      case 6:
        return 'Sunday'
      default:
        return ''
    }
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setBookingResults(0)
  };
  const handleMonthChange = (date) => {
    //if (requestAbortController.current) {
    // make sure that you are aborting useless requests
    // because it is possible to switch between months pretty quickly
    //requestAbortController.current.abort();
    //}

    setIsLoading(true);
    setHighlightedDays([]);
    //fetchHighlightedDays(date);
  };

  const getInitials = (name) => {
    if (!name) return '';

    const nameParts = name.trim().split(' ');

    if (nameParts.length === 1) {
      // If there's only one name part (no last name), use the first two letters of the first name
      return nameParts[0].substring(0, 2).toUpperCase();
    }

    // Otherwise, use the first letter of the first and last name
    const firstInitial = nameParts[0][0];
    const lastInitial = nameParts[1][0];

    return `${firstInitial}${lastInitial}`.toUpperCase();
  };


  const handleInputChange = (event) => {
    if (event.target.name === 'name') setErrors({ ...errors, name: '' })
    if (event.target.name === 'email') setErrors({ ...errors, email: '' })
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const doBooking = () => {

    const postData = {
      "bookings": [
        {
          code: bookingCode,
          number_spaces: 1,
          price: bookingPrice,
        }
      ]
    }
    joinBooking(postData).then(r => {
      setSessionId(r.sessionId)
      setConfirmModal(false)
    }).catch(error => {
      enqueueSnackbar(t('booking.message.error'), {
        variant: 'error',
      })
      setConfirmModal(false)
    })
  };

  function getVenueInfo(slug) {
    getVenue(slug).then(r => {
      const locationCenter = { lat: r?.location[0], lng: r?.location[1] }
      setCenter(locationCenter)
      setVenue(r)
      if (r.gallery) {
        const galleryImages = r.gallery.map(g => {
          return {
            original: `${API_BASE_URL}${g.image}`,
            thumbnail: `${API_BASE_URL}${g.image}`,
          }
        })
        setImages(galleryImages)
      }
      if (r.open_time) {
        const openingHours = r.open_time.map(o => {
          const formattedOpen = o.time_start?.split(':').slice(0, 2).join(':');
          const formattedClose = o.time_end?.split(':').slice(0, 2).join(':');

          return {
            day: getDayName(parseInt(o.day)),
            time: `${formattedOpen} - ${formattedClose}`
          }
        });
        const updatedOpeningHours = openingHoursData.map(day => {
          const matchingDay = openingHours.find(o => o.day === day.day);
          if (matchingDay) {
            return matchingDay;
          } else {
            return day;
          }
        });

        setOpeningHours(updatedOpeningHours);
      }
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function getVenuePricesInfo() {
    const parameters = {
      booking: bookingSlug,
      group: slug,
    }

    getAvailableGroupBooking(parameters).then(r => {
      setPlan(r.booking_plan)
      setPrices(r.bookings)
      setBookingCode(r.booking_plan?.code)
      const referralFee = parseFloat(r.booking_plan?.fee ?? 0) + parseFloat(r.booking_plan?.fee_vat_amount ?? 0)
      const formattedReferralFee = referralFee.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
      setReferralFee(formattedReferralFee)
      if (r.booking_plan?.group) {
        if (r.booking_plan?.group?.data_protection === 'NO') {
          setDataProtection({ 'level': 'No data shared.', 'description': 'No data shared.' })
        }
        if (r.booking_plan?.group?.data_protection === 'IN') {
          setDataProtection({ 'level': 'Shared internally.', 'description': 'No data shared.' })
        }
        if (r.booking_plan?.group?.data_protection === 'PA') {
          setDataProtection({ 'level': 'Shared with partners.', 'description': 'No data shared.' })
        }
        if (r.booking_plan?.group?.data_protection === 'CO') {
          setDataProtection({ 'level': 'Shared commercially.', 'description': 'No data shared.' })
        }
      }
      //obtener fee y el vat
      if (r.available_spaces !== 0) {
        const firstBooking = r.bookings[0]
        setBookingPrice(parseFloat(r.booking_plan?.price_unit) + parseFloat(r.booking_plan.vat_amount))
        const formattedStartDate = dayjs(`${r.booking_plan?.planned_start_day} ${r.booking_plan?.planned_start_time}`).format('DD/MM/YYYY h:mmA');
        const formattedEndDate = dayjs(`${r.booking_plan?.planned_end_day} ${r.booking_plan?.planned_end_time}`).format('h:mmA');

        setSelectedDate(`${formattedStartDate} - ${formattedEndDate}`)

      }

    })
      .catch(error => console.log('The error is -> ', error))
  }

  useEffect(() => {
    //getVenueInfo(true)
    getVenuePricesInfo(true)
  }, [])

  useEffect(() => {
    if (plan != null && plan?.venue)
      getVenueInfo(plan?.venue?.slug)
  }, [plan?.venue])

  useEffect(() => {
    if (sessionId != null) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (error) {
          console.error(error.message);
          // Handle the error, e.g., display a message to the user
        }
      }
      redirectToCheckout()
    }

  }, [sessionId])


  const avatar = venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar
  const background = venue?.background ? `${API_BASE_URL}${venue?.background}` : noAvatar


  return (
    <Container>
      {/* Global toolbar */}
      {/* Community header */}
      <VenueHeader title={venue?.name} subtitle={venue?.single_address} avatar={avatar} background={background} />
      <Grid container>
        <Grid item xs={12}>

          <SecondRowContainer>

            <div>
              {/* Tab content sections */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={8}>
                  <OverviewLeft>
                    <OverviewTitle>About the experience</OverviewTitle>
                    <div dangerouslySetInnerHTML={{ __html: plan?.promotional_text }} />

                    {plan?.active_bookings?.length > 0 &&
                      <div><OverviewTitle>Who is coming?</OverviewTitle>

                        <Grid container spacing={2}>
                          {plan?.active_bookings?.map((participant, index) => (
                            <Grid item key={index}>
                              <Avatar
                                sx={{ width: 96, height: 96 }}
                                src={participant.participant_image ? `${API_BASE_URL}${participant.participant_image}` : ''}
                                alt={participant.participant_name}
                              >
                                {!participant.participant_image && getInitials(participant.participant_name)}
                              </Avatar>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                  }
                    <OverviewTitle>About the space</OverviewTitle>
                    <div dangerouslySetInnerHTML={{ __html: venue?.long_description }} />
                    <div>
                      <ImageGallery items={images} />
                    </div>

                  </OverviewLeft>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <OverviewRight>
                    <OfferContainer>
                      <OverviewTitle>{plan?.title}</OverviewTitle>
                      <Card>
                        <CardMedia
                          component="img"
                          src={venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar}
                          alt="Venue Image"
                          onError={handleImageRenderError}
                          sx={{ borderRadius: '10px', height: '100px' }}
                        />
                        <Typography sx={{ marginTop: '4px', paddingLeft: '6px', fontSize: '16px' }}>
                          Group Booking{bookingPrice > 0 ? `: £${bookingPrice} (VAT inc)` : ''}
                        </Typography>
                        <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '14px', marginBottom: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                          {selectedDate}
                        </Typography>
                        <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '14px', marginBottom: '16px' }}>
                          {plan?.description}
                        </Typography>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                          {bookingPrice > 0 && <Button size='small' variant='contained' color='secondary' onClick={() => setConfirmModal(true)} sx={{ alignItems: 'center' }}>
                            Book now!
                          </Button>}
                          {bookingPrice === 0 && <Button size='small' variant='text' color='error' sx={{ alignItems: 'center' }}>
                            Sorry, the space is full
                          </Button>}
                        </div>
                      </Card>
                    </OfferContainer>

                    <LocationContainer>
                      <OverviewTitle>Location</OverviewTitle>
                      <Typography color="textSecondary" sx={{ fontSize: '14px', marginBottom: '16px' }}>
                        {venue?.single_address}
                      </Typography>
                      {venue && venue?.location && venue?.location[0] && venue?.location[1] && (
                        <LocationMap
                          latitude={venue?.location[0]}
                          longitude={venue?.location[1]}
                          name={venue?.name}
                          googleMapsUrl={venue?.google_maps_url}
                        />
                      )}
                    </LocationContainer>

                    <OpeningTimesContainer>
                      <OverviewTitle>Opening Times</OverviewTitle>
                      {openingHoursData.map(({ day, time }) => (
                        <div key={day} style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography sx={{ fontSize: '0.9rem' }}>{day}</Typography>
                          <Typography sx={{ fontSize: '0.9rem', color: '#666' }}>{time}</Typography>
                        </div>
                      ))}
                    </OpeningTimesContainer>

                    <OpeningTimesContainer>
                      <OverviewTitle>Transparency</OverviewTitle>
                      <div key={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '0.9rem' }}>Referral fees</Typography>
                        <Typography sx={{ fontSize: '0.9rem', color: '#666', fontWeight: 'bold' }}>{referralFee} per booking.</Typography>
                      </div>
                      <div key={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '0.9rem' }}>Data protection</Typography>
                        <Typography sx={{ fontSize: '0.9rem', color: '#666', fontWeight: 'bold' }}>{dataProtection.level}</Typography>
                      </div>
                      <div key={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '0.9rem' }}>Cancellation policy</Typography>
                        <Typography sx={{ fontSize: '0.9rem', color: '#666', fontWeight: 'bold' }}>Cancel any time. Full refund.</Typography>
                      </div>
                    </OpeningTimesContainer>
                  </OverviewRight>
                </Grid>
              </Grid>




            </div>
          </SecondRowContainer>
        </Grid>

      </Grid>

      <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <ModalContent>

          <Typography id="parent-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
            Confirm your booking
          </Typography>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ position: 'relative' }} onClick={() => setConfirmModal(true)}>
              <CardMedia
                component="img"
                src={venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar}
                alt="Venue Image"
                onError={handleImageRenderError}
                sx={{ borderRadius: '10px', height: '150px' }}
              />

              <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '14px', marginBottom: '10px', marginTop: '20px' }}>
                Please confirm your booking details below.
              </Typography>
              <Grid container spacing={2} style={{ padding: '10px' }}>
                <Grid item xs={3}>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Date:</Typography>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Price:</Typography>
                  <Typography variant="body2" sx={{ fontSize: '14px' }}>Venue:</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    {selectedDate}
                  </Typography>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    £{bookingPrice}
                  </Typography>
                  <Typography variant="body2" fontWeight="light" sx={{ fontSize: '14px' }}>
                    {venue?.name}
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button size='small' variant='contained' color='secondary' onClick={() => doBooking()} sx={{ alignItems: 'center' }}>
                  Confirm booking
                </Button>
              </div>

            </div>

          </Grid>
          <Snackbar open={bookingResults != 0} autoHideDuration={6000} onClose={handleSnackClose}>
            {bookingResults === 1 && <Alert onClose={handleSnackClose} severity="success">
              Booking confirmed!
            </Alert>}
            {bookingResults === -1 && <Alert onClose={handleSnackClose} severity="error">
              An error occurred. Please try again later.
            </Alert>}
          </Snackbar>
        </ModalContent>
      </Modal>
    </Container>
  )
}

BookingSingle.propTypes = {}

export default BookingSingle
