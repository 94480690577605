import React from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { padding, styled } from '@mui/system';
import { Grid } from '@mui/material'
import { Card, CardMedia, Typography, Box, Chip, CardContent, CardActions, Avatar, Button } from '@mui/material';
import { API_BASE_URL } from '../../../../config/api'
import DoneIcon from '@mui/icons-material/Done';
import DeviceUnknown from '@mui/icons-material/DeviceUnknown';
import { InnerChip, AvatarContainer } from './styled';
import noAvatar from '../../../../assets/images/no-avatar.png'

const VenueListItem = ({ venue, setSelectedVenue }) => {

  const handleImageRenderError = (e) => {
    e.target.src = noAvatar
  }

  return (
    <div onClick={() => setSelectedVenue(venue)} style={{ cursor: 'pointer', position: 'relative' }}>
      <Card style={{ position: 'relative' }}>
        <AvatarContainer>
          {venue?.status === 'AC' && <InnerChip label={venue?.actions ? venue?.actions?.length : 0} size="small" variant="outlined" color="success" icon={venue?.status === 'AC' ? <DoneIcon /> : <DeviceUnknown />} />}
          <CardMedia
            component="img"
            src={venue?.image ? `${API_BASE_URL}${venue?.image}` : noAvatar}
            alt="Venue Image"
            onError={handleImageRenderError}
            sx={{ borderRadius: '10px' }}
          />
        </AvatarContainer>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={9}>
            <Typography sx={{ marginTop: '4px', paddingLeft: '6px', fontSize: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {venue?.name}
            </Typography>
            <Typography color="textSecondary" sx={{ paddingLeft: '6px', fontSize: '14px', marginBottom: '16px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {venue?.address || '-'}
            </Typography>
          </Grid>
          <Grid item xs={3}>

          </Grid>
        </Grid>

      </Card>
    </div>
  );
};

VenueListItem.propTypes = {
  venue: PropTypes.object,
}

export default VenueListItem
