import styled from 'styled-components'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

export const DialogContainer = styled(Dialog)`
  && .MuiDialog-paper {
    width: 100%;
    max-width: 560px;
  }
`

export const Container = styled.div`
  padding: 0 32px 32px;
`

export const DialogSubtitle = styled.div``

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  min-height: 50px;
`
export const ContainerTitle = styled(MuiDialogTitle)`
  && {
    padding: 0;
  }
`

export const ContentDialog = styled(MuiDialogContent)`
  && {
    padding: 0;
  }
`
export const DialogActions = styled(MuiDialogActions)`
  && {
    margin-top: 32px;
  }
`

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`

export const Title = styled.div`
  font-weight: bold;
`
