import { styled } from '@mui/system';

import { Paper, Avatar, Tabs, Button } from '@mui/material';

export const Container = styled('div')()

export const HeroContainer = styled(Paper)(({ background }) => ({
    height: '200px', /* 20% of the viewport height */
    background: `url("${background}") center/cover`, /* Use the dynamic background image */
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    '@media (max-width: 600px)': {
        marginBottom: '60px'
      }, 
  }));
  
export const InfoContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row', 
    width: '100%', // Ensure it takes full width
});
export const AvatarContainer = styled('div')({
    width: '180px',
    '@media (max-width: 600px)': {
        width: '0', // Set width to 100% for smaller screens
      }, 
});

export const ContentContainer = styled('div')({
    //width: 'calc(100% - 180px)',
    width: '100%',
    marginLeft: '20px',
    '@media (max-width: 600px)': {
        marginLeft: '0px',
      }, 
});

  export const LowerRowContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
    justifyContent: 'space-between',
    alignItems: 'flex-start', // Align items to the left
    margin: '20px', // Add margin or adjust as needed
    
  });
  
  export const FirstRowContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row', // Stack items vertically
    alignItems: 'flex-start', // Align items to the left
    justifyContent: 'space-between', // Distribute items along the row    
    padding: '20', // Add padding or adjust as needed
    width: '100%', // Ensure it takes full width
  });
  

  
  export const InternalBasic = styled('div')({
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
  });

  export const InternalName = styled('div')({
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  });
  
  export const InternalStatus = styled('div')({
    fontSize: '16px',
    marginBottom: '10px',
  });
  
  export const JoinButton = styled(Button)({
    
  });
  
  export const StyledAvatar = styled(Avatar)({
    width: '120px',
    height: '120px', // Set the height to twice the avatar height
    position: 'absolute',
    top: '140px', // Adjust to position the top half inside the hero section
    left: '60px',
    '@media (max-width: 600px)': {
        left: '20px',
      }, 
  });
  
  export const StyledTabs = styled(Tabs)({
    background: 'rgba(255, 255, 255, 0.8)', /* Semi-transparent background for tabs */
  });
  
  export const InternalListContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%', // Ensure it takes full width
  });
  
  export const InternalListItem = styled('div')({
    // Add styles for community list items
  });