import React from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom'

import View from './view'

const LoginForm = ({ ...props }) => {
  const onSuccess = props.onSuccess
  const defaultEmail = props.defaultEmail
  const setShowLogin = props.setShowLogin
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { doLogin } = useAuth();

  const mutateLogin = useMutation(data => doLogin(data), {
    onSuccess: result => {
      if (onSuccess) {
        onSuccess(result)
      }
      navigate('/')
    },
    onError: () => {
      enqueueSnackbar(t('login.error'), {
        variant: 'error',
      })
    },
  })

  const onSubmit = data => {
    mutateLogin.mutate(data)
  }

  return <View {...props} onSubmit={onSubmit} onSuccess={onSuccess} setShowLogin={setShowLogin} defaultEmail={defaultEmail}/>
}

export default LoginForm
