import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import MemberList from '../memberList/view';
import MemberDetail from '../memberDetail/view';
import { TextField, Autocomplete, Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Members = (props) => {
  const { community, member, members, setMember, setSelectedParticipant, tags, role, validateAccount } = props;
  const [searchText, setSearchText] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
    // onFilterChange(event.target.value, selectedTags);
  };

  const handleTagsChange = (event, value) => {
    setSelectedTags(value);
    // onFilterChange(searchText, value);
  };

  return (
    <div>
      {/* Member list displayed when no member is selected */}
      {member === null && <MemberList community={community} members={members} setSelectedParticipant={setSelectedParticipant} role={role} />}

      {/* Modal that shows MemberDetail when a member is selected */}
      <Dialog
        open={member !== null}
        onClose={() => setMember(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setMember(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {member !== null && (
            <MemberDetail
              member={member}
              goBack={() => setMember(null)}
              role={role}
              validateAccount={(result) => validateAccount(result)}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

Members.propTypes = {};

export default Members;
