import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { CircularProgress, Grid, Select, MenuItem, TextField, Button, Modal, Typography, Box, Fab } from '@mui/material'
import NoResultsPlaceholder from 'components/organisms/noResultsPlaceholder/view'
import BookingHeader from './header'
import { ModalContent, BookingModalContent } from './styled'
import { use } from 'i18next'
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getBooking, acceptBooking, createBookingPlan, getVenuePrice } from 'api'
import { get } from 'api/utils'
import dayjs, { Dayjs } from 'dayjs';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import GroupBookingForm from '../groupBookingForm/view';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Badge from '@mui/material/Badge';
import { API_BASE_URL, REACT_APP_STRIPE_KEY } from 'config/api';
import AddIcon from '@mui/icons-material/Add';

const BookingDetailList = ({ }) => {

  const { t } = useTranslation()
  const { pathname } = useLocation()
  const params = useParams()
  const { slug } = params

  const [confirmModal, setConfirmModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(['PA'])
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({})
  const [theBooking, setBooking] = useState({})
  const [messageEditorState, setMessageEditorState] = useState(EditorState.createEmpty())
  const [openConfirm, setOpenConfirm] = useState(false)
  const [bookingConfirm, setBookingConfirm] = useState(false)
  const [confirmAction, setConfirmAction] = useState({})
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [sessionId, setSessionId] = useState(null)
  const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
  const [prices, setPrices] = useState([])

  const [highlightedDays, setHighlightedDays] = useState([1, 5, 7, 10, 20, 27])
  const [isLoading, setIsLoading] = useState(false);

  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'history'],
    inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] },
    blockType: { inDropdown: true, options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'] },
    list: { inDropdown: false, options: ['unordered', 'ordered'] },
    textAlign: { inDropdown: false, options: ['left', 'center', 'right'] },
    history: { inDropdown: false, options: ['undo', 'redo'] },
  };


  function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;

    // Check if prices is an array
    if (!Array.isArray(prices)) {
      return null; // or handle it in a way that makes sense for your application
    }
    // Check if there is a price for the current day
    const priceInfo = prices?.find((price) => {
      const priceDate = new Date(price.start);
      return priceDate.toISOString().split('T')[0] === props.day.format('YYYY-MM-DD');
    });

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={priceInfo ? `£${priceInfo?.price}` : undefined}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    );
  }


  function handleInputChange(e) {
    const { name, value } = e.target
    if (name === 'subject') {
      setSubject(value)
      setBooking({ ...theBooking, subject: value })
    }
    if (name === 'message') {
      setMessage(value)
    }
  }


  const doBooking = () => {
    const formattedDate = selectedDate.format('DD/MM/YYYY');

    const data = {
      title: formData.title,
      description: formData.description,
      venue: slug,
      group: formData.group,
      date: formattedDate,
      number_spaces: formData.spaces,
      booking_type: 'HD',
      price: formData.price,
      fee: formData.fee,
    }
    createBookingPlan(data).then(r => {
      setSessionId(r.sessionId)
      setBookingConfirm(false)
    })
      .catch(error => console.log('The error is -> ', error))

  };

  function getVenuePricesInfo(firstLoad) {
    getVenuePrice(slug).then(r => {
      setPrices(r.prices)
    })
      .catch(error => console.log('The error is -> ', error))
  }

  function doAcceptBooking(slug, action) {
    setConfirmAction({ slug, action })
    setOpenConfirm(true)
  }

  function doConfirm() {
    acceptBooking(confirmAction).then(r => {
      fetchBooking()
      setOpenConfirm(false)
    }).catch(error => console.log('The error is -> ', error))
  }

  // Handle change for rules editor
  const handleMessageEditorChange = (newEditorState) => {
    setMessageEditorState(newEditorState);
    setMessage(
      convertToRaw(newEditorState.getCurrentContent())
    );
  };

  const handleMonthChange = (date) => {
    setIsLoading(true);
    setHighlightedDays([]);
    //fetchHighlightedDays(date);
    setIsLoading(false);
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value)
    setBooking({ ...theBooking, type: value })
  }

  function fetchBooking() {
    getBooking(slug).then(r => {
      setBooking(r)
    })
  }

  useEffect(() => {
    fetchBooking()
  }, [])

  useEffect(() => {
    if (sessionId != null) {
      const redirectToCheckout = async () => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (error) {
          console.error(error.message);
          // Handle the error, e.g., display a message to the user
        }
      }
      redirectToCheckout()
    }

  }, [sessionId])

  useEffect(() => {
    const htmlMessage = convertToHTML(messageEditorState.getCurrentContent())
    setBooking({ ...theBooking, message: htmlMessage })
  }, [message])


  return (
    <Container>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={12} md={12} sx={{ paddingTop: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Code</Typography>
              <Typography variant='body1'>{theBooking?.code}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Status</Typography>
              <Typography variant='body1'>{theBooking?.status}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Tenant</Typography>
              <Typography variant='body1'></Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Team</Typography>
              <Typography variant='body1'></Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Plan</Typography>
              <Typography variant='body1'></Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Booking type</Typography>
              <Typography variant='body1'>{theBooking?.booking_type}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Price exc VAT</Typography>
              <Typography variant='body1'>{theBooking?.price}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Price inc VAT</Typography>
              <Typography variant='body1'>{theBooking?.vat_amount}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Planned date</Typography>
              <Typography variant='body1'>{theBooking?.planned_start_day}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Planned start time</Typography>
              <Typography variant='body1'>{theBooking?.planned_start_time}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Planned end time</Typography>
              <Typography variant='body1'>{theBooking?.planned_end_time}</Typography>
            </Grid>

            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Location</Typography>
              <Typography variant='body1'>{theBooking?.venue?.name}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Area</Typography>
              <Typography variant='body1'></Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant='body2'>Resource</Typography>
              <Typography variant='body1'></Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{marginTop: 5}}>
            <Grid item xs={12} md={12} sx={{alignContent: 'center', justifyContent: 'center'}}>
            {theBooking?.status === 'PN' && <Box display="flex" alignItems="center" sx={{justifyContent: 'center'}}>
              <Button sx={{marginRight: 2}} variant='contained' color='success' onClick={() => doAcceptBooking(theBooking?.slug, 'accept')}>Accept</Button>
              <Button sx={{marginLeft: 2}} variant='contained' color='error' onClick={() => doAcceptBooking(theBooking?.slug, 'reject')}>Reject</Button>
            </Box>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="confirmation-modal-title"
        aria-describedby="confirmation-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ModalContent>
          <Box>
            <Typography id="confirmation-modal-title" variant="h6" component="h2">
              Booking Confirmation
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to {confirmAction.action === 'accept' ? 'confirm' : 'reject'} this booking?
            </Typography>
            <Box mt={3} display="flex" justifyContent="space-between">
              <Button variant="contained" color="primary" onClick={() => doConfirm()}>
                {confirmAction.action === 'accept' ? 'Confirm' : 'Reject'}
              </Button>
            </Box>

          </Box>
        </ModalContent>
      </Modal>

      <Modal
        open={bookingConfirm}
        onClose={() => setBookingConfirm(false)}
        aria-labelledby="booking-modal-title"
        aria-describedby="booking-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <BookingModalContent>
          <Grid container spacing={2}>

            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={selectedDate}
                  loading={isLoading}
                  onMonthChange={handleMonthChange}
                  renderLoading={() => <DayCalendarSkeleton />}
                  slots={{
                    day: ServerDay,
                  }}
                  slotProps={{
                    day: {
                      highlightedDays,
                    },
                  }}
                  onChange={(newValue) => setSelectedDate(newValue)}
                />
              </LocalizationProvider>

              <Button variant='contained' color='button_color' onClick={() => doBooking()}>Book</Button>
            </Grid>
          </Grid>
        </BookingModalContent>
      </Modal>
    </Container >
  )
}

BookingDetailList.propTypes = {

}

export default BookingDetailList
