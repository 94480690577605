import React, { useEffect, useState } from 'react'
import { Container } from './styled'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { validateBookingPayment } from 'api'
import { Button, Box, LinearProgress, Grid, Card, CardContent, Link } from '@mui/material'
import BookingHeader from '../bookingHeader/view'
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router'



const BookingResult = () => {

  const { t } = useTranslation()
  const params = useParams()
  const { sessionId } = params
  const [firstLoad, setFirstLoad] = useState(true)
  const [validationResult, setValidationResult] = useState({ result: 'loading' });

  const navigate = useNavigate()

  function navigateMenu() {
    navigate(`/bookings`);
  }

  function getPaymentConfirmation() {
    validateBookingPayment(sessionId).then(r => {
      console.log(r)
      setValidationResult(r)
    })
      .catch(error => {
        console.log(error)
        console.log('The error is -> ', error)

      })
  }



  useEffect(() => {
    getPaymentConfirmation()
  }, [])

  return (
    <Container>
      <BookingHeader />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h1" gutterBottom align="center">
                Thank you for using Epic Spaces!
              </Typography>

              {(validationResult.result === 'loading') && <LinearProgress sx={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }} />}

              {(validationResult.result === 'ok') && (
                <Grid container justifyContent="center">
                  <Grid item xs={12} md={12} sx={{marginBottom: '15px'}}>
                    <Typography variant="h6" align="center">
                      {validationResult.status === "AC" ? "Your booking is now confirmed!" : "Your booking is pending confirmation"}
                    </Typography>
                    <Typography variant="body1" align="center">
                      Booking reference: <b>{validationResult.code}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{padding:'0px 10px'}}>
                    <img src="https://workersleague.com/wp-content/uploads/2021/04/Bonhill-CW_DSC_5703.jpg" style={{ width: '100%' }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body2" align="left">
                      <Box>
                        <b>Venue:</b> {validationResult.venue}
                      </Box>
                      <Box>
                        <b>Address:</b> {validationResult.address}
                      </Box>
                      <Box>
                        <b>Date:</b> {validationResult.day}
                      </Box>
                      <Box>
                      <b>Time:</b> {validationResult.start} - {validationResult.end}
                      </Box>
                      <Box>
                      <b>Price:</b> £{validationResult?.price}
                      </Box>
                      <Box>
                      <b>Status:</b> {validationResult?.status === "AC" ? "Confirmed" : "Pending"}
                      </Box>
                    </Typography>
                    <Typography variant="body2" align="left">
                      <b>Access:</b> <div dangerouslySetInnerHTML={{ __html: validationResult?.access }} />
                      
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography variant="body2" align="center" marginTop='40px'>
                      { validationResult?.status === "AC" ? "You should have an email with the booking details." : "You will receive an email with your booking confirmation." } If you don't receive it, please check your spam folder or contact us at <a href="mailto:hello@the-epic.space">hello@the-epic.space</a>
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {(validationResult.result === 'existing') && (
                <Typography variant="body1" align="center" sx={{ paddingTop: '20px' }}>
                  <Box>
                    Sorry, this booking has already been confirmed.
                  </Box>
                  <Box>
                    Please contact us at <a href="mailto:hello@the-epic.space">hello@the-epic.space</a>
                  </Box>
                </Typography>
              )}



              <Button
                key='remove'
                variant='contained'
                color="success"
                onClick={() => navigateMenu()}
                sx={{ my: 2, color: 'white', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              >
                Back to bookings
              </Button>


            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box
        component="footer"
        sx={{
          backgroundColor: '#f5f5f5',
          padding: '20px',
          marginTop: '50px', // Push the footer to the bottom
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Email: hello@the-epic.space
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Link href="/privacy-policy" color="text.primary" sx={{ mr: 2 }}>
            Privacy Policy
          </Link>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Epic Spaces Ltd. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

BookingResult.propTypes = {}

export default BookingResult
