import styled from 'styled-components'

export const Container = styled.div`
    background-color: #fff;
    margin: 10px;
    padding: 10px;
`

export const ForgotButton = styled.a`
    position: fixed;
    bottom: 40px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5%;
    width: 160px;
    height: 80px;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    outline: none;
`
