import styled from 'styled-components'
import { Typography, Button } from '@mui/material'

export const Container = styled.div`
  background-color: #fff;
  margin: 10px;
  padding: 10px;
`

export const Description = styled(Typography)`
  margin-bottom: 16px;
`

export const Prompt = styled(Typography)`
  margin-bottom: 16px;
  font-weight: bold;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 10px; /* Space between buttons */
`
export const JoinButton = styled(Button)`
  && {
    background-color: #3f51b5; /* Primary color */
    color: #fff;
    &:hover {
      background-color: #303f9f; /* Darker shade for hover */
    }
  }
`

export const CancelButton = styled(Button)`
  && {
    color: #f50057; /* Secondary color */
    border-color: #f50057;
    &:hover {
      border-color: #c51162; /* Darker shade for hover */
      background-color: #f50057;
      color: #fff;
    }
  }
`